import styled from '@emotion/styled'
import { Chip as MuiChip } from '@mui/material'

const Chip = styled(MuiChip)(({ theme }) => ({
  'height': '26px',

  'backgroundColor': '#FAFAFA',
  'color': theme.palette.mode === 'dark' ? '#fff' : '#000',
  'border': `1px solid ${theme.palette.mode === 'dark' ? '#666' : '#ccc'}`,
  '&:hover': {
    backgroundColor: '#F2F5F5',
  },
  '&.MuiChip-deletable': {
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? '#555' : '#c0c0c0', // Focus state
    },
  },
}))

export { Chip }
