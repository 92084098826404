export const XCircle = ({ size = 24, color }) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.0002 0.199951C5.05808 0.199951 0.200195 5.05784 0.200195 11C0.200195 16.9421 5.05808 21.7999 11.0002 21.7999C16.9423 21.7999 21.8002 16.9421 21.8002 11C21.8002 5.05784 16.9423 0.199951 11.0002 0.199951ZM14.1233 9.18596C14.4849 8.82444 14.4849 8.23831 14.1233 7.8768C13.7618 7.51529 13.1757 7.51529 12.8142 7.8768L11.0002 9.69079L9.1862 7.8768C8.82469 7.51529 8.23856 7.51529 7.87704 7.8768C7.51553 8.23831 7.51553 8.82444 7.87704 9.18596L9.69104 11L7.87704 12.8139C7.51553 13.1755 7.51553 13.7616 7.87704 14.1231C8.23856 14.4846 8.82469 14.4846 9.1862 14.1231L11.0002 12.3091L12.8142 14.1231C13.1757 14.4846 13.7618 14.4846 14.1233 14.1231C14.4849 13.7616 14.4849 13.1755 14.1233 12.8139L12.3094 11L14.1233 9.18596Z'
        fill='#D72B2B'
      />
    </svg>
  );
};
