import { Button, Snackbar, Box } from '@mui/material';
import { copyToClipboard } from 'lib/utils';
import { useState } from 'react';
import Alert from '@mui/material/Alert';

const TGCopyToClipboard = ({
  children,
  target,
  message = 'Texto copiado!',
}) => {
  const [state, setState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });

  const handleClick = () => {
    setState({ ...state, open: true });
    copyToClipboard(target);
  };

  const { vertical, horizontal, open } = state;

  return (
    <>
      <Box width='100%' onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() => setState({ ...state, open: false })}
        autoHideDuration={2000}
        message={message}
        severity='success'
      >
        <Alert severity={'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TGCopyToClipboard;
