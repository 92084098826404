import Select from './Select'

const YesNoSelect = ({ label = '', name, errors = {}, ...props }) => {
  return (
    <Select errors={errors} label={label} name={name} {...props}>
      <option value=''>Selecione</option>
      <option value='yes'>Sim</option>
      <option value='no'>Não</option>
    </Select>
  )
}

export default YesNoSelect
