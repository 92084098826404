export const BankSlip = ({ size = 24, color }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4068_1737)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3.20708 3.46992C2.78371 3.87513 2.4888 4.54193 2.4888 5.61663V10.3826C2.4888 11.4573 2.78371 12.1241 3.20708 12.5293C3.63843 12.9422 4.29736 13.1741 5.19705 13.1741H11.8026C12.7022 13.1741 13.3612 12.9422 13.7925 12.5293C14.2159 12.1241 14.5108 11.4573 14.5108 10.3826V5.61663C14.5108 4.54193 14.2159 3.87513 13.7925 3.46992C13.3612 3.05707 12.7022 2.82514 11.8026 2.82514H5.19705C4.29736 2.82514 3.63843 3.05707 3.20708 3.46992ZM2.39803 2.57185C3.12264 1.87832 4.11509 1.59961 5.19705 1.59961H11.8026C12.8845 1.59961 13.877 1.87832 14.6016 2.57185C15.3342 3.27302 15.6998 4.30835 15.6998 5.61663V10.3826C15.6998 11.6909 15.3342 12.7262 14.6016 13.4274C13.877 14.1209 12.8845 14.3996 11.8026 14.3996H5.19705C4.11509 14.3996 3.12264 14.1209 2.39803 13.4274C1.66544 12.7262 1.2998 11.6909 1.2998 10.3826V5.61663C1.2998 4.30835 1.66544 3.27302 2.39803 2.57185Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M4.5365 4.66344C4.86483 4.66344 5.131 4.93778 5.131 5.27621V10.723C5.131 11.0614 4.86483 11.3358 4.5365 11.3358C4.20817 11.3358 3.94201 11.0614 3.94201 10.723V5.27621C3.94201 4.93778 4.20817 4.66344 4.5365 4.66344Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M6.51815 4.66344C6.84648 4.66344 7.11265 4.93778 7.11265 5.27621V7.99961C7.11265 8.33803 6.84648 8.61238 6.51815 8.61238C6.18982 8.61238 5.92366 8.33803 5.92366 7.99961V5.27621C5.92366 4.93778 6.18982 4.66344 6.51815 4.66344Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M6.51815 9.4294C6.84648 9.4294 7.11265 9.70374 7.11265 10.0422V10.723C7.11265 11.0614 6.84648 11.3358 6.51815 11.3358C6.18982 11.3358 5.92366 11.0614 5.92366 10.723V10.0422C5.92366 9.70374 6.18982 9.4294 6.51815 9.4294Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.4815 4.66344C10.8098 4.66344 11.076 4.93778 11.076 5.27621V5.95706C11.076 6.29548 10.8098 6.56982 10.4815 6.56982C10.1531 6.56982 9.88696 6.29548 9.88696 5.95706V5.27621C9.88696 4.93778 10.1531 4.66344 10.4815 4.66344Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.4998 4.66344C8.82814 4.66344 9.0943 4.93778 9.0943 5.27621V10.723C9.0943 11.0614 8.82814 11.3358 8.4998 11.3358C8.17147 11.3358 7.90531 11.0614 7.90531 10.723V5.27621C7.90531 4.93778 8.17147 4.66344 8.4998 4.66344Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.4815 7.38684C10.8098 7.38684 11.076 7.66119 11.076 7.99961V10.723C11.076 11.0614 10.8098 11.3358 10.4815 11.3358C10.1531 11.3358 9.88696 11.0614 9.88696 10.723V7.99961C9.88696 7.66119 10.1531 7.38684 10.4815 7.38684Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.4631 4.66344C12.7914 4.66344 13.0576 4.93778 13.0576 5.27621V10.723C13.0576 11.0614 12.7914 11.3358 12.4631 11.3358C12.1348 11.3358 11.8686 11.0614 11.8686 10.723V5.27621C11.8686 4.93778 12.1348 4.66344 12.4631 4.66344Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_4068_1737'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
