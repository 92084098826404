import crudActionTypes from '../actionTypes/crud'

export const insert = (endpoint, data, redirectTo = null) => ({
    type: crudActionTypes.INSERT,
    payload: { endpoint, data, redirectTo }
})

export const update = (endpoint, data, redirectTo) => ({
    type: crudActionTypes.UPDATE,
    payload: { endpoint, data, redirectTo }
})

export const remove = (endpoint, data, redirectTo) => ({
    type: crudActionTypes.DELETE,
    payload: { endpoint, data, redirectTo }
})

export const get = (endpoint, criteria) => ({
    type: crudActionTypes.GET,
    payload: { endpoint, criteria }
})

export const applyData = (data) => ({
    type: crudActionTypes.APPLY_DATA,
    payload: { data }
})

export const applyLists = (lists) => ({
    type: crudActionTypes.APPLY_LISTS,
    payload: { lists }
})

export const applyErrors = (errors) => ({
    type: crudActionTypes.APPLY_ERRORS,
    payload: { errors }
})

export const clear = () => ({
    type: crudActionTypes.CLEAR,
})

export const setLoading = (flag) => ({
    type: crudActionTypes.SET_LOADING,
    payload: { flag }
})

