import { Stack } from '@mui/material';
import Header from '../Header';
import Body from './Body';
import { useSearchParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import appConfig from 'config';

const PaymentCard = ({ loadingPost }) => {
  const { values, setFieldValue } = useFormikContext();
  const [searchParams] = useSearchParams();
  const qtym = searchParams.get('qtym');
  const tipo_pagamento = searchParams.get('tipo_pagamento');
  const uuid_evento = searchParams.get('uuid_evento');
  const uuid_bilhete = searchParams.get('uuid_bilhete');
  const idTransacao = searchParams.get('idTransacao');

  const [checkoutConfig, setCheckoutConfig] = useState(null);
  const [checkoutConfigLoading, setCheckoutConfigLoading] = useState(false);
  const [parcelConfig, setParcelConfig] = useState(null);
  const [loadingParcelConfig, setLoadingParcelConfig] = useState(false);

  // Fetch checkout configuration data
  useEffect(() => {
    const fetchCheckoutConfig = async () => {
      setCheckoutConfigLoading(true);
      setLoadingParcelConfig(true);
      try {
        const response = await fetch(
          `${
            appConfig[appConfig.env].api
          }/checkout-config/b${uuid_bilhete}_e${uuid_evento}_quantidade=${
            values?.quantity
          }_idTransacao=${idTransacao}`
        );
        if (!response.ok) {
          throw new Error('Error fetching data');
        }
        const data = await response.json();
        setCheckoutConfig(data);
        setFieldValue('id_checkout', data?.id);
        setFieldValue('boLiberaEventoCompra', data?.boLiberaEventoCompra);
        setFieldValue('boVoltar', data?.boVoltar);
      } catch (error) {
        console.error(error.message);
      } finally {
        setCheckoutConfigLoading(false);
      }
    };

    if (uuid_bilhete && uuid_evento && values.quantity) {
      fetchCheckoutConfig();
    }
  }, [uuid_bilhete, uuid_evento, values.quantity, setFieldValue]);

  // Fetch parcel configuration data when checkoutConfig changes
  useEffect(() => {
    const fetchParcelConfig = async () => {
      if (checkoutConfig) {
        const updatedSimulationParams = {
          checkoutId: checkoutConfig.id,
          valor: checkoutConfig.valor * values.quantity,
          parcela: 18,
        };
        setFieldValue('valorTotalpix', checkoutConfig.valor * values.quantity);

        setLoadingParcelConfig(true);
        try {
          const response = await fetch(
            `${
              appConfig[appConfig.env].api
            }/checkout-config/cartao-credito/simular-parcela`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(updatedSimulationParams),
            }
          );
          if (!response.ok) {
            throw new Error('Error fetching parcel configuration');
          }
          const data = await response.json();
          setParcelConfig(data);
        } catch (error) {
          console.error(error.message);
        } finally {
          setLoadingParcelConfig(false);
        }
      }
    };

    fetchParcelConfig();
  }, [checkoutConfig]);

  return (
    <Stack
      padding='40px'
      width={['100%', '100%', '480px']}
      borderRadius='12px'
      bgcolor='#FFFFFF'
      gap='24px'
    >
      <Header
        title={tipo_pagamento === 'pix' ? 'Pix' : 'Confirme seu pagamento'}
        description='Uma nova compra será gerada no seu cartão crédito.'
      />
      <Body
        paymentType={tipo_pagamento}
        qtym={qtym}
        checkoutConfig={checkoutConfig}
        parcelConfig={parcelConfig}
        loadingParcelConfig={loadingParcelConfig}
        loadingPost={loadingPost}
      />
    </Stack>
  );
};

export default PaymentCard;
