import React from 'react'
import ReactDOM from 'react-dom'
import { store, persistor } from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
//import * as serviceWorker from './serviceWorker';
import './sass/app.scss'

import { createIntl } from 'react-intl'
//import { IntlProvider } from 'react-intl-redux';
import App from './App'

function Ticket() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<p>...Loading</p>} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  )
}

export default Ticket

if (document.getElementById('ticket-root')) {
  ReactDOM.render(<Ticket />, document.getElementById('ticket-root'))
}
