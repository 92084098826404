const singleActionTypes = {
    GET_SINGLE: 'SINGLE/GET_SINGLE', 
    PUT_SINGLE: 'SINGLE/PUT_SINGLE', 
    POST_SINGLE: 'SINGLE/POST_SINGLE', 
    DELETE_SINGLE: 'SINGLE/DELETE_SINGLE', 
    SET_SINGLE: 'SINGLE/SET_SINGLE',
    SET_RESPONSE: 'SINGLE/SET_RESPONSE',
    CLEAR_ALL: 'SINGLE/CLEAR_ALL',
    CLEAR_SINGLE: 'SINGLE/CLEAR_SINGLE',
    DOWNLOAD: 'SINGLE/DOWNLOAD',
    SET_LOADING: 'SINGLE/SET_LOADING',
}

export default singleActionTypes