export const ShareIcon = ({ width = 16, height = 16, color = '#00A7F0' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4 2.52193V10.2008C7.4 10.5322 7.66863 10.8008 8 10.8008C8.33137 10.8008 8.6 10.5322 8.6 10.2008V2.52185L10.0672 3.50001C10.343 3.68382 10.7155 3.60932 10.8993 3.3336C11.0831 3.05788 11.0086 2.68536 10.7329 2.50155L8.33722 0.90444C8.24112 0.83902 8.12502 0.800781 8 0.800781C7.87315 0.800781 7.75549 0.840149 7.65857 0.907333L5.26724 2.50155C4.99153 2.68536 4.91702 3.05788 5.10083 3.3336C5.28465 3.60932 5.65717 3.68382 5.93288 3.50001L7.4 2.52193Z"
        fill={color}
      />
      <path
        d="M10.3918 6.55217C10.4186 6.22189 10.7081 5.97591 11.0384 6.00275C12.3298 6.10772 13.364 6.48327 14.0579 7.29062C14.7439 8.08862 15 9.19696 15 10.5436V10.6262C15 12.1123 14.6873 13.3086 13.834 14.1141C12.9895 14.9113 11.7451 15.2008 10.1894 15.2008H5.81061C4.25492 15.2008 3.0105 14.9113 2.16596 14.1141C1.3127 13.3086 1 12.1123 1 10.6262V10.5436C1 9.20675 1.25263 8.10526 1.92756 7.30889C2.61119 6.50225 3.63067 6.12147 4.90379 6.00944C5.23389 5.98039 5.52503 6.22444 5.55407 6.55454C5.58312 6.88463 5.33907 7.17577 5.00898 7.20482C3.90101 7.30232 3.2423 7.6136 2.84301 8.08474C2.43502 8.56615 2.2 9.32815 2.2 10.5436V10.6262C2.2 11.9781 2.48929 12.7691 2.98968 13.2414C3.4988 13.722 4.35968 14.0008 5.81061 14.0008H10.1894C11.6403 14.0008 12.5012 13.722 13.0103 13.2414C13.5107 12.7691 13.8 11.9781 13.8 10.6262V10.5436C13.8 9.31888 13.5617 8.55421 13.1479 8.07285C12.7422 7.60084 12.0713 7.29066 10.9412 7.19881C10.6109 7.17196 10.3649 6.88245 10.3918 6.55217Z"
        fill={color}
      />
    </svg>
  );
};