import { createContext, useState } from 'react';

export const BarsContext = createContext();

const BarsProvider = ({ children }) => {
  const [closeTopBar, setCloseTopBar] = useState(false);
  const [closeSidebar, setCloseSidebar] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#EEEEEE');

  return (
    <BarsContext.Provider
      value={{
        closeTopBar,
        setCloseTopBar,
        closeSidebar,
        setCloseSidebar,
        fullScreen,
        setFullScreen,
        backgroundColor,
        setBackgroundColor,
      }}
    >
      {children}
    </BarsContext.Provider>
  );
};

export default BarsProvider;
