import crudActionTypes from '../actionTypes/crud'
import userActionTypes from './../actionTypes/user'


const initialState = {
    data: null,
    lists: [],
    errors: null,
    loading: false

}

const crud = (state = initialState, action) => {
    switch (action.type) {

        case crudActionTypes.APPLY_DATA: {

            const { data } = action.payload
            return {
                ...state,
                data,
            }
        }

        case crudActionTypes.APPLY_LISTS: {

            const { lists } = action.payload
            return {
                ...state,
                lists,
            }
        }

        case crudActionTypes.APPLY_ERRORS: {

            const { errors } = action.payload
            return {
                ...state,
                errors,
            }
        }

        case crudActionTypes.SET_LOADING: {

            const { flag } = action.payload
            return {
                ...state,
                loading: flag
            }
        }

        case crudActionTypes.CLEAR: 
        case userActionTypes.LOGOFF:
        {

            return initialState
        }

        default:
            return state
    }
}

export default crud