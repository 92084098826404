// menuItemsProduct.js
import { pixel } from 'assets/svgs';
import { cooproducao } from 'assets/svgs';
import {
  resumo,
  paginaVenda,
  ofertas,
  cupom,
  listaMembros,
  gerenciarTurmas,
} from 'assets/svgs';

const menuItemsProduct = [
  {
    text: 'Resumo',
    icon: resumo,
    to: 'produto/resumo',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Página de vendas',
    icon: paginaVenda,
    to: 'produto/add',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Ofertas',
    icon: ofertas,
    to: 'produto/ofertas',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Cupom',
    icon: cupom,
    to: 'produto/cupons',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Lista de membros',
    icon: listaMembros,
    to: 'produto/membros',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },

  {
    text: 'Coprodução',
    icon: cooproducao,
    to: 'produto/coproducoes',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Pixel',
    icon: pixel,
    to: 'produto/rastreamento',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
];

export default menuItemsProduct;
