const actionTypes = {
    SUCCESS: 'NOTIFICATIONS/SUCCESS',
    WARNING: 'NOTIFICATIONS/WARNING',
    INFO: 'NOTIFICATIONS/INFO',
    ERROR: 'NOTIFICATIONS/ERROR',
    CLEAR: 'NOTIFICATIONS/CLEAR',
    CLEAR_REDIRECT: 'NOTIFICATIONS/CLEAR_REDIRECT',

}

export default actionTypes