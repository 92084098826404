import React, { createContext, useState } from 'react';
export const EventContext = createContext();

// Create a provider component
export const EventProvider = ({ children }) => {
  const [eventName, setEventName] = useState(null);

  return (
    <EventContext.Provider value={{ eventName, setEventName }}>
      {children}
    </EventContext.Provider>
  );
};
