import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from 'TGComponents/global';
import notfound from 'assets/svgs/common/emojis/notfound.svg';
import TGIcon from 'TGComponents/global/TGIcon';
const NotAllowed = () => {
  return (
    <Stack
      textAlign='center'
      bgcolor='#ffffff'
      mx='20px'
      p={['20px', '20px', '20px', '40px']}
      borderRadius={'16px'}
      gap='12px'
      justifyContent='center'
      alignItems='center'
      height='85vh'
    >
      <TGIcon src={notfound} height='80px' width='80px' />

      <Typography lineHeight={1} variant='headline' fontSize='34px'>
        Error 403
      </Typography>
      <Typography variant='body-l'>Acesso negado</Typography>
      <Typography width={['100%', '80%', '50%', '40%']} mx='auto'>
        Você tentou acessar uma área protegida. Caso o acesso seja necessário
        entre em contato com o admistrador resposável pela sua conta! Clique no
        botão abaixo para voltar ao início.
      </Typography>

      <Link to='/admin'>
        <Button variant='primary' size='medium'>
          Voltar ao início
        </Button>
      </Link>
    </Stack>
  );
};

export default NotAllowed;
