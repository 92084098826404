export const ExportIcon = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8122_64953)">
        <path
          d="M9.19247 1.80078C6.76995 1.80078 4.85509 2.28013 3.56712 3.5681C2.27915 4.85607 1.7998 6.77093 1.7998 9.19344V14.8081C1.7998 17.2306 2.27915 19.1455 3.56712 20.4335C4.85509 21.7214 6.76995 22.2008 9.19247 22.2008H14.8071C17.2297 22.2008 19.1445 21.7214 20.4325 20.4335C21.7205 19.1455 22.1998 17.2306 22.1998 14.8081V12.9366C22.1998 12.4714 21.8227 12.0944 21.3576 12.0944C20.8925 12.0944 20.5154 12.4714 20.5154 12.9366V14.8081C20.5154 17.0645 20.059 18.4249 19.2414 19.2424C18.4239 20.0599 17.0635 20.5164 14.8071 20.5164H9.19247C6.93608 20.5164 5.57571 20.0599 4.75818 19.2424C3.94064 18.4249 3.48421 17.0645 3.48421 14.8081V9.19344C3.48421 6.93706 3.94064 5.57668 4.75818 4.75915C5.57571 3.94162 6.93608 3.48519 9.19247 3.48519H11.064C11.5292 3.48519 11.9062 3.10812 11.9062 2.64298C11.9062 2.17785 11.5292 1.80078 11.064 1.80078H9.19247Z"
          fill={color}
        />
        <path
          d="M16.0236 2.64298C16.0236 2.17785 16.4007 1.80078 16.8658 1.80078H21.3576C21.8227 1.80078 22.1998 2.17785 22.1998 2.64298V7.13473C22.1998 7.59986 21.8227 7.97693 21.3576 7.97693C20.8925 7.97693 20.5154 7.59986 20.5154 7.13473V4.67633L13.5311 11.6606C13.2022 11.9895 12.6689 11.9895 12.34 11.6606C12.0111 11.3317 12.0111 10.7984 12.34 10.4695L19.3244 3.48519H16.8658C16.4007 3.48519 16.0236 3.10812 16.0236 2.64298Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8122_64953">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
