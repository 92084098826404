export default [
  {
    'Code': '001',
    'Name': 'BANCO DO BRASIL',
    'Document': '00.000.000/0001-91',
    'CreatedAt': '2017-04-19 15:52:42.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '341',
    'Name': 'ITAÚ',
    'CreatedAt': '2017-04-19 15:53:59.107',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '104',
    'Name': 'CAIXA ECONÔMICA FEDERAL',
    'CreatedAt': '2017-04-19 15:54:41.200',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '033',
    'Name': 'SANTANDER',
    'CreatedAt': '2017-04-19 15:55:59.197',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '070',
    'Name': 'BRB - BANCO DE BRASÌLIA',
    'CreatedAt': '2017-04-19 15:58:17.387',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '077',
    'Name': 'BANCO INTER',
    'Document': '00.416.968/0001-01',
    'CreatedAt': '2017-04-19 15:58:39.887',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '237',
    'Name': 'BRADESCO',
    'CreatedAt': '2017-04-19 16:00:31.407',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '745',
    'Name': 'CITIBANK',
    'CreatedAt': '2017-04-19 16:01:50.353',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '422',
    'Name': 'BANCO SAFRA',
    'CreatedAt': '2017-04-19 16:04:20.790',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '399',
    'Name': 'BANCO HSBC',
    'CreatedAt': '2017-04-19 16:05:20.353',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '756',
    'Name': 'BANCO SICOOB',
    'CreatedAt': '2017-08-01 22:29:11.827',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '212',
    'Name': 'BANCO ORIGINAL',
    'CreatedAt': '2017-10-11 20:33:06.803',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '002',
    'Name': 'BANCO CENTRAL DO BRASIL',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '003',
    'Name': 'BANCO DA AMAZONIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '004',
    'Name': 'BANCO DO NORDESTE DO BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '007',
    'Name': 'BANCO NAC DESENV. ECO. SOCIAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '008',
    'Name': 'BANCO MERIDIONAL DO BRASIL',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '020',
    'Name': 'BANCO DO ESTADO DE ALAGOAS S.A',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '021',
    'Name': 'BANCO DO ESTADO DO ESPIRITO SANTO S.A',
    'CreatedAt': '2018-01-29 15:12:24.270',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '022',
    'Name': 'BANCO DE CREDITO REAL DE MINAS GERAIS SA',
    'CreatedAt': '2018-01-29 15:12:24.273',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '024',
    'Name': 'BANCO DO ESTADO DE PERNAMBUCO',
    'CreatedAt': '2018-01-29 15:12:24.273',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '025',
    'Name': 'BANCO ALFA S/A',
    'CreatedAt': '2018-01-29 15:12:24.273',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '026',
    'Name': 'BANCO DO ESTADO DO ACRE S.A',
    'CreatedAt': '2018-01-29 15:12:24.273',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '027',
    'Name': 'BANCO DO ESTADO DE SANTA CATARINA S.A',
    'CreatedAt': '2018-01-29 15:12:24.273',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '028',
    'Name': 'BANCO DO ESTADO DA BAHIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.277',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '029',
    'Name': 'BANCO DO ESTADO DO RIO DE JANEIRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.277',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '030',
    'Name': 'BANCO DO ESTADO DA PARAIBA S.A',
    'CreatedAt': '2018-01-29 15:12:24.277',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '031',
    'Name': 'BANCO DO ESTADO DE GOIAS S.A',
    'CreatedAt': '2018-01-29 15:12:24.277',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '032',
    'Name': 'BANCO DO ESTADO DO MATO GROSSO S.A.',
    'CreatedAt': '2018-01-29 15:12:24.277',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '034',
    'Name': 'BANCO DO ESADO DO AMAZONAS S.A',
    'CreatedAt': '2018-01-29 15:12:24.280',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '035',
    'Name': 'BANCO DO ESTADO DO CEARA S.A',
    'CreatedAt': '2018-01-29 15:12:24.280',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '036',
    'Name': 'BANCO DO ESTADO DO MARANHAO S.A',
    'CreatedAt': '2018-01-29 15:12:24.280',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '037',
    'Name': 'BANCO DO ESTADO DO PARA S.A',
    'CreatedAt': '2018-01-29 15:12:24.280',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '038',
    'Name': 'BANCO DO ESTADO DO PARANA S.A',
    'CreatedAt': '2018-01-29 15:12:24.280',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '039',
    'Name': 'BANCO DO ESTADO DO PIAUI S.A',
    'CreatedAt': '2018-01-29 15:12:24.280',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '041',
    'Name': 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A',
    'CreatedAt': '2018-01-29 15:12:24.283',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '047',
    'Name': 'BANCO DO ESTADO DE SERGIPE S.A',
    'CreatedAt': '2018-01-29 15:12:24.283',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '048',
    'Name': 'BANCO DO ESTADO DE MINAS GERAIS S.A',
    'CreatedAt': '2018-01-29 15:12:24.283',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '059',
    'Name': 'BANCO DO ESTADO DE RONDONIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.283',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '106',
    'Name': 'BANCO ITABANCO S.A.',
    'CreatedAt': '2018-01-29 15:12:24.287',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '107',
    'Name': 'BANCO BBM S.A',
    'CreatedAt': '2018-01-29 15:12:24.287',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '109',
    'Name': 'BANCO CREDIBANCO S.A',
    'CreatedAt': '2018-01-29 15:12:24.290',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '116',
    'Name': 'BANCO B.N.L DO BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.290',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '148',
    'Name': 'MULTI BANCO S.A',
    'CreatedAt': '2018-01-29 15:12:24.290',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '151',
    'Name': 'CAIXA ECONOMICA DO ESTADO DE SAO PAULO',
    'CreatedAt': '2018-01-29 15:12:24.290',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '153',
    'Name': 'CAIXA ECONOMICA DO ESTADO DO R.G.SUL',
    'CreatedAt': '2018-01-29 15:12:24.290',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '165',
    'Name': 'BANCO NORCHEM S.A',
    'CreatedAt': '2018-01-29 15:12:24.290',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '166',
    'Name': 'BANCO INTER-ATLANTICO S.A',
    'CreatedAt': '2018-01-29 15:12:24.293',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '168',
    'Name': 'BANCO C.C.F. BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.293',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '175',
    'Name': 'CONTINENTAL BANCO S.A',
    'CreatedAt': '2018-01-29 15:12:24.293',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '184',
    'Name': 'BBA - CREDITANSTALT S.A',
    'CreatedAt': '2018-01-29 15:12:24.293',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '199',
    'Name': 'BANCO FINANCIAL PORTUGUES',
    'CreatedAt': '2018-01-29 15:12:24.293',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '200',
    'Name': 'BANCO FRICRISA AXELRUD S.A',
    'CreatedAt': '2018-01-29 15:12:24.297',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '201',
    'Name': 'BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.297',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '204',
    'Name': 'BANCO S.R.L S.A',
    'CreatedAt': '2018-01-29 15:12:24.297',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '205',
    'Name': 'BANCO SUL AMERICA S.A',
    'CreatedAt': '2018-01-29 15:12:24.297',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '206',
    'Name': 'BANCO MARTINELLI S.A',
    'CreatedAt': '2018-01-29 15:12:24.297',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '208',
    'Name': 'BANCO PACTUAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.300',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '210',
    'Name': 'DEUTSCH SUDAMERIKANICHE BANK AG',
    'CreatedAt': '2018-01-29 15:12:24.300',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '211',
    'Name': 'BANCO SISTEMA S.A',
    'CreatedAt': '2018-01-29 15:12:24.300',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '213',
    'Name': 'BANCO ARBI S.A',
    'CreatedAt': '2018-01-29 15:12:24.300',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '214',
    'Name': 'BANCO DIBENS S.A',
    'CreatedAt': '2018-01-29 15:12:24.303',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '215',
    'Name': 'BANCO AMERICA DO SUL S.A',
    'CreatedAt': '2018-01-29 15:12:24.303',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '216',
    'Name': 'BANCO REGIONAL MALCON S.A',
    'CreatedAt': '2018-01-29 15:12:24.303',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '217',
    'Name': 'BANCO AGROINVEST S.A',
    'CreatedAt': '2018-01-29 15:12:24.303',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '218',
    'Name': 'BS2',
    'Document': '71.027.866/0001-34',
    'CreatedAt': '2018-01-29 15:12:24.303',
    'UpdatedAt': '2019-05-21 08:14:51.000',
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '219',
    'Name': 'BANCO DE CREDITO DE SAO PAULO S.A',
    'CreatedAt': '2018-01-29 15:12:24.307',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '220',
    'Name': 'BANCO CREFISUL',
    'CreatedAt': '2018-01-29 15:12:24.307',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '221',
    'Name': 'BANCO GRAPHUS S.A',
    'CreatedAt': '2018-01-29 15:12:24.307',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '222',
    'Name': 'BANCO AGF BRASIL S. A.',
    'CreatedAt': '2018-01-29 15:12:24.307',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '223',
    'Name': 'BANCO INTERUNION S.A',
    'CreatedAt': '2018-01-29 15:12:24.307',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '224',
    'Name': 'BANCO FIBRA S.A',
    'CreatedAt': '2018-01-29 15:12:24.307',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '225',
    'Name': 'BANCO BRASCAN S.A',
    'CreatedAt': '2018-01-29 15:12:24.310',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '228',
    'Name': 'BANCO ICATU S.A',
    'CreatedAt': '2018-01-29 15:12:24.310',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '229',
    'Name': 'BANCO CRUZEIRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.310',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '230',
    'Name': 'BANCO BANDEIRANTES S.A',
    'CreatedAt': '2018-01-29 15:12:24.310',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '231',
    'Name': 'BANCO BOAVISTA S.A',
    'CreatedAt': '2018-01-29 15:12:24.310',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '232',
    'Name': 'BANCO INTERPART S.A',
    'CreatedAt': '2018-01-29 15:12:24.310',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '233',
    'Name': 'BANCO MAPPIN S.A',
    'CreatedAt': '2018-01-29 15:12:24.313',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '234',
    'Name': 'BANCO LAVRA S.A.',
    'CreatedAt': '2018-01-29 15:12:24.313',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '235',
    'Name': 'BANCO LIBERAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.313',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '236',
    'Name': 'BANCO CAMBIAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.313',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '239',
    'Name': 'BANCO BANCRED S.A',
    'CreatedAt': '2018-01-29 15:12:24.317',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '240',
    'Name': 'BANCO DE CREDITO REAL DE MINAS GERAIS S.',
    'CreatedAt': '2018-01-29 15:12:24.317',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '241',
    'Name': 'BANCO CLASSICO S.A',
    'CreatedAt': '2018-01-29 15:12:24.317',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '242',
    'Name': 'BANCO EUROINVEST S.A',
    'CreatedAt': '2018-01-29 15:12:24.317',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '243',
    'Name': 'BANCO STOCK S.A',
    'CreatedAt': '2018-01-29 15:12:24.317',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '244',
    'Name': 'BANCO CIDADE S.A',
    'CreatedAt': '2018-01-29 15:12:24.320',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '245',
    'Name': 'BANCO EMPRESARIAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.320',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '246',
    'Name': 'BANCO ABC ROMA S.A',
    'CreatedAt': '2018-01-29 15:12:24.320',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '247',
    'Name': 'BANCO OMEGA S.A',
    'CreatedAt': '2018-01-29 15:12:24.320',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '249',
    'Name': 'BANCO INVESTCRED S.A',
    'CreatedAt': '2018-01-29 15:12:24.320',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '250',
    'Name': 'BANCO SCHAHIN CURY S.A',
    'CreatedAt': '2018-01-29 15:12:24.320',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '251',
    'Name': 'BANCO SAO JORGE S.A.',
    'CreatedAt': '2018-01-29 15:12:24.323',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '252',
    'Name': 'BANCO FININVEST S.A',
    'CreatedAt': '2018-01-29 15:12:24.323',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '254',
    'Name': 'BANCO PARANA BANCO S.A',
    'CreatedAt': '2018-01-29 15:12:24.323',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '255',
    'Name': 'MILBANCO S.A.',
    'CreatedAt': '2018-01-29 15:12:24.323',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '256',
    'Name': 'BANCO GULVINVEST S.A',
    'CreatedAt': '2018-01-29 15:12:24.323',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '258',
    'Name': 'BANCO INDUSCRED S.A',
    'CreatedAt': '2018-01-29 15:12:24.327',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '261',
    'Name': 'BANCO VARIG S.A',
    'CreatedAt': '2018-01-29 15:12:24.327',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '262',
    'Name': 'BANCO BOREAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.327',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '263',
    'Name': 'BANCO CACIQUE',
    'CreatedAt': '2018-01-29 15:12:24.327',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '264',
    'Name': 'BANCO PERFORMANCE S.A',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '265',
    'Name': 'BANCO FATOR S.A',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '266',
    'Name': 'BANCO CEDULA S.A',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '267',
    'Name': 'BANCO BBM-COM.C.IMOB.CFI S.A.',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '275',
    'Name': 'BANCO REAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '277',
    'Name': 'BANCO PLANIBANC S.A',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '282',
    'Name': 'BANCO BRASILEIRO COMERCIAL',
    'CreatedAt': '2018-01-29 15:12:24.330',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '291',
    'Name': 'BANCO DE CREDITO NACIONAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.333',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '294',
    'Name': 'BCR - BANCO DE CREDITO REAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.333',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '295',
    'Name': 'BANCO CREDIPLAN S.A',
    'CreatedAt': '2018-01-29 15:12:24.333',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '300',
    'Name': 'BANCO DE LA NACION ARGENTINA S.A',
    'CreatedAt': '2018-01-29 15:12:24.333',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '302',
    'Name': 'BANCO DO PROGRESSO S.A',
    'CreatedAt': '2018-01-29 15:12:24.337',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '303',
    'Name': 'BANCO HNF S.A.',
    'CreatedAt': '2018-01-29 15:12:24.337',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '304',
    'Name': 'BANCO PONTUAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.337',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '308',
    'Name': 'BANCO COMERCIAL BANCESA S.A.',
    'CreatedAt': '2018-01-29 15:12:24.337',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '318',
    'Name': 'BANCO B.M.G. S.A',
    'CreatedAt': '2018-01-29 15:12:24.337',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '320',
    'Name': 'BANCO INDUSTRIAL E COMERCIAL',
    'CreatedAt': '2018-01-29 15:12:24.340',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '346',
    'Name': 'BANCO FRANCES E BRASILEIRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.340',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '347',
    'Name': 'BANCO SUDAMERIS BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.340',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '351',
    'Name': 'BANCO BOZANO SIMONSEN S.A',
    'CreatedAt': '2018-01-29 15:12:24.340',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '353',
    'Name': 'BANCO GERAL DO COMERCIO S.A',
    'CreatedAt': '2018-01-29 15:12:24.340',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '356',
    'Name': 'ABN AMRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.340',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '366',
    'Name': 'BANCO SOGERAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.343',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '369',
    'Name': 'PONTUAL',
    'CreatedAt': '2018-01-29 15:12:24.343',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '370',
    'Name': 'BEAL - BANCO EUROPEU PARA AMERICA LATINA',
    'CreatedAt': '2018-01-29 15:12:24.343',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '372',
    'Name': 'BANCO ITAMARATI S.A',
    'CreatedAt': '2018-01-29 15:12:24.347',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '375',
    'Name': 'BANCO FENICIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.347',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '376',
    'Name': 'CHASE MANHATTAN BANK S.A',
    'CreatedAt': '2018-01-29 15:12:24.347',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '388',
    'Name': 'BANCO MERCANTIL DE DESCONTOS S/A',
    'CreatedAt': '2018-01-29 15:12:24.347',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '389',
    'Name': 'BANCO MERCANTIL DO BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.347',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '392',
    'Name': 'BANCO MERCANTIL DE SAO PAULO S.A',
    'CreatedAt': '2018-01-29 15:12:24.350',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '394',
    'Name': 'BANCO B.M.C. S.A',
    'CreatedAt': '2018-01-29 15:12:24.350',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '409',
    'Name': 'UNIBANCO - UNIAO DOS BANCOS BRASILEIROS',
    'CreatedAt': '2018-01-29 15:12:24.350',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '412',
    'Name': 'BANCO NACIONAL DA BAHIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.350',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '415',
    'Name': 'BANCO NACIONAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.350',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '420',
    'Name': 'BANCO NACIONAL DO NORTE S.A',
    'CreatedAt': '2018-01-29 15:12:24.350',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '424',
    'Name': 'BANCO NOROESTE S.A',
    'CreatedAt': '2018-01-29 15:12:24.353',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '434',
    'Name': 'BANCO FORTALEZA S.A',
    'CreatedAt': '2018-01-29 15:12:24.353',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '453',
    'Name': 'BANCO RURAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.353',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '456',
    'Name': 'BANCO TOKIO S.A',
    'CreatedAt': '2018-01-29 15:12:24.353',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '464',
    'Name': 'BANCO SUMITOMO BRASILEIRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.357',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '466',
    'Name': 'BANCO MITSUBISHI BRASILEIRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.357',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '472',
    'Name': 'LLOYDS BANK PLC',
    'CreatedAt': '2018-01-29 15:12:24.357',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '473',
    'Name': 'BANCO FINANCIAL PORTUGUES S.A',
    'CreatedAt': '2018-01-29 15:12:24.357',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '477',
    'Name': 'CITIBANK N.A',
    'CreatedAt': '2018-01-29 15:12:24.357',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '479',
    'Name': 'BANCO DE BOSTON S.A',
    'CreatedAt': '2018-01-29 15:12:24.360',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '480',
    'Name': 'BANCO PORTUGUES DO ATLANTICO-BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.360',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '483',
    'Name': 'BANCO AGRIMISA S.A.',
    'CreatedAt': '2018-01-29 15:12:24.360',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '487',
    'Name': 'DEUTSCHE BANK S.A - BANCO ALEMAO',
    'CreatedAt': '2018-01-29 15:12:24.360',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '488',
    'Name': 'BANCO J. P. MORGAN S.A',
    'CreatedAt': '2018-01-29 15:12:24.360',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '489',
    'Name': 'BANESTO BANCO URUGAUAY S.A',
    'CreatedAt': '2018-01-29 15:12:24.360',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '492',
    'Name': 'INTERNATIONALE NEDERLANDEN BANK N.V.',
    'CreatedAt': '2018-01-29 15:12:24.363',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '493',
    'Name': 'BANCO UNION S.A.C.A',
    'CreatedAt': '2018-01-29 15:12:24.363',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '494',
    'Name': 'BANCO LA REP. ORIENTAL DEL URUGUAY',
    'CreatedAt': '2018-01-29 15:12:24.363',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '495',
    'Name': 'BANCO LA PROVINCIA DE BUENOS AIRES',
    'CreatedAt': '2018-01-29 15:12:24.363',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '496',
    'Name': 'BANCO EXTERIOR DE ESPANA S.A',
    'CreatedAt': '2018-01-29 15:12:24.363',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '498',
    'Name': 'CENTRO HISPANO BANCO',
    'CreatedAt': '2018-01-29 15:12:24.367',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '499',
    'Name': 'BANCO IOCHPE S.A',
    'CreatedAt': '2018-01-29 15:12:24.367',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '501',
    'Name': 'BANCO BRASILEIRO IRAQUIANO S.A.',
    'CreatedAt': '2018-01-29 15:12:24.367',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '502',
    'Name': 'BANCO SANTANDER S.A',
    'CreatedAt': '2018-01-29 15:12:24.367',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '504',
    'Name': 'BANCO MULTIPLIC S.A',
    'CreatedAt': '2018-01-29 15:12:24.367',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '505',
    'Name': 'BANCO GARANTIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.370',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '600',
    'Name': 'BANCO LUSO BRASILEIRO S.A',
    'CreatedAt': '2018-01-29 15:12:24.370',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '601',
    'Name': 'BFC BANCO S.A.',
    'CreatedAt': '2018-01-29 15:12:24.370',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '602',
    'Name': 'BANCO PATENTE S.A',
    'CreatedAt': '2018-01-29 15:12:24.370',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '604',
    'Name': 'BANCO INDUSTRIAL DO BRASIL S.A',
    'CreatedAt': '2018-01-29 15:12:24.370',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '607',
    'Name': 'BANCO SANTOS NEVES S.A',
    'CreatedAt': '2018-01-29 15:12:24.373',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '608',
    'Name': 'BANCO OPEN S.A',
    'CreatedAt': '2018-01-29 15:12:24.373',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '610',
    'Name': 'BANCO V.R. S.A',
    'CreatedAt': '2018-01-29 15:12:24.373',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '611',
    'Name': 'BANCO PAULISTA S.A',
    'CreatedAt': '2018-01-29 15:12:24.373',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '612',
    'Name': 'BANCO GUANABARA S.A',
    'CreatedAt': '2018-01-29 15:12:24.377',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '613',
    'Name': 'BANCO PECUNIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.377',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '616',
    'Name': 'BANCO INTERPACIFICO S.A',
    'CreatedAt': '2018-01-29 15:12:24.377',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '617',
    'Name': 'BANCO INVESTOR S.A.',
    'CreatedAt': '2018-01-29 15:12:24.377',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '618',
    'Name': 'BANCO TENDENCIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.377',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '621',
    'Name': 'BANCO APLICAP S.A.',
    'CreatedAt': '2018-01-29 15:12:24.380',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '622',
    'Name': 'BANCO DRACMA S.A',
    'CreatedAt': '2018-01-29 15:12:24.380',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '623',
    'Name': 'BANCO PANAMERICANO S.A',
    'CreatedAt': '2018-01-29 15:12:24.380',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '624',
    'Name': 'BANCO GENERAL MOTORS S.A',
    'CreatedAt': '2018-01-29 15:12:24.380',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '625',
    'Name': 'BANCO ARAUCARIA S.A',
    'CreatedAt': '2018-01-29 15:12:24.380',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '626',
    'Name': 'BANCO FICSA S.A',
    'CreatedAt': '2018-01-29 15:12:24.380',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '627',
    'Name': 'BANCO DESTAK S.A',
    'CreatedAt': '2018-01-29 15:12:24.383',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '628',
    'Name': 'BANCO CRITERIUM S.A',
    'CreatedAt': '2018-01-29 15:12:24.383',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '629',
    'Name': 'BANCORP BANCO COML. E. DE INVESTMENTO',
    'CreatedAt': '2018-01-29 15:12:24.383',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '630',
    'Name': 'BANCO INTERCAP S.A',
    'CreatedAt': '2018-01-29 15:12:24.383',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '633',
    'Name': 'BANCO REDIMENTO S.A',
    'CreatedAt': '2018-01-29 15:12:24.383',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '634',
    'Name': 'BANCO TRIANGULO S.A',
    'CreatedAt': '2018-01-29 15:12:24.387',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '635',
    'Name': 'BANCO DO ESTADO DO AMAPA S.A',
    'CreatedAt': '2018-01-29 15:12:24.387',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '637',
    'Name': 'BANCO SOFISA S.A',
    'CreatedAt': '2018-01-29 15:12:24.387',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '638',
    'Name': 'BANCO PROSPER S.A',
    'CreatedAt': '2018-01-29 15:12:24.387',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '639',
    'Name': 'BIG S.A. - BANCO IRMAOS GUIMARAES',
    'CreatedAt': '2018-01-29 15:12:24.387',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '640',
    'Name': 'BANCO DE CREDITO METROPOLITANO S.A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '641',
    'Name': 'BANCO EXCEL ECONOMICO S/A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '643',
    'Name': 'BANCO SEGMENTO S.A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '645',
    'Name': 'BANCO DO ESTADO DE RORAIMA S.A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '647',
    'Name': 'BANCO MARKA S.A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '648',
    'Name': 'BANCO ATLANTIS S.A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '649',
    'Name': 'BANCO DIMENSAO S.A',
    'CreatedAt': '2018-01-29 15:12:24.390',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '650',
    'Name': 'BANCO PEBB S.A',
    'CreatedAt': '2018-01-29 15:12:24.393',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '652',
    'Name': 'BANCO FRANCES E BRASILEIRO SA',
    'CreatedAt': '2018-01-29 15:12:24.393',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '653',
    'Name': 'BANCO INDUSVAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.393',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '654',
    'Name': 'BANCO A. J. RENNER S.A',
    'CreatedAt': '2018-01-29 15:12:24.393',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '655',
    'Name': 'BANCO VOTORANTIM S.A.',
    'CreatedAt': '2018-01-29 15:12:24.393',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '656',
    'Name': 'BANCO MATRIX S.A',
    'CreatedAt': '2018-01-29 15:12:24.397',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '657',
    'Name': 'BANCO TECNICORP S.A',
    'CreatedAt': '2018-01-29 15:12:24.397',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '658',
    'Name': 'BANCO PORTO REAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.397',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '702',
    'Name': 'BANCO SANTOS S.A',
    'CreatedAt': '2018-01-29 15:12:24.397',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '705',
    'Name': 'BANCO INVESTCORP S.A.',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '707',
    'Name': 'BANCO DAYCOVAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '711',
    'Name': 'BANCO VETOR S.A.',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '713',
    'Name': 'BANCO CINDAM S.A',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '715',
    'Name': 'BANCO VEGA S.A',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '718',
    'Name': 'BANCO OPERADOR S.A',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '719',
    'Name': 'BANCO PRIMUS S.A',
    'CreatedAt': '2018-01-29 15:12:24.400',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '720',
    'Name': 'BANCO MAXINVEST S.A',
    'CreatedAt': '2018-01-29 15:12:24.403',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '721',
    'Name': 'BANCO CREDIBEL S.A',
    'CreatedAt': '2018-01-29 15:12:24.403',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '722',
    'Name': 'BANCO INTERIOR DE SAO PAULO S.A',
    'CreatedAt': '2018-01-29 15:12:24.403',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '724',
    'Name': 'BANCO PORTO SEGURO S.A',
    'CreatedAt': '2018-01-29 15:12:24.403',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '725',
    'Name': 'BANCO FINABANCO S.A',
    'CreatedAt': '2018-01-29 15:12:24.407',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '726',
    'Name': 'BANCO UNIVERSAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.407',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '728',
    'Name': 'BANCO FITAL S.A',
    'CreatedAt': '2018-01-29 15:12:24.407',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '729',
    'Name': 'BANCO FONTE S.A',
    'CreatedAt': '2018-01-29 15:12:24.407',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '730',
    'Name': 'BANCO COMERCIAL PARAGUAYO S.A',
    'CreatedAt': '2018-01-29 15:12:24.407',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '731',
    'Name': 'BANCO GNPP S.A.',
    'CreatedAt': '2018-01-29 15:12:24.410',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '732',
    'Name': 'BANCO PREMIER S.A.',
    'CreatedAt': '2018-01-29 15:12:24.410',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '733',
    'Name': 'BANCO NACOES S.A.',
    'CreatedAt': '2018-01-29 15:12:24.410',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '734',
    'Name': 'BANCO GERDAU S.A',
    'CreatedAt': '2018-01-29 15:12:24.410',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '735',
    'Name': 'BACO POTENCIAL',
    'CreatedAt': '2018-01-29 15:12:24.410',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '736',
    'Name': 'BANCO UNITED S.A',
    'CreatedAt': '2018-01-29 15:12:24.410',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '737',
    'Name': 'THECA',
    'CreatedAt': '2018-01-29 15:12:24.413',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '738',
    'Name': 'MARADA',
    'CreatedAt': '2018-01-29 15:12:24.413',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '739',
    'Name': 'BGN',
    'CreatedAt': '2018-01-29 15:12:24.413',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '740',
    'Name': 'BCN BARCLAYS',
    'CreatedAt': '2018-01-29 15:12:24.413',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '741',
    'Name': 'BRP',
    'CreatedAt': '2018-01-29 15:12:24.413',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '742',
    'Name': 'EQUATORIAL',
    'CreatedAt': '2018-01-29 15:12:24.417',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '743',
    'Name': 'BANCO EMBLEMA S.A',
    'CreatedAt': '2018-01-29 15:12:24.417',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '744',
    'Name': 'THE FIRST NATIONAL BANK OF BOSTON',
    'CreatedAt': '2018-01-29 15:12:24.417',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '746',
    'Name': 'MODAL S\\A',
    'CreatedAt': '2018-01-29 15:12:24.417',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '747',
    'Name': 'RAIBOBANK DO BRASIL',
    'CreatedAt': '2018-01-29 15:12:24.420',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '748',
    'Name': 'SICREDI',
    'CreatedAt': '2018-01-29 15:12:24.420',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '749',
    'Name': 'BRMSANTIL SA',
    'CreatedAt': '2018-01-29 15:12:24.420',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '750',
    'Name': 'BANCO REPUBLIC NATIONAL OF NEW YORK (BRA',
    'CreatedAt': '2018-01-29 15:12:24.420',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '751',
    'Name': 'DRESDNER BANK LATEINAMERIKA-BRASIL S/A',
    'CreatedAt': '2018-01-29 15:12:24.420',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '752',
    'Name': 'BANCO BANQUE NATIONALE DE PARIS BRASIL S',
    'CreatedAt': '2018-01-29 15:12:24.420',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '753',
    'Name': 'BANCO COMERCIAL URUGUAI S.A.',
    'CreatedAt': '2018-01-29 15:12:24.423',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '755',
    'Name': 'BANCO MERRILL LYNCH S.A',
    'CreatedAt': '2018-01-29 15:12:24.423',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '757',
    'Name': 'BANCO KEB DO BRASIL S.A.',
    'CreatedAt': '2018-01-29 15:12:24.423',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '735',
    'Name': 'BANCO NEON',
    'CreatedAt': '2018-01-29 16:47:35.090',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '260',
    'Name': 'NUBANK',
    'CreatedAt': '2018-01-29 16:47:35.153',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '102',
    'Name': 'XP INVESTIMENTOS',
    'CreatedAt': '2018-01-29 16:47:35.210',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '336',
    'Name': 'BANCO C6 S.A.',
    'Document': '31.872.495/0001-72',
    'CreatedAt': '2019-08-02 13:11:00.000',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '290',
    'Name': 'PagSeguro Interne S.A.',
    'Document': '08.561.701/0001-01',
    'CreatedAt': '2019-08-21 12:48:00.000',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '197',
    'Name': 'BANCO STONE',
    'Document': '16.501.555/0001-57',
    'CreatedAt': '2023-09-01 10:06:00.000',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '403',
    'Name': 'CORA SCD',
    'CreatedAt': '2023-10-05 15:54:41.200',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  },
  {
    'Code': '542',
    'Name': 'CLOUDWALK IP LTDA',
    'CreatedAt': '2024-01-01 15:54:41.200',
    'UpdatedAt': null,
    'DeletedAt': null,
    'IsDeleted': false
  }
]