export const QRCode = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.36493 5.4476C2.36493 4.11085 3.44288 3.0329 4.77963 3.0329H6.44493C6.76684 3.0329 7.02779 2.77195 7.02779 2.45004C7.02779 2.12814 6.76684 1.86719 6.44493 1.86719H4.77963C2.79908 1.86719 1.19922 3.46705 1.19922 5.4476V7.1129C1.19922 7.4348 1.46017 7.69576 1.78208 7.69576C2.10398 7.69576 2.36493 7.4348 2.36493 7.1129V5.4476Z"
          fill={color ?? "currentColor"}
        />
        <path
          d="M9.5535 1.86719C9.2316 1.86719 8.97065 2.12814 8.97065 2.45004C8.97065 2.77195 9.2316 3.0329 9.5535 3.0329H11.2188C12.5556 3.0329 13.6335 4.11085 13.6335 5.4476V7.1129C13.6335 7.4348 13.8945 7.69576 14.2164 7.69576C14.5383 7.69576 14.7992 7.4348 14.7992 7.1129V5.4476C14.7992 3.46705 13.1994 1.86719 11.2188 1.86719H9.5535Z"
          fill={color ?? "currentColor"}
        />
        <path
          d="M14.2164 10.4158C14.5383 10.4158 14.7992 10.6767 14.7992 10.9986V11.97C14.7992 13.9045 13.2366 15.4672 11.3021 15.4672H10.3306C10.0087 15.4672 9.74779 15.2062 9.74779 14.8843C9.74779 14.5624 10.0087 14.3015 10.3306 14.3015H11.3021C12.5927 14.3015 13.6335 13.2607 13.6335 11.97V10.9986C13.6335 10.6767 13.8945 10.4158 14.2164 10.4158Z"
          fill={color ?? "currentColor"}
        />
        <path
          d="M2.36493 10.2215C2.36493 9.89957 2.10398 9.63862 1.78208 9.63862C1.46017 9.63862 1.19922 9.89957 1.19922 10.2215V11.8868C1.19922 13.8673 2.79908 15.4672 4.77963 15.4672H6.44493C6.76684 15.4672 7.02779 15.2062 7.02779 14.8843C7.02779 14.5624 6.76684 14.3015 6.44493 14.3015H4.77963C3.44288 14.3015 2.36493 13.2235 2.36493 11.8868V10.2215Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.72879 4.27105C4.03208 3.96776 4.43966 3.86719 4.84036 3.86719H6.08379C6.48449 3.86719 6.89207 3.96776 7.19536 4.27105C7.49865 4.57433 7.59922 4.98191 7.59922 5.38262V6.62604C7.59922 7.02675 7.49865 7.43433 7.19536 7.73762C6.89207 8.0409 6.48449 8.14147 6.08379 8.14147H4.84036C4.43966 8.14147 4.03208 8.0409 3.72879 7.73762C3.4255 7.43433 3.32493 7.02675 3.32493 6.62604V5.38262C3.32493 4.98191 3.4255 4.57433 3.72879 4.27105ZM4.55308 5.09533C4.54551 5.1029 4.49065 5.1616 4.49065 5.38262V6.62604C4.49065 6.84706 4.54551 6.90576 4.55308 6.91333C4.56064 6.9209 4.61935 6.97576 4.84036 6.97576H6.08379C6.3048 6.97576 6.36351 6.9209 6.37108 6.91333C6.37865 6.90576 6.4335 6.84706 6.4335 6.62604V5.38262C6.4335 5.1616 6.37865 5.1029 6.37108 5.09533C6.36351 5.08776 6.3048 5.0329 6.08379 5.0329H4.84036C4.61935 5.0329 4.56064 5.08776 4.55308 5.09533Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0404 3.86719C9.63966 3.86719 9.23208 3.96776 8.92879 4.27105C8.6255 4.57433 8.52493 4.98191 8.52493 5.38262V6.62604C8.52493 7.02675 8.6255 7.43433 8.92879 7.73762C9.23208 8.0409 9.63966 8.14147 10.0404 8.14147H11.2838C11.6845 8.14147 12.0921 8.0409 12.3954 7.73762C12.6986 7.43433 12.7992 7.02675 12.7992 6.62604V5.38262C12.7992 4.98191 12.6986 4.57433 12.3954 4.27105C12.0921 3.96776 11.6845 3.86719 11.2838 3.86719H10.0404ZM9.69065 5.38262C9.69065 5.1616 9.74551 5.1029 9.75308 5.09533C9.76065 5.08776 9.81935 5.0329 10.0404 5.0329H11.2838C11.5048 5.0329 11.5635 5.08776 11.5711 5.09533C11.5786 5.1029 11.6335 5.1616 11.6335 5.38262V6.62604C11.6335 6.84706 11.5786 6.90576 11.5711 6.91333C11.5635 6.9209 11.5048 6.97576 11.2838 6.97576H10.0404C9.81935 6.97576 9.76065 6.9209 9.75308 6.91333C9.74551 6.90576 9.69065 6.84706 9.69065 6.62604V5.38262Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.72879 9.59676C4.03208 9.29347 4.43966 9.1929 4.84036 9.1929H6.08379C6.48449 9.1929 6.89207 9.29347 7.19536 9.59676C7.49865 9.90005 7.59922 10.3076 7.59922 10.7083V11.9518C7.59922 12.3525 7.49865 12.76 7.19536 13.0633C6.89207 13.3666 6.48449 13.4672 6.08379 13.4672H4.84036C4.43966 13.4672 4.03208 13.3666 3.72879 13.0633C3.4255 12.76 3.32493 12.3525 3.32493 11.9518V10.7083C3.32493 10.3076 3.4255 9.90005 3.72879 9.59676ZM4.55308 10.421C4.54551 10.4286 4.49065 10.4873 4.49065 10.7083V11.9518C4.49065 12.1728 4.54551 12.2315 4.55308 12.239C4.56064 12.2466 4.61935 12.3015 4.84036 12.3015H6.08379C6.3048 12.3015 6.36351 12.2466 6.37108 12.239C6.37865 12.2315 6.4335 12.1728 6.4335 11.9518V10.7083C6.4335 10.4873 6.37865 10.4286 6.37108 10.421C6.36351 10.4135 6.3048 10.3586 6.08379 10.3586H4.84036C4.61935 10.3586 4.56064 10.4135 4.55308 10.421Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0404 9.1929C9.63966 9.1929 9.23208 9.29347 8.92879 9.59676C8.6255 9.90005 8.52493 10.3076 8.52493 10.7083V11.9518C8.52493 12.3525 8.6255 12.76 8.92879 13.0633C9.23208 13.3666 9.63966 13.4672 10.0404 13.4672H11.2838C11.6845 13.4672 12.0921 13.3666 12.3954 13.0633C12.6986 12.76 12.7992 12.3525 12.7992 11.9518V10.7083C12.7992 10.3076 12.6986 9.90005 12.3954 9.59676C12.0921 9.29347 11.6845 9.1929 11.2838 9.1929H10.0404ZM9.69065 10.7083C9.69065 10.4873 9.74551 10.4286 9.75308 10.421C9.76065 10.4135 9.81935 10.3586 10.0404 10.3586H11.2838C11.5048 10.3586 11.5635 10.4135 11.5711 10.421C11.5786 10.4286 11.6335 10.4873 11.6335 10.7083V11.9518C11.6335 12.1728 11.5786 12.2315 11.5711 12.239C11.5635 12.2466 11.5048 12.3015 11.2838 12.3015H10.0404C9.81935 12.3015 9.76065 12.2466 9.75308 12.239C9.74551 12.2315 9.69065 12.1728 9.69065 11.9518V10.7083Z"
          fill={color ?? "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_27892">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.667969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
