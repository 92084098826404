export const MoneyCupom = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3652_5490)">
        <path
          d="M10.5 10.5344C10.5 10.0373 10.0971 9.63438 9.6 9.63438C9.10294 9.63438 8.7 10.0373 8.7 10.5344V14.1344C8.7 14.6314 9.10294 15.0344 9.6 15.0344C10.0971 15.0344 10.5 14.6314 10.5 14.1344V10.5344Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.2 4.23438C5.29318 4.23438 3.74916 4.43588 2.74445 5.37779C1.71903 6.33913 1.5 7.83272 1.5 9.63438V10.0844C1.5 10.5814 1.90294 10.9844 2.4 10.9844C3.28334 10.9844 3.9 11.6433 3.9 12.3344C3.9 13.0255 3.28334 13.6844 2.4 13.6844C1.90294 13.6844 1.5 14.0873 1.5 14.5844V15.0344C1.5 16.836 1.71903 18.3296 2.74445 19.291C3.74916 20.2329 5.29318 20.4344 7.2 20.4344H16.8C18.7068 20.4344 20.2508 20.2329 21.2555 19.291C22.281 18.3296 22.5 16.836 22.5 15.0344C22.5 14.5373 22.0971 14.1344 21.6 14.1344C20.7167 14.1344 20.1 13.4755 20.1 12.7844C20.1 12.0933 20.7167 11.4344 21.6 11.4344C22.0971 11.4344 22.5 11.0314 22.5 10.5344V9.63438C22.5 7.83272 22.281 6.33913 21.2555 5.37779C20.2508 4.43588 18.7068 4.23438 16.8 4.23438H7.2ZM8.7 6.03438V6.93438C8.7 7.43143 9.10294 7.83438 9.6 7.83438C10.0971 7.83438 10.5 7.43143 10.5 6.93438V6.03438H16.8C18.7332 6.03438 19.5892 6.28287 20.0245 6.69096C20.439 7.07962 20.7 7.83604 20.7 9.63438V9.75441C19.3434 10.1233 18.3 11.3056 18.3 12.7844C18.3 14.2562 19.3336 15.4344 20.681 15.8091C20.614 17.0618 20.3737 17.6503 20.0245 17.9778C19.5892 18.3859 18.7332 18.6344 16.8 18.6344H10.5V17.7344C10.5 17.2373 10.0971 16.8344 9.6 16.8344C9.10294 16.8344 8.7 17.2373 8.7 17.7344V18.6344H7.2C5.26682 18.6344 4.41084 18.3859 3.97555 17.9778C3.58721 17.6137 3.33365 16.927 3.30311 15.3635C4.65823 14.9936 5.7 13.812 5.7 12.3344C5.7 10.8568 4.65823 9.67513 3.30311 9.30525C3.33365 7.74176 3.58721 7.05502 3.97555 6.69096C4.41084 6.28287 5.26682 6.03438 7.2 6.03438H8.7Z"
          fill={color ?? "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_5490">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
