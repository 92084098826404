import AuthLayout from './components/AuthLayout'
import LoginContent from './components/LoginContent'

const Login = () => {
  return (
    <>
      <AuthLayout title='Ajudamos você a criar e promover experiencias únicas.'>
        <LoginContent />
      </AuthLayout>
    </>
  )
}

export default Login
