export const PixIcon = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4275_70006)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.6532 14.7819L14.785 21.6495C13.252 23.1825 10.7542 23.1825 9.22127 21.6495L2.35306 14.781C0.819797 13.2477 0.819823 10.7497 2.35308 9.21639L9.22127 2.34877C9.9885 1.58152 10.9973 1.19922 12.0031 1.19922C13.0087 1.19922 14.0185 1.58143 14.7858 2.3488L21.6532 9.21723C23.1865 10.7505 23.1864 13.2486 21.6532 14.7819ZM10.5451 12.3893L7.7639 15.1706C7.5811 15.3414 7.33913 15.4373 7.08646 15.4373H5.95815L3.82751 13.3066C3.10169 12.5808 3.10169 11.4167 3.82751 10.6909L5.95736 8.56094H7.08646C7.33914 8.56094 7.58108 8.65681 7.76386 8.82764L10.5453 11.6092C10.7088 11.7725 10.8935 11.9026 11.0908 11.9993C10.8938 12.0958 10.7088 12.2258 10.5451 12.3893ZM13.4603 12.3893C13.2967 12.2256 13.1119 12.0958 12.915 11.9993C13.1122 11.9025 13.2969 11.7724 13.4603 11.609L16.217 8.85222C16.4041 8.66547 16.6566 8.56094 16.9198 8.56094H18.0481L20.1787 10.6917C20.9046 11.4175 20.9046 12.5816 20.1787 13.3074L18.0489 15.4373H16.9198C16.6565 15.4373 16.4039 15.3327 16.2168 15.1458L13.4603 12.3893ZM13.3113 3.82322L16.0798 6.5924C15.5789 6.7344 15.1175 7.00239 14.7428 7.37674L14.5565 7.56385L12.0027 10.1177L9.26313 7.37801C8.88864 7.00311 8.42717 6.73464 7.926 6.59244L10.6957 3.82322C11.0597 3.45925 11.5311 3.27894 12.0031 3.27894C12.4754 3.27894 12.9475 3.45944 13.3113 3.82322ZM10.6957 20.175L7.92646 17.4057C8.42735 17.2635 8.88879 16.9949 9.26313 16.6202L12.0027 13.8805L14.7428 16.6215C15.1176 16.9959 15.5791 17.2639 16.0801 17.4059L13.3105 20.175C12.5852 20.9004 11.421 20.9004 10.6957 20.175Z"
          fill={color}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_4275_70006">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
