const ScheduleIconCustom = ({ color = '#222222', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29492 10C5.74264 10 5.29492 10.4477 5.29492 11C5.29492 11.5523 5.74264 12 6.29492 12H6.3039C6.85619 12 7.3039 11.5523 7.3039 11C7.3039 10.4477 6.85619 10 6.3039 10H6.29492Z"
        fill={color}
      />
      <path
        d="M8.99609 11C8.99609 10.4477 9.44381 10 9.99609 10H10.0051C10.5574 10 11.0051 10.4477 11.0051 11C11.0051 11.5523 10.5574 12 10.0051 12H9.99609C9.44381 12 8.99609 11.5523 8.99609 11Z"
        fill={color}
      />
      <path
        d="M5.29492 14C5.29492 13.4477 5.74264 13 6.29492 13H6.3039C6.85619 13 7.3039 13.4477 7.3039 14C7.3039 14.5523 6.85619 15 6.3039 15H6.29492C5.74264 15 5.29492 14.5523 5.29492 14Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 0.75C7.41421 0.75 7.75 1.08579 7.75 1.5V2H12.25V1.5C12.25 1.08579 12.5858 0.75 13 0.75C13.4142 0.75 13.75 1.08579 13.75 1.5V2.01248C16.5806 2.21851 18 3.42729 18 7.6483V13.3241C18 17.108 17.1111 19 12.6667 19H7.33333C2.88889 19 2 17.108 2 13.3241V7.6483C2 3.42729 3.41944 2.22729 6.25 2.01306V1.5C6.25 1.08579 6.58579 0.75 7 0.75ZM6.25 3.51805C5.09942 3.61941 4.55881 3.89393 4.24322 4.25618C3.94613 4.5972 3.65462 5.23497 3.54527 6.5H16.4548C16.3454 5.2339 16.0537 4.59407 15.7561 4.25198C15.4408 3.88951 14.9004 3.6147 13.75 3.51709V4C13.75 4.41421 13.4142 4.75 13 4.75C12.5858 4.75 12.25 4.41421 12.25 4V3.5H7.75V4C7.75 4.41421 7.41421 4.75 7 4.75C6.58579 4.75 6.25 4.41421 6.25 4V3.51805ZM16.5 8H3.5V13.3241C3.5 15.1997 3.74637 16.127 4.15865 16.6284C4.50566 17.0504 5.26377 17.5 7.33333 17.5H12.6667C14.7362 17.5 15.4943 17.0504 15.8413 16.6284C16.2536 16.127 16.5 15.1997 16.5 13.3241V8Z"
        fill={color}
      />
    </svg>
  );
};

export default ScheduleIconCustom;
