import React from "react";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import TGDropdown from "./TGDropdown";

const StyledBadge = styled(Badge)(
  ({ theme, showStatusPart, background, border }) => ({
    borderRadius: "20px",
    border: border || "1px solid #EEE",
    background: background || "#FAFAFA",
    width: "40px",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    "& .MuiBadge-badge": {
      display: showStatusPart ? "block" : "none",
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main,
      border: 0,

      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1.5px solid currentColor",
        content: '""',
      },
    },
  })
);

const dropdownOptions = [
  {
    label: "Ativar",
    onClick: "some-active-function",
  },
  { label: "Remover", onClick: "" },
];

const TGBadge = ({ altText, src, showStatusPart, background, border, props }) => {
  return (
    <>
      <StyledBadge
        {...props}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        showStatusPart={showStatusPart}
        background={background}
        border={border}
      >
        <Avatar
          alt={altText || "Notificações"}
          src={src}
          sx={{ width: "20px", height: "20px" }}
        />
      </StyledBadge>
    </>
  );
};

export default TGBadge;
