import React from 'react';
import { styled } from '@mui/material/styles';
import { TGTooltip } from 'TGComponents/layout/TGTobBar/stylesMobile';
import { Stack, Typography } from '@mui/material';
import IconButtonBox from './styles';

const IconButton = ({
  text,
  icon,
  tooltip,
  tooltipPosition = 'bottom',
  onClick,
  border = '1px solid',
  borderColor = '#22222214',
  bgcolor = 'rgba(0, 0, 0, 0)',
  width = '48px',
  height = '48px',
  dark = false,
  padding,
  disabled,
  ...props
}) => {
  return (
    <TGTooltip title={tooltip} placement={tooltipPosition}>
      <IconButtonBox
        dark
        onClick={onClick}
        border={border}
        borderColor={borderColor}
        bgcolor={bgcolor}
        width={width}
        height={height}
        disabled={disabled}
        {...props}
      >
        <Stack direction='flex' justifyContent='center' gap='8px'>
          <img src={icon} alt='icone' />
          {text && (
            <Typography fontSize='14px' color={disabled ? '#2222228F' : '#222'}>
              {text}
            </Typography>
          )}
        </Stack>
      </IconButtonBox>
    </TGTooltip>
  );
};

export default IconButton;
