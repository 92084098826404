export const Printer = ({ width = 16, height = 16, color = '#00A7F0' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5094_64136)">
        <g clip-path="url(#clip1_5094_64136)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.66956 1.47815C5.1575 0.976634 5.8687 0.800781 6.70382 0.800781L9.29774 0.800782C10.1329 0.800782 10.8441 0.976635 11.332 1.47815C11.8193 1.97902 11.9557 2.67358 11.9557 3.39278V4.09221H12.4289C13.2319 4.09221 13.9476 4.27855 14.4635 4.76022C14.989 5.25078 15.2008 5.94697 15.2008 6.72909V8.02061C14.7842 7.86766 14.3425 7.88527 13.9839 7.92714V6.72909C13.9839 6.16473 13.8351 5.85104 13.6394 5.66836C13.4342 5.47678 13.0682 5.3265 12.4289 5.3265L3.57261 5.3265C2.93334 5.3265 2.56735 5.47677 2.36214 5.66836C2.16647 5.85104 2.01768 6.16473 2.01768 6.72909V10.0953C2.01768 10.6597 2.16647 10.9734 2.36214 11.1561C2.56735 11.3476 2.93334 11.4979 3.57261 11.4979H4.04585V9.64649C4.04585 9.30566 4.31826 9.02935 4.6543 9.02935H10.0069C9.63873 9.4149 9.36615 9.84451 9.22499 10.2636H5.26275V12.4677C5.26275 13.0509 5.42607 13.3838 5.65873 13.5861C5.90934 13.804 6.34964 13.9665 7.08811 13.9665H8.71064C8.87347 13.9665 9.02181 13.9586 9.15681 13.9439C9.23732 14.504 9.4257 14.8859 9.61125 15.1232C9.32802 15.1766 9.02618 15.2008 8.71064 15.2008H7.08811C6.20403 15.2008 5.42744 15.0107 4.86678 14.5232C4.35069 14.0745 4.10215 13.4492 4.05446 12.7322H3.57261C2.76963 12.7322 2.05393 12.5459 1.53801 12.0642C1.01256 11.5736 0.800781 10.8775 0.800781 10.0953V6.72909C0.800781 5.94697 1.01256 5.25078 1.53801 4.76022C2.05393 4.27855 2.76963 4.09221 3.57261 4.09221H4.04585V3.39278C4.04585 2.67358 4.18224 1.97902 4.66956 1.47815ZM5.26275 4.09221L10.7388 4.09221V3.39278C10.7388 2.79542 10.619 2.50254 10.4658 2.34513C10.3133 2.18836 9.99975 2.03507 9.29774 2.03507L6.70382 2.03507C6.00181 2.03507 5.68824 2.18836 5.53571 2.34512C5.38256 2.50254 5.26275 2.79541 5.26275 3.39278V4.09221Z"
            fill={color}
          />
          <path
            d="M4.6543 7.38364C4.31826 7.38364 4.04585 7.65994 4.04585 8.00078C4.04585 8.34162 4.31826 8.61792 4.6543 8.61792H6.27684C6.61288 8.61792 6.88529 8.34162 6.88529 8.00078C6.88529 7.65994 6.61288 7.38364 6.27684 7.38364H4.6543Z"
            fill={color}
          />
          <path
            d="M14.2018 11.366C14.5286 11.366 14.7935 11.0952 14.7935 10.7611V9.68444C14.7935 9.35038 14.5286 9.07957 14.2018 9.07957C13.9478 9.07957 13.7313 9.24316 13.6474 9.47289C12.738 9.03573 11.6194 9.20161 10.8661 9.96755L10.8649 9.96871C10.4707 10.3718 10.2488 10.8784 10.1771 11.3867C10.1304 11.7173 10.3547 12.0241 10.6781 12.0718C11.0015 12.1195 11.3016 11.8902 11.3482 11.5595C11.3869 11.2858 11.504 11.0264 11.7011 10.8247C11.9615 10.5603 12.3048 10.4342 12.6439 10.4455C12.5888 10.5374 12.557 10.6455 12.557 10.7611C12.557 11.0952 12.8219 11.366 13.1487 11.366H14.2018Z"
            fill={color}
          />
          <path
            d="M14.6936 11.5615C15.017 11.6092 15.2413 11.9159 15.1946 12.2465C15.1229 12.7548 14.901 13.2615 14.5068 13.6645L14.5056 13.6657C13.7435 14.4407 12.6073 14.6013 11.6923 14.1446C11.6218 14.3981 11.3937 14.5837 11.1232 14.5837C10.7964 14.5837 10.5315 14.3129 10.5315 13.9788V12.9021C10.5315 12.5681 10.7964 12.2973 11.1232 12.2973H11.1859C11.1931 12.2971 11.2002 12.2971 11.2074 12.2973H12.1763C12.503 12.2973 12.7679 12.5681 12.7679 12.9021C12.7679 13.0049 12.7429 13.1017 12.6986 13.1864C13.0471 13.2062 13.4028 13.0806 13.6706 12.8086C13.8677 12.6068 13.9848 12.3475 14.0235 12.0737C14.0701 11.7431 14.3702 11.5137 14.6936 11.5615Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5094_64136">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_5094_64136">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
