import { Box, Typography, styled, useMediaQuery } from '@mui/material'

const AuthPage = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  background: `url('/images/login/Background_login_overlay.webp') center/cover no-repeat`,
  display: 'flex',
  justifyContent: 'space-around',
}))

const AuthLayout = ({ children, title }) => {
  const isMobile = useMediaQuery('(max-width:1080px)')
  return (
    <>
      <AuthPage>
        {!isMobile && (
          <Box padding='60px 100px'>
            <Box mb='72px'>
              <img src='/images/login/LoginLogo.png' alt='Logo' />
            </Box>

            <Typography
              maxWidth='600px'
              fontSize={'40px'}
              color='#fff'
              fontWeight={'bold'}
            >
              {title}
            </Typography>
          </Box>
        )}
        <Box
          display='flex'
          alignItems='center'
          width={isMobile ? '100%' : 'auto'}
          marginRight={isMobile ? '' : '28px'}
        >
          {children}
        </Box>
      </AuthPage>
    </>
  )
}

export default AuthLayout
