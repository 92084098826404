import { takeEvery, put, takeLatest, call } from 'redux-saga/effects'
import Requester from '../services/requester'

import * as listsActions from '../actions/listsActions'
import * as notificationActions from '../actions/notificationActions'

const _get = (endpoint, criteria) => Requester(`${endpoint}${criteria ? '/'+criteria : ''}`, {
    method: 'GET',    
})


export function* getLists(action) {

    const { endpoint, criteria } = action.payload

    yield put(listsActions.setLoading(criteria.list))

    try {
        

        

        

        const result = yield call(_get, endpoint, criteria.arg)

        if (result.status === "success") {
            const { data } = result

            if((data.items || []).length === 0){
                yield put(notificationActions.error('Não foram encontrados registros nessa pesquisa'))

            }

            if(data?.items?.items){
                if((data?.items?.items || []).length === 0){
                    yield put(notificationActions.error('Não foram encontrados registros nessa pesquisa'))

                }
            }

            const list = {
                list: criteria.list, 
                items: data.items
            }

            yield put(listsActions.setLists(list))

        }
        else{
            if(result.message){
                yield put(notificationActions.error(result.message))

            }
        }
        
    } catch (error) {
        console.log('erro de comunicacao')
    }
    finally {
        yield put(listsActions.setLoading(false))
    }
}





