import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import * as addressActions from '../../actions/addressActions'

import Select from './Select'
import TGTextField from 'TGComponents/global/TGTextField'

const Address = ({ touched, errors, name = null }) => {
  const { values, handleChange, setFieldValue } = useFormikContext()
  const dispatch = useDispatch()

  const getAddress = (cep) => dispatch(addressActions.getAddress(cep))
  const clear = () => dispatch(addressActions.clear())

  const { data, loading } = useSelector((state) => state.address)

  useEffect(() => {
    setFieldValue(
      `${name ? name + '.' : ''}cep`,
      data?.cep ? data?.cep.replace('-', '') : ''
    )
    setFieldValue(`${name ? name + '.' : ''}endereco`, data?.logradouro || '')
    setFieldValue(`${name ? name + '.' : ''}bairro`, data?.bairro || '')
    setFieldValue(`${name ? name + '.' : ''}cidade`, data?.localidade || '')
    setFieldValue(`${name ? name + '.' : ''}estado`, data?.uf || '')
  }, [data?.cep])

  return (
    <div className='col-12'>
      <div className='row'>
        {loading ? (
          <div className='col d-flex justify-content-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div
              className='col-sm-12 col-xl-3 mb-3'
              style={{ minWidth: '280px' }}
            >
              <TGTextField
                placeholder='00000-000'
                errors={errors}
                touched={touched}
                mask='99999-999'
                label='CEP'
                type='text'
                name={`${name ? name + '.' : ''}cep`}
                id={`${name ? name + '.' : ''}cep`}
                onChange={(e) => {
                  handleChange(e)

                  const val = e.target.value.replace(/[^0-9]+/, '')
                  if (val.length === 8) {
                    getAddress(val)
                  } else {
                    clear()
                  }
                }}
              />
            </div>
            <div
              className='col-sm-6 col-xl-6 mb-3'
              style={{ minWidth: '280px' }}
            >
              <TGTextField
                placeholder='Digite o seu endereço'
                errors={errors}
                touched={touched}
                label='Endereço'
                type='text'
                name={`${name ? name + '.' : ''}endereco`}
                id={`${name ? name + '.' : ''}endereco`}
              />
            </div>

            <div
              className='col-sm-6 col-xl-3 mb-3'
              style={{ minWidth: '280px' }}
            >
              <TGTextField
                placeholder='Número'
                errors={errors}
                touched={touched}
                label='Número'
                type='text'
                name={`${name ? name + '.' : ''}numero`}
                id={`${name ? name + '.' : ''}numero`}
              />
            </div>
            <div
              className='col-sm-6 col-xl-3 mb-3'
              style={{ minWidth: '280px' }}
            >
              <TGTextField
                placeholder='Complemento'
                errors={errors}
                touched={touched}
                label='Complemento'
                type='text'
                name={`${name ? name + '.' : ''}complemento`}
                id={`${name ? name + '.' : ''}complemento`}
              />
            </div>
            <div
              className='col-sm-6 col-xl-3 mb-3'
              style={{ minWidth: '280px' }}
            >
              <TGTextField
                placeholder='Bairro'
                errors={errors}
                touched={touched}
                label='Bairro'
                type='text'
                name={`${name ? name + '.' : ''}bairro`}
                id={`${name ? name + '.' : ''}bairro`}
              />
            </div>
            <div
              className='col-sm-6 col-xl-3 mb-3'
              style={{ minWidth: '280px' }}
            >
              <TGTextField
                placeholder='Cidade'
                errors={errors}
                touched={touched}
                label='Cidade'
                type='text'
                name={`${name ? name + '.' : ''}cidade`}
                id={`${name ? name + '.' : ''}cidade`}
              />
            </div>
            <div
              className='col-sm-6 col-xl-3 mb-3'
              style={{ minWidth: '280px' }}
            >
              <Select
                errors={errors}
                touched={touched}
                label='Estado'
                id={`${name ? name + '.' : ''}estado`}
                name={`${name ? name + '.' : ''}estado`}
              >
                <option value='AC'>AC</option>
                <option value='AL'>AL</option>
                <option value='AM'>AM</option>
                <option value='AP'>AP</option>
                <option value='BA'>BA</option>
                <option value='CE'>CE</option>
                <option value='DF'>DF</option>
                <option value='ES'>ES</option>
                <option value='GO'>GO</option>
                <option value='MA'>MA</option>
                <option value='MG'>MG</option>
                <option value='MS'>MS</option>
                <option value='MT'>MT</option>
                <option value='PA'>PA</option>
                <option value='PB'>PB</option>
                <option value='PE'>PE</option>
                <option value='PI'>PI</option>
                <option value='PR'>PR</option>
                <option value='RJ'>RJ</option>
                <option value='RN'>RN</option>
                <option value='RO'>RO</option>
                <option value='RR'>RR</option>
                <option value='RS'>RS</option>
                <option value='SC'>SC</option>
                <option value='SE'>SE</option>
                <option value='SP'>SP</option>
                <option value='TO'>TO</option>
              </Select>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Address
