export const WarningIcon = ({ width = 24, height = 25, color = '#F5910D' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6166_141324)">
        <rect
          width="4.8"
          height="13.2"
          transform="translate(9.60156 6.5)"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9993 22.0902H6.18083C2.84917 22.0902 1.45697 19.5611 3.07 16.4712L6.06562 10.74L8.88841 5.35553C10.5975 2.08201 13.4011 2.08201 15.1101 5.35553L20.9285 16.4814C22.5415 19.5713 21.1398 22.1004 17.8177 22.1004H11.9993V22.0902ZM11.9992 7.20388C12.4963 7.20388 12.8992 7.63186 12.8992 8.1598V13.2587C12.8992 13.7867 12.4963 14.2147 11.9992 14.2147C11.5022 14.2147 11.0992 13.7867 11.0992 13.2587V8.1598C11.0992 7.63186 11.5022 7.20388 11.9992 7.20388ZM11.9939 16.3181C11.3312 16.3181 10.7939 16.8887 10.7939 17.5926C10.7939 18.2966 11.3312 18.8672 11.9939 18.8672H12.0007C12.6634 18.8672 13.2007 18.2966 13.2007 17.5926C13.2007 16.8887 12.6634 16.3181 12.0007 16.3181H11.9939Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6166_141324">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
