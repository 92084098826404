// TGPagination.jsx

import React from 'react';
import {
  Pagination,
  PaginationItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { ArrowLeft } from 'assets/svgs/common/SvgCustom';

const TGPagination = ({
  total,
  totalShowing,
  totalPages,
  page,
  handleChangePage,
  padding = '8px 20px 12px',
  hasTotalShowing = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const pageNumber = (pageN) =>
    pageN?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return (
    <Box
      display='flex'
      flexDirection={isMobile && 'column'}
      justifyContent={['center', 'space-between']}
      padding={padding}
      alignItems='center'
      marginBottom={isMobile && '20px'}
    >
        <Box marginBottom={isMobile && '20px'}>
          {totalShowing || hasTotalShowing ? (
            <Typography color='#999999' fontSize={'12px'}>
              Exibindo <span style={{ color: '#222' }}>{totalShowing}</span> de{' '}
              {pageNumber(total)} registros
            </Typography>
          ) : (
            <Typography color='#999999' fontSize={'12px'}>
              {`Exibindo ${pageNumber(page)} de ${pageNumber(totalPages)} ${
                totalPages === 1 ? 'página' : 'páginas'
              }`}
            </Typography>
          )}
        </Box>
      {totalPages > 1 ? (
        <Pagination
          count={totalPages}
          page={page}
          color='primary'
          onChange={handleChangePage}
          variant='outlined'
          shape='rounded'
          sx={{
            borderRadius: '8px',
            '& .Mui-selected': {
              backgroundColor: '#00B2FF !important',
            },
            '& .MuiPaginationItem-page': {
              border: '1px solid rgba(34, 34, 34, 0.08)',
              '&:hover': {
                backgroundColor: '#EFF9FF',
              },
            },

            '& .MuiPaginationItem-page': {
              border: '1px solid rgba(34, 34, 34, 0.08)',
              '&:hover': {
                backgroundColor: '#EFF9FF',
              },
            },
            '& .MuiPaginationItem-previousNext': {
              backgroundColor: 'transparent !important',
              border: '0px solid transparent',
              '&:hover': {
                backgroundColor: '#EFF9FF !important',
              },
            },
          }}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIos, next: ArrowForwardIos }}
              {...item}
              sx={{
                borderRadius: '8px',
                minWidth: '37px',
                width: 'max-content',
                height: '37px',
                '&.Mui-selected': {
                  backgroundColor: '#00B2FF ',
                  color: '#FFF',
                },
              }}
            />
          )}
          icon={{
            '&:hover': {
              backgroundColor: '#999',
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default TGPagination;
