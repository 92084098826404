const spacing = [
  '0px', // 0x
  '4px', // 1x
  '8px', // 2x
  '12px', // 3x
  '16px', // 4x
  '20px', // 5x
  '24px', // 6x
  '28px', // 7x
  '32px', // 8x
  '36px', // 9x
  '40px', // 10x
]

export default spacing
