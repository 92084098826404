export const InfoIcon = ({
  size = 24,
  color = '#222222',
  bgcolor = '#F5910D',
}) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4044_62755)'>
        <rect
          width='4.8'
          height='13.2'
          transform='translate(9.6001 6.5)'
          fill='#FAF6DC'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.0002 23.2992C6.05808 23.2992 1.2002 18.4413 1.2002 12.4992C1.2002 6.5571 6.05808 1.69922 12.0002 1.69922C17.9423 1.69922 22.8002 6.5571 22.8002 12.4992C22.8002 18.4413 17.9423 23.2992 12.0002 23.2992ZM12.9259 16.2021C12.9259 16.7133 12.5115 17.1278 12.0002 17.1278C11.4889 17.1278 11.0745 16.7133 11.0745 16.2021V11.2649C11.0745 10.7537 11.4889 10.3392 12.0002 10.3392C12.5115 10.3392 12.9259 10.7537 12.9259 11.2649V16.2021ZM10.7587 7.56208C10.7587 8.24375 11.3113 8.79636 11.993 8.79636H12.004C12.6857 8.79636 13.2383 8.24375 13.2383 7.56208C13.2383 6.8804 12.6857 6.32779 12.004 6.32779H11.993C11.3113 6.32779 10.7587 6.8804 10.7587 7.56208Z'
          fill={bgcolor}
        />
      </g>
      <defs>
        <clipPath id='clip0_4044_62755'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
