import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Search from '../../../components/form/Search'

import PieChart from '../../../components/charts/pie-chart/PieChart'
import ProgressCircle from '../../../components/charts/progress-circle/ProgressCircle'
import VerticalBars from '../../../components/charts/vertical-bars/VerticalBars'
import * as singleActions from '../../../actions/singleActions'
import TGLayoutProvisorio from 'TGComponents/layout/TGLayoutProvisorio'
import { Box } from '@mui/system'

import './dashboard.scss'
import { Skeleton, Typography } from '@mui/material'

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard - Ticket And Go'
  })

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.single.loading === 'dashboard')
  const dashboard = useSelector((state) => state.single.dashboard || {})
  const { user, isOperacional } = useSelector((state) => ({
    user: state.user,
    isOperacional: !!(state.user?.roles || []).find(
      (r) => r.slug === 'PRODUTOR-OPERACIONAL'
    ),
  }))

  const lists = useSelector((state) => state.lists)

  const getDashboard = (arg = '') => {
    dispatch(
      singleActions.getSingle(`/usuarios/${user.uuid}/dashboard`, {
        single: 'dashboard',
        arg,
      })
    )
  }

  const clearDashboard = () => {
    dispatch(singleActions.clearSingle('dashboard'))
    getDashboard()
  }

  useEffect(() => {
    getDashboard()

    return () => clearDashboard()
  }, [])

  function porcentagem(valor1) {
    return parseInt(valor1 / (dashboard?.general?.total / 100))
  }

  function porcentagem2(valor1, valor2) {
    return parseInt(
      dashboard?.general?.total / (dashboard?.ingressos?.quantidade / 100)
    )
  }

  return (
    <TGLayoutProvisorio
      pageTitle={'Painel'}
      page={
        <>
          <div className='row'>
            <div className='mb-3'>
              <div className='row'>
                <Search
                  fields={[
                    { name: 'evento', label: 'Evento/Produto', role: 'event' },
                    { name: 'inicio', label: 'Data início', role: 'date' },
                    { name: 'fim', label: 'Data fim', role: 'date' },
                  ]}
                  handleSearch={getDashboard}
                  handleClear={clearDashboard}
                  col={3}
                  loading={loading}
                />
              </div>
            </div>
            <Box className='row mb-3'>
              {!isOperacional ? (
                <div className='col-sm-5'>
                  <Box
                    paddingBlock='40px'
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    className='retangulo-cinza-arredondado'
                    style={{ minHeight: '220px' }}
                  >
                    <Box>
                      <Typography variant='title'>VENDAS BRUTAS</Typography>
                    </Box>
                    <Box mb='4px'>
                      <Typography
                        fontSize={{ md: '1rem', xl: '1.3rem' }}
                        color='#555'
                      >
                        {loading ? (
                          // Se estiver carregando, exiba o Skeleton no lugar do conteúdo
                          <Box display='flex' gap={1}>
                            <Skeleton width={80} height={25} animation='wave' />

                            <Skeleton
                              width={120}
                              height={28}
                              animation='wave'
                            />
                          </Box>
                        ) : (
                          // Se não estiver carregando, exiba o conteúdo normal
                          <>
                            {dashboard?.general?.total || 0} |{' '}
                            {parseFloat(
                              dashboard?.general?.venda_acumulada || 0
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </>
                        )}
                      </Typography>
                    </Box>

                    <Box mt={1}>
                      <Typography variant='title'>RECEITA LÍQUIDA</Typography>
                    </Box>

                    <Box>
                      <Typography
                        fontSize={{ md: '1.8rem', xl: '3rem' }}
                        fontWeight='800'
                        color='#00B2FF'
                        style={{ color: '#00B2FF' }}
                      >
                        {loading ? (
                          // Se estiver carregando, exiba o Skeleton no lugar do conteúdo
                          <Box display='flex' gap={1}>
                            <Skeleton
                              width={220}
                              height={45}
                              animation='wave'
                            />
                          </Box>
                        ) : (
                          // Se não estiver carregando, exiba o conteúdo normal
                          <>
                            {parseFloat(
                              dashboard?.general?.venda_liquida || 0
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              ) : null}
              <div className='col-sm-7'>
                <div
                  className='retangulo-cinza-arredondado'
                  style={{ minHeight: '220px' }}
                >
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='d-flex flex-row mb-3 mt-3'>
                        <div>
                          <i
                            className='bi bi-ticket-fill me-3 fs-2'
                            style={{ color: '#000000' }}
                          ></i>
                        </div>
                        <div>
                          <div className='row'>
                            <span className='texto-15-400-18-Abel'>
                              CAPACIDADE DO EVENTO
                            </span>
                          </div>
                          <div className='row'>
                            <Typography
                              fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                              fontWeight='800'
                              color='#555'
                            >
                              {loading ? (
                                <Skeleton
                                  height={25}
                                  width={30}
                                  animation='wave'
                                />
                              ) : (
                                <>{dashboard?.ingressos?.quantidade || 0}</>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-row mb-3'>
                        <div>
                          <i
                            className='bi bi-ticket me-3 fs-2'
                            style={{ color: '#000000' }}
                          ></i>
                        </div>
                        <div>
                          <div className='row'>
                            <span className='texto-15-400-18-Abel'>
                              INGRESSOS VENDIDOS
                            </span>
                          </div>

                          <Typography
                            fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                            fontWeight='800'
                            color='#555'
                          >
                            {loading ? (
                              <Skeleton
                                height={25}
                                width={30}
                                animation='wave'
                              />
                            ) : (
                              <>{dashboard?.general?.quantidade || '0'}</>
                            )}
                          </Typography>
                        </div>
                      </div>
                      {dashboard?.general?.eventos < 2 ? (
                        <div className='d-flex flex-row mb-3'>
                          <div>
                            <i
                              className='bi bi-ticket-fill me-3 fs-2'
                              style={{ color: '#000000' }}
                            ></i>
                          </div>
                          <div>
                            <div className='row'>
                              <span className='texto-15-400-18-Abel'>
                                INGRESSOS GRATUITOS
                              </span>
                            </div>

                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {dashboard?.ingressos?.gratuitos || '0'}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex flex-row'>
                          <div>
                            <i
                              className='bi bi-people  me-3 fs-2'
                              style={{ color: '#000000' }}
                            ></i>
                          </div>
                          <div>
                            <div className='row'>
                              <span className='texto-15-400-18-Abel'>
                                CLIENTES
                              </span>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {loading ? (
                                  <Skeleton
                                    height={25}
                                    width={30}
                                    animation='wave'
                                  />
                                ) : (
                                  <> {dashboard?.general?.clientes || '0'}</>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-sm-6'>
                      {((dashboard?.general?.eventos || 0) > 1 ||
                        (dashboard?.general?.eventos || 0) === 0) && (
                        <div className='d-flex flex-row mb-3 mt-3'>
                          <div>
                            <i
                              className='bi bi-calendar-check me-3 fs-2'
                              style={{ color: '#000000' }}
                            ></i>
                          </div>
                          <div>
                            <div className='row'>
                              <span className='texto-15-400-18-Abel'>
                                EVENTOS CRIADOS
                              </span>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {loading ? (
                                  <Skeleton
                                    height={25}
                                    width={30}
                                    animation='wave'
                                  />
                                ) : (
                                  <> {dashboard?.events?.quantidade_evento || 0}</>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className={
                          (dashboard?.general?.eventos || 0) > 1 ||
                          (dashboard?.general?.eventos || 0) === 0
                            ? 'd-flex flex-row mb-3'
                            : 'd-flex flex-row mb-3 mt-3'
                        }
                      >
                        <div>
                          <i
                            className='bi bi-pie-chart-fill me-3 fs-2'
                            style={{ color: '#000000' }}
                          ></i>
                        </div>
                        <div>
                          <div className='row'>
                            <span className='texto-15-400-18-Abel'>
                              LOTAÇÃO
                            </span>
                          </div>

                          <div className='row'>
                            <Typography
                              fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                              fontWeight='800'
                              color='#555'
                            >
                              {loading ? (
                                <Skeleton
                                  height={25}
                                  width={30}
                                  animation='wave'
                                />
                              ) : (
                                <>
                                  {dashboard?.general?.quantidade > 0
                                    ? parseFloat(dashboard?.lotacao).toLocaleString('pt-br', {
                                      style: 'percent',
                                      minimumFractionDigits: 2,
                                    })
                                  : 0
                                  }
                                  {
                                  //dashboard?.general?.quantidade > 0
                                  //  ? parseFloat(
                                  //      (dashboard?.general?.quantidade +
                                  //        (dashboard?.ingressos?.gratuitos ??
                                  //          0)) /
                                  //        dashboard?.ingressos?.quantidade
                                  //    ).toLocaleString('pt-br', {
                                  //      style: 'percent',
                                  //      minimumFractionDigits: 2,
                                  //    })
                                  //  : 0
                                  }
                                </>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-row mb-3'>
                        <div>
                          <i
                            className='bi bi-pie-chart-fill me-3 fs-2'
                            style={{ color: '#000000' }}
                          ></i>
                        </div>
                        <div>
                          <div className='row'>
                            <span className='texto-15-400-18-Abel'>
                              CHECK-IN
                            </span>
                          </div>
                          <div className='row'>
                            <Typography
                              fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                              fontWeight='800'
                              color='#555'
                            >
                              {loading ? (
                                <Skeleton
                                  height={25}
                                  width={30}
                                  animation='wave'
                                />
                              ) : (
                                <>
                                  {' '}
                                  {dashboard?.credenciamento?.total
                                    ? parseFloat(
                                        dashboard?.credenciamento?.total /
                                          (dashboard?.general?.quantidade +
                                            (dashboard?.ingressos?.gratuitos ??
                                              0))
                                      ).toLocaleString('pt-br', {
                                        style: 'percent',
                                        minimumFractionDigits: 2,
                                      })
                                    : 0}
                                </>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      {dashboard?.general?.eventos < 2 && (
                        <div className='d-flex flex-row'>
                          <div>
                            <i
                              className='bi bi-people  me-3 fs-2'
                              style={{ color: '#000000' }}
                            ></i>
                          </div>
                          <div>
                            <div className='row'>
                              <span className='texto-15-400-18-Abel'>
                                CLIENTES
                              </span>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {dashboard?.general?.clientes || '0'}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Box>

            <div className='row h-100 mb-3'>
              {!isOperacional ? (
                <div className='col-sm-5'>
                  <div className='retangulo-cinza-arredondado h-100'>
                    <div className='row mt-2'>
                      <Typography variant='title'>
                        FORMA DE PAGAMENTO
                      </Typography>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='d-flex flex-row h-50 pt-4'>
                          <div>
                            <PieChart
                              porcentagem={porcentagem(
                                parseInt(
                                  (dashboard?.tipos_pagamento || [])
                                    .filter(
                                      (t) =>
                                        t.nome_pagamento === 'CARTÃO DE CRÉDITO'
                                    )
                                    .reduce((acc, cur) => acc + cur.total, 0)
                                )
                              )}
                            />
                          </div>
                          <div>
                            <div className='row'>
                              <Typography
                                fontSize='0.8rem'
                                className='texto-15-400-18-Abel'
                              >
                                CRÉDITO
                              </Typography>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {loading ? (
                                  <Skeleton
                                    height={25}
                                    width={20}
                                    animation='wave'
                                  />
                                ) : (
                                  <>
                                    {(dashboard?.tipos_pagamento || [])
                                      .filter(
                                        (t) =>
                                          t.nome_pagamento ===
                                          'CARTÃO DE CRÉDITO'
                                      )
                                      .reduce((acc, cur) => acc + cur.total, 0)}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex flex-row h-50 pt-4'>
                          <div>
                            <PieChart
                              porcentagem={porcentagem(
                                parseInt(
                                  (dashboard?.tipos_pagamento || [])
                                    .filter(
                                      (t) =>
                                        t.nome_pagamento === 'DEBITO_ONLINE'
                                    )
                                    .reduce((acc, cur) => acc + cur.total, 0)
                                )
                              )}
                            />
                          </div>
                          <div>
                            <div className='row'>
                              <Typography
                                fontSize='0.8rem'
                                className='texto-15-400-18-Abel'
                              >
                                DÉBITO
                              </Typography>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {loading ? (
                                  <Skeleton
                                    height={25}
                                    width={20}
                                    animation='wave'
                                  />
                                ) : (
                                  <>
                                    {(dashboard?.tipos_pagamento || [])
                                      .filter(
                                        (t) =>
                                          t.nome_pagamento === 'DEBITO_ONLINE'
                                      )
                                      .reduce((acc, cur) => acc + cur.total, 0)}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='d-flex flex-row h-50 pt-4'>
                          <div>
                            <PieChart
                              porcentagem={porcentagem(
                                parseInt(
                                  (dashboard?.tipos_pagamento || [])
                                    .filter(
                                      (t) => t.nome_pagamento === 'BOLETO'
                                    )
                                    .reduce((acc, cur) => acc + cur.total, 0)
                                )
                              )}
                            />
                          </div>
                          <div>
                            <div className='row'>
                              <Typography fontSize='0.8rem'>BOLETO</Typography>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {loading ? (
                                  <Skeleton
                                    height={25}
                                    width={20}
                                    animation='wave'
                                  />
                                ) : (
                                  <>
                                    {(dashboard?.tipos_pagamento || [])
                                      .filter(
                                        (t) => t.nome_pagamento === 'BOLETO'
                                      )
                                      .reduce((acc, cur) => acc + cur.total, 0)}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex flex-row h-50 pt-4'>
                          <div>
                            <PieChart
                              porcentagem={porcentagem(
                                parseInt(
                                  (dashboard?.tipos_pagamento || [])
                                    .filter((t) => t.nome_pagamento === 'PIX')
                                    .reduce((acc, cur) => acc + cur.total, 0)
                                )
                              )}
                            />
                          </div>
                          <div>
                            <div className='row'>
                              <Typography fontSize='0.8rem'>PIX</Typography>
                            </div>
                            <div className='row'>
                              <Typography
                                fontSize={{ md: '1.3rem', xl: '1.5rem' }}
                                fontWeight='800'
                                color='#555'
                              >
                                {loading ? (
                                  <Skeleton
                                    height={25}
                                    width={20}
                                    animation='wave'
                                  />
                                ) : (
                                  <>
                                    {(dashboard?.tipos_pagamento || [])
                                      .filter((t) => t.nome_pagamento === 'PIX')
                                      .reduce((acc, cur) => acc + cur.total, 0)}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className='col-sm-7'>
                <div className='retangulo-cinza-arredondado h-100'>
                  <div className='row'>
                    <Typography variant='title' mt='6px'>
                      EVOLUÇÃO DAS VENDAS (30 DIAS)
                    </Typography>
                  </div>

                  {loading ? (
                    <div className='row text-center pt-5'>
                      <Skeleton width={'80%'} height={80} animation='wave' />
                    </div>
                  ) : (
                    <>
                      {(dashboard?.chart || []).length > 0 ? (
                        <div className='row pt-1'>
                          <VerticalBars
                            title={
                              '' /*(dashboard?.chart || []).length > 30 ? 'EVOLUÇÃO DAS VENDAS (30 DIAS)' : 'EVOLUÇÃO DAS VENDAS'*/
                            }
                            columns={(dashboard?.chart || [])
                              .slice(-30)
                              .map((c) =>
                                c.data
                                  .replace(/[0-9]{4}\-/, '')
                                  .replace(/([0-9]{2})\-([0-9]{2})/, '$2/$1')
                              )}
                            data={(dashboard?.chart || [])
                              .slice(-30)
                              .map((c) => c.total)}
                          />
                        </div>
                      ) : (
                        <div className='row text-center pt-5'>
                          <Skeleton
                            width={'80%'}
                            height={80}
                            animation='wave'
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='row h-100 mb-3'>
              <div className='col-sm-5 d-none'>
                <div className='card h-100' style={{ borderRadius: '1em' }}>
                  <div className='card-body m-2'>
                    <h3>Formas de pagamento</h3>
                    <div className='row mt-2 mb-3'>
                      <div className='col-12'>
                        <div className='row mb-3'></div>
                      </div>
                      <div className='col-6'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    />
  )
}

export default Dashboard
