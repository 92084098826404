import React, { useState } from 'react';
import { Stack } from '@mui/material';
import PaymentCard from './PaymentCard';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import ModalStatus from './ModalStatus';
import QrcodePix from './QrcodePix';
import { Button } from 'TGComponents/global';
import appConfig from 'config';
import { useNavigate } from 'react-router-dom';

const UpsellTemplate = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const qtym = searchParams.get('qtym');
  const idTransacao = searchParams?.get('idTransacao');
  const uuid_evento = searchParams?.get('uuid_evento');
  const uuid_bilhete = searchParams?.get('uuid_bilhete');
  const tipo_pagamento = searchParams?.get('tipo_pagamento');
  const urlCallback = searchParams?.get('urlCallback');

  const initialValues = { quantity: qtym ?? 0 };

  const [loadingPost, setLoadingPost] = useState(false);
  const [dataPost, setDataPost] = useState({});
  const [errorPost, setErrorPost] = useState(null);

  // Função pra criar transação s
  const handlePay = async (values) => {
    let valorTotal = null;
    let qtdParcelas = null;

    //separa valor total e quantidade de parcelas
    if (values?.qtdParcelasValorParcela) {
      [valorTotal, qtdParcelas] = values.qtdParcelasValorParcela.split(':');
    }

    const payload = {
      uuid_bilhete: uuid_bilhete,
      uuid_evento: uuid_evento,
      quantidade: values.quantity,
      id_checkout: values.id_checkout,
      id_transacao: idTransacao,
      parcelas: qtdParcelas || '',
      //Aqui envia valorTotal quando é cartão de credito e quando não é envia o values.valorTotalpix
      valor: valorTotal || values.valorTotalpix,
      forma_pagamento: tipo_pagamento,
    };

    try {
      setLoadingPost(true);
      const response = await fetch(
        `${appConfig[appConfig.env].api}/upsell/finalizaPagamento`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      setDataPost(data);

      if (!response.ok) {
        throw new Error(data?.message);
      }
    } catch (error) {
      setErrorPost(error.message);
    } finally {
      setLoadingPost(false);
    }
  };

  //Função pra renderizar os modais de status
  const renderModalStatus = (status, title, message, buttons) => (
    <ModalStatus
      status={status}
      title={title}
      message={message}
      buttons={buttons}
    />
  );

  //função para escolher o conteudo que ira renderizarno modal de pagamento
  const renderContent = (values) => {
    if (
      !idTransacao ||
      !uuid_evento ||
      uuid_evento === 'null' ||
      idTransacao === 'null'
    ) {
      return renderModalStatus(
        'warning',
        'Ops! Para carregar este formulário, é necessário que tenha uma compra em andamento.'
      );
    }
    if (values?.isExpired) {
      return renderModalStatus(
        'warning',
        'Ops! Parece que sua oferta escapou.',
        'O tempo para a compra expirou. Isso garante que a reserva não fique presa, permitindo que esteja disponível para compra novamente.',
        <>
          <Button
            buttonBoxStyle={{
              width: '100%',
            }}
            sx={{
              width: '100%',
            }}
            variant='primary'
            type='submit'
            loading={loadingPost}
          >
            Resgatar oferta
          </Button>
        </>
      );
    }

    if (dataPost?.status === 201 || values?.successPix) {
      return renderModalStatus(
        'success',
        'Pronto, seu pagamento foi confirmado!',
        'Seu pagamento foi processado com sucesso.',
        <>
          {urlCallback && (
            <Button
              buttonBoxStyle={{
                width: '100%',
              }}
              sx={{
                width: '100%',
              }}
              variant='primary'
              onClick={() => (window.top.location.href = urlCallback)}
            >
              Ok, entendi
            </Button>
          )}
        </>
      );
    }
    if (dataPost?.statusPagamento === 'PIX') {
      return (
        <QrcodePix
          retorno={dataPost?.retorno}
          checkoutId={dataPost?.checkoutId}
        />
      );
    }

    if (errorPost) {
      return renderModalStatus(
        'error',
        'Oops, alguma coisa deu errado.',
        errorPost
      );
    }

    if (values.boLiberaEventoCompra === 0 || values.boVoltar === 1) {
      return renderModalStatus(
        'error',
        'Ops! Não foi possível gerar um novo pagamento.',
        'Provavelmente as ofertas disponíveis já se esgotaram.'
      );
    }
    return <PaymentCard loadingPost={loadingPost} />;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handlePay}
    >
      {({ values }) => (
        <Form style={{ width: '100%' }}>
          <Stack
            justifyContent='center'
            alignItems='center'
            width='100%'
            height='100vh'
          >
            {renderContent(values)}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default UpsellTemplate;
