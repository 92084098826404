export default function TGIcon({
  src,
  height = '32px',
  width = '32px',
  alt = 'defaultAlt',
  onClick,
  style,
}) {
  return (
    <img
      style={style}
      src={src}
      height={height}
      width={width}
      alt={alt}
      onClick={onClick}
    />
  );
}
