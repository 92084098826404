const BoxIconCustom = ({ width = 20, height = 20, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1506 1.49006L16.7198 3.94132C17.3864 4.29749 17.9478 4.8922 18.3401 5.53386C18.7324 6.17562 19 6.93663 19 7.6708V12.3354C19 13.0696 18.7324 13.8306 18.3401 14.4723C17.9478 15.114 17.3869 15.7085 16.7203 16.0646L12.1486 18.5172C11.5179 18.8534 10.7369 19 10 19C9.26306 19 8.48214 18.8534 7.85136 18.5172L3.28019 16.0649C2.61374 15.7087 2.05209 15.1139 1.6599 14.4723C1.26758 13.8306 1 13.0696 1 12.3354V7.6708C1 6.93663 1.26758 6.17563 1.6599 5.53386C2.05211 4.89227 2.61296 4.29791 3.27943 3.94172L7.84685 1.4914L7.84825 1.49065C8.47943 1.14926 9.25913 1 9.99679 1C10.7353 1 11.5164 1.14967 12.1506 1.49006ZM9.99679 2.48607C9.44897 2.48607 8.94539 2.60077 8.60105 2.78723L8.59838 2.78868L7.50807 3.37361L14.3291 7.1729L16.6966 5.85013C16.4706 5.58351 16.2195 5.37276 15.9735 5.24139L11.4016 2.78867C11.0515 2.60075 10.5443 2.48607 9.99679 2.48607ZM17.394 7.17789L15.0456 8.48994V11.0226C15.0456 11.433 14.7008 11.7656 14.2753 11.7656C13.8499 11.7656 13.505 11.433 13.505 11.0226V9.35074L10.7661 10.881V17.437C11.0098 17.3856 11.2272 17.3104 11.4027 17.2169L15.9722 14.7655L15.9735 14.7648C16.3423 14.5678 16.7227 14.1923 17.013 13.7175C17.3032 13.2427 17.4593 12.7405 17.4593 12.3354V7.6708C17.4593 7.51806 17.4372 7.35154 17.394 7.17789ZM9.22539 10.8813L2.60421 7.18529C2.56222 7.35627 2.54066 7.52024 2.54066 7.6708V12.3354C2.54066 12.7405 2.69675 13.2427 2.98703 13.7175C3.27726 14.1923 3.65764 14.5678 4.0265 14.7648L4.02777 14.7655L8.59655 17.2165C8.77007 17.3089 8.98475 17.3836 9.22539 17.435V10.8813ZM3.2985 5.85588L9.99554 9.59418L12.7911 8.03225L5.93872 4.21553L4.0265 5.24139C3.77877 5.37371 3.52583 5.58653 3.2985 5.85588Z"
        fill={color}
      />
    </svg>
  );
};

export default BoxIconCustom;
