export const PlusCircle = ({ size = 24, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
    >
      <g clip-path="url(#clip0_4031_18230)">
        <path
          d="M8.65 16.75C8.65 16.0873 9.18726 15.55 9.85 15.55H15.05V10.35C15.05 9.68726 15.5873 9.15 16.25 9.15C16.9127 9.15 17.45 9.68726 17.45 10.35V15.55H22.65C23.3127 15.55 23.85 16.0873 23.85 16.75C23.85 17.4127 23.3127 17.95 22.65 17.95H17.45V23.15C17.45 23.8127 16.9127 24.35 16.25 24.35C15.5873 24.35 15.05 23.8127 15.05 23.15V17.95H9.85C9.18726 17.95 8.65 17.4127 8.65 16.75Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 16.75C2.25 9.04726 8.54726 2.75 16.25 2.75C23.9527 2.75 30.25 9.04726 30.25 16.75C30.25 24.4527 23.9527 30.75 16.25 30.75C8.54726 30.75 2.25 24.4527 2.25 16.75ZM16.25 5.15C9.87274 5.15 4.65 10.3727 4.65 16.75C4.65 23.1273 9.87274 28.35 16.25 28.35C22.6273 28.35 27.85 23.1273 27.85 16.75C27.85 10.3727 22.6273 5.15 16.25 5.15Z"
          fill={color ?? "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4031_18230">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.25 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
