import notificationActionTypes from '../actionTypes/notifications'


const initialState = {
    type: null,
    message: null,
    show: false,
    redirectTo: null
}

const notifications = (state = initialState, action) => {
    switch (action.type) {

        case notificationActionTypes.SUCCESS: {

            const { message, redirectTo } = action.payload
            return {
                ...state,
                message,
                type: 'success',
                show: true,
                redirectTo
            }
        }

        case notificationActionTypes.WARNING: {

            const { message, redirectTo } = action.payload
            return {
                ...state,
                message,
                type: 'warning',
                show: true,
                redirectTo
            }
        }

        case notificationActionTypes.INFO: {

            const { message, redirectTo  } = action.payload
            return {
                ...state,
                message,
                type: 'info',
                show: true,
                redirectTo
            }
        }

        case notificationActionTypes.ERROR: {

            const { message, redirectTo } = action.payload
            return {
                ...state,
                message,
                type: 'error',
                show: true,
                redirectTo
            }
        }

        case notificationActionTypes.CLEAR_REDIRECT: {
            return {
                ...state,
                redirectTo: null, 
                show: false
            }
        }

        case notificationActionTypes.CLEAR: {
            return {
                ...initialState
            }
        }

        default:
            return state
    }
}

export default notifications