import { useNavigate, Link, NavLink } from 'react-router-dom'
import React, { useState } from 'react'
// window.Pusher = require('pusher-js');
import TGButton from 'TGComponents/global/TGButton'

const menuConfiguracoes = ({ setActive, ...props }) => {
  return (
    <>
      <div className="row mt-0">
        <ul className="menu-configuracoes">
          <li>      
            <NavLink to="/admin/producer/add">
              <TGButton               
                class="radius-2 producer-btn-1"
                style={                 
                  props.active === 'producer/add'
                    ? { background: '#00b2FF', color: '#FFFFFF' }
                    : { background: 'C7CBD2', color: '#FFFFFF'  }
                    
                }
                onClick={() => setActive('producer/add')}
              >
                CADASTRO
              </TGButton>
            </NavLink>
          </li>
          {/* <li>
                <NavLink to="/admin/operator/index">
                    <button style={props.active == 'operator/index' ? {background: '#253A67', color: '#FFFFFF'} : {background: 'C7CBD2', color: '#FFFFFF'}} onClick={() => setActive('operator/index')}>
                        EQUIPE
                    </button>
                </NavLink>
            </li> */}
          <li>
            <NavLink to="/admin/databank/add">
              <TGButton
              
                class="radius-2 producer-btn"
                style={
                  props.active === 'databank/add'
                    ? { background: '#00b2FF', color: '#FFFFFF' }
                    : { background: 'C7CBD2', color: '#FFFFFF' }
                }
                onClick={() => setActive('databank/add')}
              >
                DADOS BANCÁRIOS
              </TGButton>
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/admin/databank/rates">
              <button
                style={
                  props.active == 'databank/rates'
                    ? { background: '#253A67', color: '#FFFFFF' }
                    : { background: 'C7CBD2', color: '#FFFFFF' }
                }
                onClick={() => setActive('databank/rates')}
              >
                TAXAS
              </button>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default menuConfiguracoes
