import React, { Suspense, lazy, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { hasPermissionSelector } from '../services/selectors'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import * as notificationActions from '../actions/notificationActions'

import NotFound from './admin/NotFound'

const LazyWrapper = () => {
  const location = useLocation()

  const complete = useSelector((state) => state.user.complete || false)

  const dispatch = useDispatch()

  const requireComplete = () =>
    dispatch(
      notificationActions.info(
        'Para iniciar você precisa completar seu cadastro.'
      )
    )

  const lazyComp = useMemo(() => {
    const loc = location.pathname
      .toLowerCase()
      .replace('/admin/', '')
      .split('/')

    let context = ''
    let comp = ''
    let action = ''

    if (loc.length == 1) {
      context = loc[0].toLowerCase()
      comp = loc[0][0].toUpperCase() + loc[0].slice(1)
    }

    if (loc.length > 1) {
      context = loc[0].toLowerCase()
      comp = loc[0][0].toUpperCase() + loc[0].slice(1)

      if (loc[1] !== '') action = loc[1][0].toUpperCase() + loc[1].slice(1)

      if (action === 'Index') action = ''

      comp = comp + action
    }

    //if(!complete){
    //    requireComplete()
    //    context = 'producer'
    //    comp = 'ProducerAdd'
    //}

    try {
      const Dc = lazy(() =>
        context + comp !== 'homeHome'
          ? import('./admin/' + context + '/' + comp).catch(() => ({
              default: () => <NotFound />,
            }))
          : import('./admin/' + comp).catch(() => ({
              default: () => <NotFound />,
            }))
      )

      return (
        <Suspense
          fallback={
            <Backdrop
              open={true}
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color='primary' />
            </Backdrop>
          }
        >
          <Dc />
        </Suspense>
      )
    } catch (e) {
      return <h1>Nof</h1>
    }
  })

  return lazyComp
}

export default LazyWrapper
