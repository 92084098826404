import React, { useState } from 'react';
import { Box, Typography, Skeleton, TextareaAutosize } from '@mui/material';
import warningIcon from 'assets/svgs/global/warningIcon.svg';
import CircularProgress from '@mui/material/CircularProgress';

const TGTextArea = ({
  name,
  title,
  text = '',
  minHeight = '63px',
  textHelper,
  maxLength = 200,
  placeholder,
  onChange,
  onBlur,
  value,
  defaultValue,
  required = false,
  warning = false,
  loading = false,
  errorMessage,
  disabled,
  resize,
  maxLengthVisible = true
}) => {
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      <Typography
        variant='label-s'
        color={disabled ? 'rgba(34, 34, 34, 0.24)' : 'neutral.30'}
      >
        {title}
      </Typography>
      <Box position={'relative'} width={'100%'}>
        {loading ? (
          <Skeleton height='100px' />
        ) : (
          <TextareaAutosize
            name={name}
            style={{
              border: `1px solid ${warning ? '#FF5858' : '#EEEEEE'} `,
              borderRadius: '8px',
              padding: '14px 12px 14px 12px',
              minHeight: minHeight,
              width: '100%',
              resize,
            }}
            placeholder={placeholder}
            minRows={1}
            maxLength={maxLength}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultValue={defaultValue}
            required={required}
            disabled={disabled}
          />
        )}

        {warning ? (
          <img
            src={warningIcon}
            height='16px'
            width={'16px'}
            alt='warningIconTextArea'
            style={{ position: 'absolute', top: '10px', right: '10px' }}
          />
        ) : null}
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection='column' gap='4px' width={'80%'}>
          {textHelper && (
            <Typography
              color='#999999'
              lineHeight={'15px'}
              fontSize={'10px'}
              fontWeight={'400'}
            >
              {textHelper}
            </Typography>
          )}
          {errorMessage && (
            <Typography
              color='#FF5858'
              lineHeight={'15px'}
              fontSize={'10px'}
              fontWeight={'400'}
              width={'80%'}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
        
        {maxLengthVisible && (
        <Typography
          ml='auto'
          color='#999999'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          <Typography
            display={'inline'}
            color='#555555'
            lineHeight={'15px'}
            fontSize={'10px'}
            fontWeight={'400'}
          >
            {value ? value?.length : 0}
          </Typography>
          / {maxLength}
        </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TGTextArea;
