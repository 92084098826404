const actionTypes = {
  LOGIN: 'USER_SAGA/LOGIN',
  RECOVER: 'USER_SAGA/RECOVER',
  RECUPERAR: 'USER_SAGA/RECUPERAR',
  APPLY_USER: 'USER/APPLY_USER',
  SET_LOADING: 'USER/SET_LOADING',
  LOGOFF: 'USER/LOGOFF',
}

export default actionTypes
