import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${pallete.base.blackAlpha};
  font-size: 0.875rem;
  padding: 1.25rem 0;
  width: 100%;
  text-align: left;

  & + & {
    border-top: 1px solid #22222214;
  }
`;
