import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Select from './Select'
import * as listsActions from '../../actions/listsActions'

const ProvedorSelect = ({
  label = 'Provedor',
  name,
  errors = {},
  ...props
}) => {
  const items = useSelector((state) => state.lists.integrations || [])
  const user = useSelector((state) => state.user)
  const options = []

  const dispatch = useDispatch()

  const getItems = () =>
    dispatch(
      listsActions.getLists(`/integracao/${user.uuid}/integracoes`, {
        list: 'integrations',
        arg: '',
      })
    )

  useEffect(() => {
    getItems()
  }, [])

  Object.keys(items).forEach(item => {
    if(items[item].ativo && item != 'MemberKit' && item != 'eNotas'){
      options.push( { label: item, id: items[item].id })

    }
  })

  return (
    <Select errors={errors} label={label} name={name} {...props}>
      <option value=''>-- Selecione --</option>
      {options.map((item) => (
        <option key={item.id} value={item.id}>
          {item.label}
        </option>
      ))}
    </Select>
  )
}

export default ProvedorSelect
