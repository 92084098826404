export const ArrowDown = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.49017 8.7304C5.84164 8.37893 6.41149 8.37893 6.76296 8.7304L11.5992 13.5666C11.8188 13.7863 12.1836 13.7863 12.4033 13.5666L17.2395 8.7304C17.5909 8.37893 18.1608 8.37893 18.5122 8.7304C18.8637 9.08187 18.8637 9.65172 18.5122 10.0032L13.6761 14.8394C12.7534 15.762 11.249 15.762 10.3264 14.8394L5.49016 10.0032C5.13869 9.65172 5.1387 9.08187 5.49017 8.7304Z'
        fill={color}
        fill-opacity='0.56'
      />
    </svg>
  );
};
