import { Box, Button, Typography, Skeleton, Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import { IOSSwitch } from './Inputs';
import { TGTooltip } from 'TGComponents/layout/TGTobBar/stylesMobile';

export default function TGAccordion({
  children,
  icon,
  title,
  titleColor = 'rgba(34, 34, 34, 0.56)',
  placeholder,
  placeholderColor = '#222222',
  helpText,
  padding = '16px',
  gap = '16px',
  border = '1px solid #EEEEEE',
  flexDirection = 'column',
  justifyContent = 'space-between',
  isOpen = false,
  noIcon = false,
  onChange,
  onSwitchChange,
  loading,
  disabled = false,
  inputBgColor = '#F7F9FA',
  hasSwitch = true,
  svgIcon: SvgIcon,
  widthSvg,
  heightSvg,
  colorSvg,
  alwaysActive = false,
  tooltip,
  tooltipTitle,
  contentTooltip,
}) {
  const [show, setShow] = useState(isOpen);
  const handleChange = (event, checked) => {
    if (alwaysActive) {
      setShow(true);
      return;
    }
    setShow(!show);
    onChange?.(show, setShow);
    onSwitchChange(!show);
  };

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);
  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        gap={'4px'}
        flexDirection={'column'}
        sx={{ cursor: disabled ? 'not-allowed' : 'initial' }}
      >
        {title && (
          <Typography color={titleColor} variant='body-m'>
            {title}
          </Typography>
        )}
        {loading ? (
          <Skeleton height='50px' />
        ) : (
          <Box>
            <Box
              component={Button}
              onClick={handleChange}
              disabled={disabled}
              borderRadius={show ? '8px 8px 0px 0px' : '8px'}
              border='1px solid #EEEEEE'
              width={'100%'}
              display={'flex'}
              alignContent={'center'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={'12px 16px 12px 16px'}
              bgcolor={inputBgColor}
              gap='12px'
              sx={{
                border: '1px solid #EEEEEE',
                borderBottom: show ? 'none' : '1px solid #EEEEEE',
              }}
            >
              <Box display={'flex'} gap={'12px'} alignItems={'center'}>
                {!noIcon && (
                  <>
                    {SvgIcon ? (
                      <SvgIcon
                        width={widthSvg}
                        height={heightSvg}
                        color={colorSvg}
                      />
                    ) : (
                      <>
                        <img
                          src={icon}
                          alt='dragimg'
                          height={'20px'}
                          width={'20px'}
                        />
                      </>
                    )}
                  </>
                )}

                <Typography
                  variant='label-m'
                  textAlign={'start'}
                  color={placeholderColor}
                  fontWeight={'500'}
                  textTransform={'initial'}
                >
                  {placeholder}
                </Typography>
                {tooltip && (
                  <TGTooltip title={contentTooltip}>{tooltipTitle} </TGTooltip>
                )}
              </Box>
              {hasSwitch && (
                <IOSSwitch
                  onChange={handleChange}
                  checked={show}
                  disableRipple={disabled}
                />
              )}
            </Box>
            <Collapse in={show} timeout='auto' unmountOnExit>
              <Box
                borderRadius={'0px 0px 8px 8px'}
                border={border}
                bgcolor='#ffffff'
                width={'100%'}
                display={'flex'}
                flexDirection={flexDirection}
                justifyContent={justifyContent}
                padding={padding}
                gap={gap}
              >
                {children}
              </Box>
            </Collapse>
          </Box>
        )}

        {helpText && (
          <Typography
            color='#999999'
            lineHeight={'15px'}
            fontSize={'10px'}
            fontWeight={'400'}
          >
            {helpText}
          </Typography>
        )}
      </Box>
    </>
  );
}
