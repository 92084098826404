import { Box, Skeleton, Typography } from '@mui/material';
import { FileUpload } from 'TGComponents/global/Inputs';

export default function TGUploadSingle({
  title,
  textHelper,
  onFileUpload,
  onFileRemove,
  file,
  type = 'image',
  iconCustom,
  disabled = false,
  errorMessage,
  loading,
  border,
  height
}) {
  const handleFileUpload = (file) => {
    onFileUpload(file);
  };

  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      <Typography variant={'label-s'} color='#2222228F'>
        {title}
      </Typography>
      {loading ? (
        <Skeleton height='150px' />
      ) : (
        <>
          <FileUpload
            type={type}
            onFileUpload={handleFileUpload}
            file={file}
            iconCustom={iconCustom}
            disabled={disabled}
            errorMessage={errorMessage}
            onFileRemove={onFileRemove}
            border={border}
            height={height}
          />
          {errorMessage && (
            <Typography
              color='#ff5858'
              lineHeight={'15px'}
              fontSize={'10px'}
              fontWeight={'400'}
            >
              {errorMessage}
            </Typography>
          )}
        </>
      )}

      {textHelper && (
        <Typography variant='body-s' color='neutral.40' lineHeight={'15px'}>
          {textHelper}
        </Typography>
      )}
    </Box>
  );
}
