import styled, { css } from "styled-components";
import { radius } from "TGComponents/theme/radius";

const wrapperModifiers = {
  primary: () => css`
    background-color: rgba(239, 249, 255, 1);
    color: rgba(0, 178, 255, 1);
  `,
  success: () => css`
    background-color: rgba(243, 250, 244, 1);
    color: rgba(83, 164, 98, 1);
  `,
  danger: () => css`
    background-color: #faf0f2;
    color: #d72b2b;
  `,
  secondary: () => css`
    background-color: #fafafa;
    color: #2222228f;
  `,
};

export const Container = styled.div`
  ${({ type }) => css`
    border-radius: ${radius.xs};
    padding: 0.25rem 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    ${wrapperModifiers[type || "primary"]()}
  `}
`;
