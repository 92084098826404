export const Calendar = ({ size = 24, color = "#00B2FF" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_124_48530)">
        <g clip-path="url(#clip1_124_48530)">
          <path
            d="M11.9953 13.3407C11.3326 13.3407 10.7953 13.878 10.7953 14.5407C10.7953 15.2035 11.3326 15.7407 11.9953 15.7407H12.0061C12.6689 15.7407 13.2061 15.2035 13.2061 14.5407C13.2061 13.878 12.6689 13.3407 12.0061 13.3407H11.9953Z"
            fill={color ?? "currentColor"}
          />
          <path
            d="M6.35391 14.5407C6.35391 13.878 6.89116 13.3407 7.55391 13.3407H7.56468C8.22743 13.3407 8.76468 13.878 8.76468 14.5407C8.76468 15.2035 8.22743 15.7407 7.56468 15.7407H7.55391C6.89116 15.7407 6.35391 15.2035 6.35391 14.5407Z"
            fill={color ?? "currentColor"}
          />
          <path
            d="M7.55391 16.9407C6.89116 16.9407 6.35391 17.478 6.35391 18.1407C6.35391 18.8035 6.89116 19.3407 7.55391 19.3407H7.56468C8.22743 19.3407 8.76468 18.8035 8.76468 18.1407C8.76468 17.478 8.22743 16.9407 7.56468 16.9407H7.55391Z"
            fill={color ?? "currentColor"}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.2 0.800781C7.69706 0.800781 8.1 1.20372 8.1 1.70078V2.00078H15.9V1.70078C15.9 1.20372 16.3029 0.800781 16.8 0.800781C17.2971 0.800781 17.7 1.20372 17.7 1.70078V2.11197C19.0748 2.33809 20.188 2.90834 21.0029 3.81559C22.0692 5.00268 22.5 6.62918 22.5 8.41429V17.7873C22.5 19.5724 22.0692 21.1989 21.0029 22.386C19.9211 23.5903 18.3138 24.2008 16.2667 24.2008H7.73333C5.68616 24.2008 4.07885 23.5903 2.99711 22.386C1.93083 21.1989 1.5 19.5724 1.5 17.7873V8.41429C1.5 6.62918 1.93083 5.00268 2.99711 3.81559C3.81202 2.90834 4.9252 2.33809 6.3 2.11197V1.70078C6.3 1.20372 6.70294 0.800781 7.2 0.800781ZM6.3 5.30078V3.9435C5.41094 4.13967 4.7793 4.52512 4.33622 5.01841C3.73102 5.69219 3.37102 6.68504 3.30946 8.00078H20.6905C20.629 6.68504 20.269 5.69219 19.6638 5.01841C19.2207 4.52512 18.5891 4.13967 17.7 3.9435V5.30078C17.7 5.79784 17.2971 6.20078 16.8 6.20078C16.3029 6.20078 15.9 5.79784 15.9 5.30078V3.80078H8.1V5.30078C8.1 5.79784 7.69706 6.20078 7.2 6.20078C6.70294 6.20078 6.3 5.79784 6.3 5.30078ZM3.3 17.7873V9.80078H20.7V17.7873C20.7 19.3103 20.3308 20.4405 19.6638 21.1832C19.0122 21.9086 17.9528 22.4008 16.2667 22.4008H7.73333C6.04717 22.4008 4.98781 21.9086 4.33622 21.1832C3.66917 20.4405 3.3 19.3103 3.3 17.7873Z"
            fill={color ?? "currentColor"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_124_48530">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
        <clipPath id="clip1_124_48530">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
