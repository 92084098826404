export const CheckedIcon = ({ size = 24, color = '#fafafa' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.225 4.37631C13.4591 4.61083 13.4588 4.99073 13.2243 5.22484L7.35416 11.0846C7.11979 11.3185 6.74018 11.3184 6.50601 11.0842L3.57613 8.1543C3.34181 7.91999 3.34181 7.54009 3.57613 7.30577C3.81044 7.07146 4.19034 7.07146 4.42465 7.30577L6.93065 9.81177L12.3765 4.37556C12.611 4.14145 12.9909 4.14179 13.225 4.37631Z'
        fill={color}
      />
    </svg>
  )
}
