export const Location = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_124_48538)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0013 6.41543C9.82551 6.41543 8.06172 8.17922 8.06172 10.355C8.06172 12.5307 9.82551 14.2945 12.0013 14.2945C14.177 14.2945 15.9408 12.5307 15.9408 10.355C15.9408 8.17922 14.177 6.41543 12.0013 6.41543ZM9.86172 10.355C9.86172 9.17333 10.8196 8.21543 12.0013 8.21543C13.1829 8.21543 14.1408 9.17333 14.1408 10.355C14.1408 11.5366 13.1829 12.4945 12.0013 12.4945C10.8196 12.4945 9.86172 11.5366 9.86172 10.355Z"
          fill="#00B2FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.005 1.35938C15.9466 1.36204 19.978 3.68977 21.0425 8.39308C22.2741 13.8336 18.8763 18.3342 16.1322 20.9695C13.8244 23.1962 10.1756 23.1938 7.86047 20.9704L7.85933 20.9693C5.1252 18.3342 1.72752 13.8237 2.9591 8.38329C4.02901 3.68003 8.06342 1.35671 12.005 1.35938ZM4.71458 8.78116C5.56403 5.04807 8.73886 3.15717 12.0038 3.15938C15.269 3.16158 18.4419 5.05706 19.2869 8.79042C20.2958 13.2472 17.5375 17.1245 14.8846 19.6721L14.8829 19.6737C13.2746 21.226 10.7286 21.2284 9.10809 19.6729C6.46477 17.1252 3.70581 13.2384 4.71458 8.78116Z"
          fill="#00B2FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_124_48538">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
