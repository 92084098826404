import TGIcon from 'TGComponents/global/TGIcon';
import mastercard from 'assets/paymentsIcons/mastercard.svg';
import visa from 'assets/paymentsIcons/visa.svg';
import hipercard from 'assets/paymentsIcons/hipercard.svg';
import boleto from 'assets/paymentsIcons/boleto.svg';
import pix from 'assets/paymentsIcons/pix.svg';
import maquiCard from 'assets/paymentsIcons/maquiCard.svg';
import elo from 'assets/paymentsIcons/elo.svg';
import visacard from 'assets/paymentsIcons/visacard.svg';
import discover from 'assets/paymentsIcons/discover.png';
import { comunicacao } from 'assets/svgs';

export default function TGPaymentType({
  type,
  id,
  width = '32px',
  height = '32px',
}) {
  const STATUS_STYLES = {
    //BOLETO
    boleto: {
      icon: boleto,
    },

    desconhecido: {
      icon: comunicacao,
    },

    discover: {
      icon: discover,
    },

    //BOLETO externo
    14: {
      icon: boleto,
    },
    // PIX
    pix: {
      icon: pix,
    },
    pix: {
      icon: pix,
    },
    // PIX externo
    26: {
      icon: pix,
    },
    //cartão de credito

    1: {
      icon: mastercard,
    },
    elo: {
      icon: elo,
    },
    master: {
      icon: mastercard,
    },
    visa: {
      icon: visa,
    },
    hipercard: {
      icon: hipercard,
    },
    //cartão de credito externo
    master: {
      icon: mastercard,
    },
    //Máquina de cartão
    29: {
      icon: maquiCard,
    },
  };
  const DEFAULT_ID_STYLE = { title: '', bgcolor: '#EFF7FA', color: '#00B2FF' };

  const { icon } = type
    ? STATUS_STYLES[type] || STATUS_STYLES['desconhecido']
    : { ...DEFAULT_ID_STYLE, title: `${id}` };

  return (
    <>
      <TGIcon src={icon} height={height} width={width} />
    </>
  );
}
