import addressActionTypes from "../actionTypes/address"
const initialState = {
    data: {}, 
    loading: false
}

const address = (state = initialState, action) => {
    switch (action.type) {

        case addressActionTypes.SET_ADDRESS: {            

            const { address } = action.payload

            return {
                ...state,
                data: address,
            }
        }

        case addressActionTypes.SET_LOADING: {            

            const { flag } = action.payload

            return {
                ...state,
                loading: flag,
            }
        }

        case addressActionTypes.CLEAR: {            

            return {...initialState}
        }

        default:
            return state
    }
}

export default address