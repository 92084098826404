import { Box, Typography, Skeleton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as React from 'react';
import warningIcon from 'assets/svgs/global/warningIcon.svg';
import { ArrowDownIcon } from 'assets/svgs';
import { Calendar } from 'TGComponents/icons';
import { ArrowRightOutline } from 'TGComponents/icons/ArrowRightOutline';
import palette from 'TGComponents/theme/pallete';
import TGIcon from './TGIcon';
import { ArrowDown } from 'TGComponents/icons/ArrowDown';

export default function TGSelectInput({
  title,
  textHelper,
  defaultValue,
  value,
  values,
  setValue,
  buttonStyle,
  placeholder,
  onChange,
  warning,
  errorMessage,
  loading,
  disabled,
  onBlur,
  name,
  height = '48px',
  inputBgColor,
  labelFontWeight = '500',
  variant,
  onHideLastItem = false,
  iconStart,
  dividerItemIndex = null,
  arrowItemIndex = null,
  requiredIcon = false,
  iconColor = '#838383',
  titleColor = '#555555',
}) {
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      {title && (
        <Typography
          fontWeight={labelFontWeight}
          color={titleColor}
          fontSize={'12px'}
          lineHeight={'18px'}
        >
          {title}{' '}
          {requiredIcon && (
            <strong style={{ color: palette.red[600] }}>*</strong>
          )}
        </Typography>
      )}

      <Box position='relative'>
        {loading ? (
          <Skeleton height='45px' />
        ) : (
          <SelectInput
            bgColor={inputBgColor}
            defaultValue={defaultValue}
            values={values}
            value={value}
            setValue={setValue}
            buttonStyle={buttonStyle}
            placeholder={placeholder}
            onChange={onChange}
            warning={warning}
            disabled={disabled}
            onBlur={onBlur}
            name={name}
            sx={{ height }}
            variant={variant}
            onHideLastItem={onHideLastItem}
            iconStart={iconStart}
            dividerItemIndex={dividerItemIndex}
            arrowItemIndex={arrowItemIndex}
            iconColor={iconColor}
          />
        )}

        {warning ? (
          <img
            src={warningIcon}
            height='16px'
            width={'16px'}
            alt='warningIconTextArea'
            style={{ position: 'absolute', top: '13px', right: '10px' }}
          />
        ) : null}
      </Box>
      {textHelper && (
        <Typography
          color='#999999'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {textHelper}
        </Typography>
      )}
      {errorMessage && (
        <Typography color='#FF5858' lineHeight={'15px'} fontSize={'10px'}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

export function SelectInput({
  values,
  value,
  onChange,
  onBlur,
  name,
  defaultValue,
  placeholder = '',
  warning,
  onHideLastItem,
  buttonStyle = {
    height: '100%',
    padding: '14px',
    fontSize: '14px',
    width: '100%',
    color: '#555555',
  },
  disabled = false,
  bgColor = '#F7F9FA',
  variant,
  iconStart,
  dividerItemIndex,
  arrowItemIndex,
  iconColor,
}) {
  const [open, setOpen] = React.useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        'maxHeight': '360px',
        'border': '1px solid #EEEEEE',
        'borderRadius': '8px',
        'paddingTop': '0px',
        'paddingBottom': '0px',
        'marginTop': '5px',
        ...(variant === 'secondary'
          ? {
              boxShadow: '0px 4px 8px 0px #0000001F',
            }
          : {
              boxShadow: 'none',
            }),

        '&& .MuiList-root	': {
          padding: '0px !important',
        },
      },
    },
  };

  const MuiSelectStyle = {
    borderRadius: '8px !important',
    bgcolor: '#F7F9FA',
    width: buttonStyle.width,
    height: buttonStyle.height,
    display: 'flex',
    justifyContent: 'space-between',
    padding: buttonStyle.padding,
    color: buttonStyle.color,
  };

  const wrapperModifiers = {
    secondary: {
      'bgcolor': '#fafafa',
      '&& .MuiSelect-filled	': {
        ...MuiSelectStyle,
        color: '#2222228F',
      },
    },
  };

  return (
    <Select
      defaultValue={defaultValue}
      IconComponent={() => (
        <Box
          sx={{
            marginRight: variant === 'secondary' ? 0 : '12px',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
            ...(variant === 'secondary' && {
              position: 'absolute',
              right: '5px',
              pointerEvents: 'none',
            }),
          }}
        >
          <ArrowDown color={iconColor} />
        </Box>
      )}
      variant='filled'
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={{
        '&& .MuiOutlinedInput-notchedOutline	': {
          border: `1px solid ${warning ? '#ff5858' : '#EEEEEE'} `,
        },
        'bgcolor': bgColor,

        '&& .MuiSelect-filled	': {
          ...MuiSelectStyle,
        },
        'fontSize': buttonStyle.fontSize,
        'minWidth': '66px',
        'width': '100%',
        'borderRadius': '8px !important',
        '&:hover': {
          background: '#F2F4F5',
        },

        ...(variant && wrapperModifiers[variant]),
      }}
      displayEmpty
      value={
        value !== ''
          ? values.find((option) => option.value === value)?.label ||
            placeholder
          : placeholder
      }
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      input={<OutlinedInput />}
      MenuProps={MenuProps}
      disabled={disabled}
      renderValue={(selected) => {
        if (iconStart) {
          return (
            <Box display={'flex'} sx={{ alignItems: 'center' }}>
              <Box mr={2} display={'flex'}>
                <Calendar size={14} color={'#222222'} />
              </Box>
              {selected}
            </Box>
          );
        }
        if (selected === '') {
          return null;
        }
        return selected;
      }}
    >
      {values.length === 0 ? (
        <MenuItem
          disabled
          sx={{
            color: '#222',
            bgcolor: '#EFF9FF',
            height: '45px',
          }}
        >
          Nada encontrado
        </MenuItem> // Replace with the component you want to show when values is empty
      ) : (
        values.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value ? option.value : value}
            divider={index === dividerItemIndex}
            disabled={option.type === 'group'}
            sx={{
              'color':
                value === option.value ? '#222' : 'rgba(34, 34, 34, 0.56)',
              'bgcolor':
                value === option.value
                  ? '#EFF9FF'
                  : option.type === 'group'
                    ? '#F2F5F5'
                    : 'transparent',
              'height': option.type === 'group' ? '34px' : '45px',
              'justifyContent':
                arrowItemIndex === index ? 'space-between' : 'flex-start',
              '&:last-child': onHideLastItem && {
                display: 'none',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            {typeof option === 'object' ? option.label : value}
            {index === arrowItemIndex && <ArrowRightOutline />}
          </MenuItem>
        ))
      )}
    </Select>
  );
}
