import singleActionTypes from '../actionTypes/single'



const initialState = {    
    loading: false, 
    response: {}
}

const single = (state = initialState, action) => {
    switch (action.type) {

        case singleActionTypes.SET_SINGLE: {

            const { data } = action.payload
            return {
                ...state,
                [data.single]: data.item,
            }
        }

        case singleActionTypes.SET_RESPONSE: {

            const { data } = action.payload
            return {
                ...state,
                response: {
                    [data.single] : data.response
                },
            }
        }

        case singleActionTypes.SET_LOADING: {

            const { single } = action.payload
            return {
                ...state,
                loading: single
            }
        }

        case singleActionTypes.CLEAR_SINGLE: {

            const { single } = action.payload

            const newSingle = {...state}
            delete newSingle[single]
            delete newSingle.response[single]
            return newSingle
        }

        case singleActionTypes.CLEAR_ALL:         
        {

            return initialState
        }

        

        default:
            return state
    }
}

export default single