export const CreditCard = ({ size = 24, color }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4068_4296)'>
        <path
          d='M3.88552 10.4272C3.54468 10.4272 3.26838 10.7236 3.26838 11.0893C3.26838 11.4549 3.54468 11.7513 3.88552 11.7513H5.53123C5.87207 11.7513 6.14838 11.4549 6.14838 11.0893C6.14838 10.7236 5.87207 10.4272 5.53123 10.4272H3.88552Z'
          fill='#222222'
        />
        <path
          d='M6.5598 11.0893C6.5598 10.7236 6.83611 10.4272 7.17695 10.4272H9.64552C9.98636 10.4272 10.2627 10.7236 10.2627 11.0893C10.2627 11.4549 9.98636 11.7513 9.64552 11.7513H7.17695C6.83611 11.7513 6.5598 11.4549 6.5598 11.0893Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0.799805 5.22688C0.799805 4.04072 0.931832 3.0297 1.57796 2.37419C2.21186 1.73109 3.1794 1.59961 4.33974 1.59961H11.6533C12.8166 1.59961 13.7857 1.73103 14.4204 2.37376C15.0678 3.02934 15.1998 4.04076 15.1998 5.22688V10.7723C15.1998 11.9584 15.0678 12.9695 14.4216 13.625C13.7878 14.2681 12.8202 14.3995 11.6599 14.3995H4.34052C3.18065 14.4028 2.21279 14.2737 1.57839 13.6313C0.931717 12.9765 0.799805 11.9649 0.799805 10.779V5.22688ZM2.03445 5.13064C2.043 4.01645 2.20136 3.5642 2.42604 3.33626C2.66931 3.08946 3.16315 2.92375 4.33974 2.92375H11.6533C12.8334 2.92375 13.3291 3.08952 13.5732 3.33669C13.7982 3.56456 13.9566 4.01642 13.9652 5.13064H2.03445ZM13.9655 6.45478V10.7723C13.9655 11.9569 13.8046 12.4285 13.5736 12.6629C13.3303 12.9097 12.8365 13.0754 11.6599 13.0754L4.33974 13.0754L4.33807 13.0754C3.16142 13.0788 2.66821 12.914 2.42561 12.6684C2.19512 12.435 2.03409 11.964 2.03409 10.779V6.45478H13.9655Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_4068_4296'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
