import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  tableCellClasses,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: '48px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '18px',
  paddingBottom: '18px',
  border: '1px solid transparent',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fafafa',
    color: '#999999',
    fontSize: '12px',
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: '10px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  'height': '65px',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },

  '& td, & th': {
    borderBottom: '1px solid #eeeeee',
  },
}));

const TGTable = ({ columns, data, loading }) => {
  const LoadingSkeleton = ({ columns }) => (
    <>
      {Array.from({ length: 10 }, (_, index) => (
        <Fade in={loading} timeout={400} key={index}>
          <StyledTableRow key={index}>
            {columns?.map((column) => (
              <StyledTableCell key={column.id}>
                <Skeleton height={20} width='100%' />
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </Fade>
      ))}
    </>
  );

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <StyledTableCell key={column.id}>
                  {column?.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <LoadingSkeleton columns={columns} />
            ) : (
              data?.map((row) => (
                <Fade in={!loading} timeout={400} key={row.id}>
                  <StyledTableRow key={row.id}>
                    {columns?.map((column) => (
                      <StyledTableCell key={column.id}>
                        {column?.render ? (
                          <Box sx={column.columnSx}>{column.render(row)}</Box>
                        ) : (
                          <Typography variant='body-l'>
                            {row[column.id]}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </Fade>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TGTable;
