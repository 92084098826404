import AuthLayout from './components/AuthLayout'
import RecoveryContent from './components/RecoveryContent'

const Login = () => {
  return (
    <>
      <AuthLayout title='Ajudamos você a criar e promover experiencias únicas.'>
        <RecoveryContent />
      </AuthLayout>
    </>
  )
}

export default Login
