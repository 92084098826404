export const BarCodeIcon = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4275_70031)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.06404 5.20585C3.42898 5.81368 2.98661 6.81388 2.98661 8.42592V15.5749C2.98661 17.1869 3.42898 18.1871 4.06404 18.7949C4.71107 19.4142 5.69946 19.7621 7.049 19.7621H16.9573C18.3068 19.7621 19.2952 19.4142 19.9422 18.7949C20.5773 18.1871 21.0196 17.1869 21.0196 15.5749V8.42592C21.0196 6.81388 20.5773 5.81368 19.9422 5.20585C19.2952 4.58658 18.3068 4.23869 16.9573 4.23869H7.049C5.69946 4.23869 4.71107 4.58658 4.06404 5.20585ZM2.85047 3.85876C3.93738 2.81846 5.42606 2.40039 7.049 2.40039H16.9573C18.5802 2.40039 20.0689 2.81846 21.1558 3.85876C22.2547 4.91051 22.8031 6.4635 22.8031 8.42592V15.5749C22.8031 17.5373 22.2547 19.0903 21.1558 20.142C20.0689 21.1823 18.5802 21.6004 16.9573 21.6004H7.049C5.42606 21.6004 3.93738 21.1823 2.85047 20.142C1.75158 19.0903 1.20312 17.5373 1.20312 15.5749V8.42592C1.20312 6.4635 1.75158 4.91051 2.85047 3.85876Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.05817 6.99614C6.55067 6.99614 6.94991 7.40765 6.94991 7.91529V16.0855C6.94991 16.5931 6.55067 17.0046 6.05817 17.0046C5.56567 17.0046 5.16643 16.5931 5.16643 16.0855V7.91529C5.16643 7.40765 5.56567 6.99614 6.05817 6.99614Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.03065 6.99614C9.52314 6.99614 9.92239 7.40765 9.92239 7.91529V12.0004C9.92239 12.508 9.52314 12.9195 9.03065 12.9195C8.53815 12.9195 8.1389 12.508 8.1389 12.0004V7.91529C8.1389 7.40765 8.53815 6.99614 9.03065 6.99614Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.03065 14.1451C9.52314 14.1451 9.92239 14.5566 9.92239 15.0642V16.0855C9.92239 16.5931 9.52314 17.0046 9.03065 17.0046C8.53815 17.0046 8.1389 16.5931 8.1389 16.0855V15.0642C8.1389 14.5566 8.53815 14.1451 9.03065 14.1451Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9756 6.99614C15.4681 6.99614 15.8673 7.40765 15.8673 7.91529V8.93656C15.8673 9.44419 15.4681 9.85571 14.9756 9.85571C14.4831 9.85571 14.0839 9.44419 14.0839 8.93656V7.91529C14.0839 7.40765 14.4831 6.99614 14.9756 6.99614Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0031 6.99614C12.4956 6.99614 12.8949 7.40765 12.8949 7.91529V16.0855C12.8949 16.5931 12.4956 17.0046 12.0031 17.0046C11.5106 17.0046 11.1114 16.5931 11.1114 16.0855V7.91529C11.1114 7.40765 11.5106 6.99614 12.0031 6.99614Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9756 11.0812C15.4681 11.0812 15.8673 11.4928 15.8673 12.0004V16.0855C15.8673 16.5931 15.4681 17.0046 14.9756 17.0046C14.4831 17.0046 14.0839 16.5931 14.0839 16.0855V12.0004C14.0839 11.4928 14.4831 11.0812 14.9756 11.0812Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.9481 6.99614C18.4406 6.99614 18.8398 7.40765 18.8398 7.91529V16.0855C18.8398 16.5931 18.4406 17.0046 17.9481 17.0046C17.4556 17.0046 17.0563 16.5931 17.0563 16.0855V7.91529C17.0563 7.40765 17.4556 6.99614 17.9481 6.99614Z"
          fill={color}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_4275_70031">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
