import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );




const VerticalBars = ({data, columns, title = 'aaa' }) => {

    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: title,
          },
        },
    };


    const dt = {
        labels: columns,
        datasets: [
        {
            label: 'Quantidade',
            data,
            backgroundColor: [
                'rgba(217,217,217, 1)',
            ],
            barPercentage: 0.5,
        },
        ],
    }




        return (
                <Bar options={options} data={dt} height={80}  />
        )
    }

export default VerticalBars
