import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import CircularProgress from '@mui/material/CircularProgress'
import * as userActions from '../../../actions/userActions'

import appConfig from '../../../config'

import '../login.css'

import { Box, TextField, Typography, useMediaQuery } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TGButton from 'TGComponents/global/TGButton'
import TGTextField from 'TGComponents/global/TGTextField'
import { toast } from 'react-toastify'
import * as notificationsActions from '../../../actions/notificationActions'
import ModalLogin from '../../../components/modal/ModalLogin'
import { loginSchema } from '../schemas/AuthSchema'
import TGAlert from 'TGComponents/global/TGAlert'
import TGCheckbox from 'TGComponents/global/TGCheckbox'

const initialValues = {
  cpf: '',
  senha: '',
  razao_social: '',
}

const LoginContent = () => {
  const [formValues, setformValues] = useState(initialValues)
  const [showPassword, setShowPassword] = useState(false)
  const getFormData = (values) => {}
  const isMobile = useMediaQuery('(max-width:1080px)')
  const uuid_produtora = useRef('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [agreeWithTerms, setAgreeWithTerms] = useState(false)
  const [isPasswordOk, setIsPasswordOk] = useState(false)
  const [error, setError] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { logado } = useSelector((state) => ({
    logado: state.user.uuid || false,
  }))

  const login = (values) =>
    dispatch({
      type: 'login',
      payload: { values },
    })

  const logoff = () => {
    dispatch(userActions.logoff())
  }

  const me = () =>
    dispatch({
      type: 'me',
    })

  useEffect(() => {
    if (logado) navigate('/admin', { replace: true })
  }, [logado])

  const [modalProps, setModalProps] = useState({
    open: false,
    title: 'Selecione a produtora',
    size: 'x4',
    content: null,
    showConfirm: false,
    showClose: false,

    botoes: true,
    handleAction: () => void 0,
    handleClose: () => void 0,
  })

  const handleClose = () =>
    setModalProps({
      ...modalProps,
      open: false,
    })

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const handleSubmitLogin = async (values) => {
    const token = await executeRecaptcha('login')
    values['cpt'] = token
    let cpf = (docEstrangeiro ? values.documento.replace(/[^0-9]/g, '') : values.cpf.replace(/[^0-9]/g, ''))

    const response = await fetch(
      appConfig[appConfig.env].api +
        `/usuarios/verificarUsuarioProdutoras/${cpf}`
    )
    const res = await response.json();

    const data = {
      cpf,
      senha: values.senha,
      cpt: values.cpt,
    }
    // Verifica a senha sem precisar do token
    fetch(appConfig[appConfig.env].api + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((responsePassword) => {
        console.log(responsePassword)
        if (res.data.length && responsePassword.ok) {
          const options = res.data.map(function (item) {
            return { label: item.razao_social, id: item.uuid }
          })

          setModalProps({
            ...modalProps,
            open: true,
            handleClose: handleClose,
            content: (
              <>
                <Box padding='20px 40px'>
                  <Box>
                    <Autocomplete
                      disablePortal
                      id='produtora'
                      name={'produtora'}
                      options={options}
                      onChange={(event, value) => {
                        console.log(value)
                        uuid_produtora.current = value?.id
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Produtora'
                          variant='outlined'
                          className={`form-select  'form-select-normal'`}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </Box>

                  <Box display='grid' width='100%' mt={2}>
                    <TGButton
                      heigh='40px'
                      variant='contained'
                      onClick={() => {
                        if (!uuid_produtora.current) {
                          toast.warning(
                            'Selecione a produtora para fazer o login',
                            {
                              osition: 'top-right',
                              theme: 'colored',
                              onClose: () =>
                                dispatch(notificationsActions.clear()),
                            }
                          )
                        } else {
                          values['uuid_produtora'] = uuid_produtora.current
                          login(values, navigate)
                        }
                      }}
                    >
                      ENTRAR
                    </TGButton>
                  </Box>
                </Box>
              </>
            ),
          })
        } else if (!responsePassword.ok) {
          console.log('oioioioioio222i')
          setError(true)
        } else if (!res.data.length) {
          login(values, navigate)
        }
      })
      .catch((error) => {
        console.log('oioioioioioi')
        setError(true)
        alert('Ocorreu um erro ao verificar a senha')
      })

    console.log(error)
  }

  const OptionsCheckboxes = [
    {
      label: 'Documento Estrangeiro',
      value: 'DE',
    }
  ]
  
  const [docEstrangeiro, setDocEstrangeiro] = useState(false)
  const selecionaDocumentoEstrangeiro = (check) => {
    setDocEstrangeiro(!docEstrangeiro)
  }

  return (
    <>
      <ModalLogin {...modalProps} />

      <Box
        bordeRadius='12px'
        bgcolor='#FFF'
        borderRadius={isMobile ? '0' : '12px'}
        display={['block', 'block', 'flex']}
        flexDirection={'column'}
        justifyContent={'center'}
        width={isMobile ? '100%' : '520px'}
        padding={['28px', '60px']}
        height={isMobile ? '100%' : '90%'}
      >
        <Box maxWidth={['100%', '400px']} m='auto'>
          <Box textAlign='center' minWidth={['100%', '400px']}>
            {isMobile && (
              <img src='/images/login/LoginLogoMobile.png' alt='Logo' />
            )}
            <Typography
              fontSize={['28px', '32px']}
              fontWeight='bold'
              color='#222'
              mt={isMobile ? '32px' : '94px'}
              mb='16px'
            >
              Bem vindo de volta!
            </Typography>
            <Typography fontSize='14px' color='#555' mb='32px'>
              Por favor, entre com seus dados de acesso.
            </Typography>
          </Box>

          {/* INICIO DO FORMULARIO */}
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={handleSubmitLogin}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              /* and other goodies */
            }) => {
              setformValues(values)
              getFormData(values)
              const isAllTouched = Object.keys(touched).every(
                (field) => touched[field]
              )
              const isFieldsValid =
                isAllTouched &&
                Object.keys(errors).every((field) => !errors[field])

              const isAllValid = isFieldsValid && agreeWithTerms

              if (isAllValid !== isFormValid) {
                setIsFormValid(isAllValid)
              }

              if(docEstrangeiro){
                values.cpf = values.documento;
              }
              
              return (
                <Form>
                  <Box display='grid'>
                    <Box mb={'16px'}>
                      <TGCheckbox
                        gap='16px'
                        options={OptionsCheckboxes}
                        onChange={(value) => selecionaDocumentoEstrangeiro(value)}
                      />
                    </Box>
                    <Box mb={'16px'}>
                      {docEstrangeiro ? 
                          <TGTextField
                            label='DOCUMENTO*'
                            type='text'
                            name='documento'
                            id='documento'
                            placeholder='DOCUMENTO'
                            style={{
                              height: '49px',
                            }}
                            onChange={(e) => {
                              handleChange(e)
                              setAgreeWithTerms(e.target.value.length > 0)
                            }}
                          />
                        :
                          <TGTextField
                            label='CPF*'
                            type='text'
                            name='cpf'
                            id='cpf'
                            mask='999.999.999-99'
                            placeholder='CPF'
                            className={`form-control ${
                              errors.cpf && touched.cpf ? 'is-invalid' : ''
                            }`}
                            style={{
                              height: '49px',
                            }}
                            onChange={(e) => {
                              handleChange(e)
                              setAgreeWithTerms(e.target.value.length > 0)
                            }}
                          />

                      }
                    </Box>
                    <Box mb='32px'>
                      <Typography fontSize='12px' color='#555' mb='4px'>
                        Senha*
                      </Typography>
                      <Box
                        position='relative'
                        display='flex'
                        alignItems='center'
                      >
                        <Box width='100%'>
                          <TGTextField
                            type={showPassword ? 'text' : 'password'}
                            name='senha'
                            id='senha'
                            placeholder='senha'
                            className={`form-control ${
                              errors.senha && touched.senha ? 'is-invalid' : ''
                            }`}
                            style={{
                              height: '49px',
                            }}
                          />

                          {!errors.senha && (
                            <Box
                              onClick={togglePasswordVisibility}
                              sx={{
                                cursor: 'pointer',
                                fontSize: '12px',
                                color: '#555',
                                position: 'absolute',
                                right: '12px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              {showPassword ? 'Ocultar' : 'Mostrar'}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <TGButton
                      type='submit'
                      disabled={!docEstrangeiro && (!isAllValid || isSubmitting)}
                    >
                      {isSubmitting ? (
                        <span
                          className='spinner-border spinner-border-sm'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      ) : (
                        'Entrar'
                      )}
                    </TGButton>

                    <Box mt='24px' textAlign='center'>
                      <Typography fontSize='12px' color='#999' mb='4px'>
                        Esqueceu sua senha?
                        <Link
                          to='/recover'
                          style={{ paddingLeft: '6px', color: '#999' }}
                        >
                          Clique aqui
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Form>
              )
            }}
          </Formik>
          <Box mt={isMobile ? '83px' : '133px'}>
            <Typography textAlign='center' color='#555'>
              Não tem uma conta?{' '}
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#555',
                  fontWeight: 'bold',
                }}
                to='/register'
              >
                Cadastre-se
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <TGAlert
        open={error}
        handleClose={() => {
          setError('')
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        message={'Dados inválidos, por favor verifique e tente novamente'}
        type={'error'}
      />
    </>
  )
}

export default LoginContent
