const ArrowLeft = ({
    width = 12,
    height = 12,
    color = '#00B2FF',
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.48047 11L1.36428 6.88384C0.87817 6.39773 0.87817 5.60228 1.36428 5.11617L5.48047 1" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ArrowLeft;