import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
Chart.register(ArcElement)

const PieChart = ({ porcentagem }) => {
  /*const porcentagem = parseFloat(valor1 / 100 * valor2).toFixed(2)*/
  const resto = 100 - porcentagem

  const data = {
    /*labels: [
          'Red',
          'Blue',
          'Yellow'
        ],*/
    /*labels: [
            porcentagem,
        ],*/
    datasets: [
      {
        label: 'My First Dataset',
        data: [porcentagem, resto],
        backgroundColor: ['#00B2FF', 'rgb(196, 196, 196)'],
        hoverOffset: 4,
      },
    ],
  }

  const config = {
    type: 'pie',
    data: data,
  }

  return (
    <>
      <div className='espaco-pie'>
        <Pie data={data} />
      </div>
    </>
  )
}

export default PieChart
