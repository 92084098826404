import { styled } from '@mui/material'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

const TGCalendar = styled(Calendar)({
  // Overall calendar styles
  backgroundColor: '#fff',
  color: '#333',
  border: '1px solid #EEEEEE !important',
  borderRadius: '8px',

  '& .react-calendar__month-view__weekdays__weekday abbr': {
    textDecoration: 'none !important',
  },

  '& .react-calendar__month-view__weekdays': {
    color: '#999999',
    backgroundColor: '#ffffff !important',
    button: {
      color: '#999999',
      minHeight: '44px',
      '&:hover': {
        backgroundColor: '#00B2FF',
      },
    },
  },
  // Navigation styles
  '& .react-calendar__navigation': {
    color: '#999999',
    margin: '0px',
    button: {
      color: '#999999',
      '&:hover': {
        backgroundColor: '#00B2FF',
      },
    },
  },

  '& .react-calendar__tile--now': {
    border: '1px solid #00B2FF',
    backgroundColor: 'transparent',
    borderRadius: '100%',
  },

  // Mes e ano estilo
  '& .react-calendar__month-view__days__day': {
    '&:hover': {
      backgroundColor: '#e3f2fd',
    },
  },

  // Dias selecionados
  '& .react-calendar__tile--range': {
    backgroundColor: '#b3e5fc',
    color: 'black',
    borderRadius: '0',
  },
  '& .react-calendar__tile--active': {
    backgroundColor: '#E6F5FB',
    color: '#222222',
  },

  // dias individuais
  '& .react-calendar__month-view__days__day--weekend': {
    color: '#222222',
  },

  // Estilo para o primeiro dia
  '& .react-calendar__tile--rangeStart': {
    backgroundColor: '#00B2FF',
    borderRadius: '100%',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#00B2FF',
    },
  },

  // Estilo para o dia final
  '& .react-calendar__tile--rangeEnd': {
    backgroundColor: '#00B2FF',
    borderRadius: '100%',
    height: '40px',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#00B2FF',
    },
  },
})

export default TGCalendar
