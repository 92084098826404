export const ScanIcon = ({ width = 24, height = 24, color = '#FAFAFA' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5084_43825)">
        <path
          d="M3.54935 7.92078C3.54935 5.50101 5.50101 3.54935 7.92078 3.54935H10.8351C11.3179 3.54935 11.7094 3.15792 11.7094 2.67507C11.7094 2.19221 11.3179 1.80078 10.8351 1.80078H7.92078C4.5353 1.80078 1.80078 4.5353 1.80078 7.92078V10.8351C1.80078 11.3179 2.19221 11.7094 2.67507 11.7094C3.15792 11.7094 3.54935 11.3179 3.54935 10.8351V7.92078Z"
          fill={color}
        />
        <path
          d="M14.3322 1.80078C13.8494 1.80078 13.4579 2.19221 13.4579 2.67507C13.4579 3.15792 13.8494 3.54935 14.3322 3.54935H16.8302C18.7137 3.54935 20.4522 5.36971 20.4522 7.92078V10.8351C20.4522 11.3179 20.8436 11.7094 21.3265 11.7094C21.8094 11.7094 22.2008 11.3179 22.2008 10.8351V7.92078C22.2008 4.66659 19.9226 1.80078 16.8302 1.80078H14.3322Z"
          fill={color}
        />
        <path
          d="M21.3265 13.4579C21.8094 13.4579 22.2008 13.8494 22.2008 14.3322V16.0808C22.2008 19.4663 19.4663 22.2008 16.0808 22.2008H14.3322C13.8494 22.2008 13.4579 21.8094 13.4579 21.3265C13.4579 20.8436 13.8494 20.4522 14.3322 20.4522H16.0808C18.5006 20.4522 20.4522 18.5006 20.4522 16.0808V14.3322C20.4522 13.8494 20.8436 13.4579 21.3265 13.4579Z"
          fill={color}
        />
        <path
          d="M3.54935 14.3322C3.54935 13.8494 3.15792 13.4579 2.67507 13.4579C2.19221 13.4579 1.80078 13.8494 1.80078 14.3322V16.8302C1.80078 19.9226 4.66659 22.2008 7.92078 22.2008H10.8351C11.3179 22.2008 11.7094 21.8094 11.7094 21.3265C11.7094 20.8436 11.3179 20.4522 10.8351 20.4522H7.92078C5.36971 20.4522 3.54935 18.7137 3.54935 16.8302V14.3322Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.5379 11.1265V9.8813C17.5379 8.87613 17.2577 7.96808 16.5523 7.32685C15.8628 6.69999 14.9137 6.46364 13.8659 6.46364H10.1356C9.08782 6.46364 8.13879 6.69999 7.44924 7.32685C6.74389 7.96808 6.46364 8.87613 6.46364 9.8813V11.1265H5.0065C4.52364 11.1265 4.13221 11.5179 4.13221 12.0008C4.13221 12.4836 4.52364 12.8751 5.0065 12.8751H6.46364V14.1203C6.46364 15.1254 6.74389 16.0335 7.44924 16.6747C8.13879 17.3016 9.08782 17.5379 10.1356 17.5379H13.8659C14.9137 17.5379 15.8628 17.3016 16.5523 16.6747C17.2577 16.0335 17.5379 15.1254 17.5379 14.1203V12.8751H18.9951C19.4779 12.8751 19.8694 12.4836 19.8694 12.0008C19.8694 11.5179 19.4779 11.1265 18.9951 11.1265H17.5379ZM8.62546 8.62069C8.39824 8.82725 8.21221 9.19089 8.21221 9.8813V11.1265H15.7894V9.8813C15.7894 9.19089 15.6033 8.82725 15.3761 8.62069C15.1331 8.39975 14.6833 8.21221 13.8659 8.21221H10.1356C9.31831 8.21221 8.86849 8.39975 8.62546 8.62069ZM15.7894 12.8751H8.21221V14.1203C8.21221 14.8107 8.39824 15.1743 8.62546 15.3809C8.86849 15.6018 9.31831 15.7894 10.1356 15.7894H13.8659C14.6833 15.7894 15.1331 15.6018 15.3761 15.3809C15.6033 15.1743 15.7894 14.8107 15.7894 14.1203V12.8751Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5084_43825">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
