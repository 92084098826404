import searchActionTypes from '../actionTypes/search'

export const get = (endpoint, criteria) => ({
    type: searchActionTypes.SEARCH,
    payload: { endpoint, criteria }
})

export const setResults = (results) => ({
    type: searchActionTypes.SET_RESULTS,
    payload: { results }
})

export const setLoading = (flag) => ({
    type: searchActionTypes.SET_LOADING,
    payload: { flag }
})

export const clear = () => ({
    type: searchActionTypes.CLEAR,
})
