import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useField, useFormikContext } from 'formik'

import Select from './Select'
import * as listsActions from '../../actions/listsActions'

const TicketTypeSelect = ({
  label = 'Tipo de ingresso',
  name,
  errors = {},
  onChange = () => void 0,
}) => {
  const items = useSelector((state) => state.lists.ticketTypes || [])
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const { handleChange } = useFormikContext()

  const getItems = () =>
    dispatch(
      listsActions.getLists(`/usuarios/${user.uuid}/bilhetes-tipo`, {
        list: 'ticketTypes',
        arg: '',
      })
    )

  useEffect(() => {
    getItems()
  }, [])

  return (
    <Select
      errors={errors}
      label={label}
      name={name}
      onChange={(e) => {
        handleChange(e)
        onChange(e)
      }}
    >
      <option value=''>-- Selecione --</option>
      {items.map((item) => (
        <option key={item.uuid} value={item.uuid}>
          {item.nome}
        </option>
      ))}
    </Select>
  )
}

export default TicketTypeSelect
