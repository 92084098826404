import React from 'react';
import { Button, Box } from '@mui/material';
import { BUTTON_VARIANTS, SIZE_STYLES } from './styles';

function Btn({
  type,
  //possíveis variações primary / secondary / tertiary
  variant = 'primary',
  //possíveis tamanhos large / medium / small
  size = 'large',
  color,
  width,
  onClick,
  children,
  startIcon,
  endIcon,
  textTransform = 'initial',
  disabled = false,
  loading = false,
  leftIcon,
  rightIcon,
  sx,
  buttonBoxStyle,
  ...props
}) {
  // Determina os estilos de acordo com a variante
  const {
    bgcolor,
    color: textColor,
    border,
    hoverBgColor,
    hoverColor,
    hoverBorder,
    activeBgColor,
    activeColor,
    activeBorder,
    disabledBgColor,
    disabledColor,
    disabledBorder,
  } = BUTTON_VARIANTS[variant];

  const { fontSize, height, minWidth } = SIZE_STYLES[size];

  return (
    <Box
      className={disabled && 'disabled-button-cursor'}
      style={buttonBoxStyle}
    >
      <Button
        disabled={disabled}
        sx={{
          'padding': '11px 28px',
          'height': height,
          'minWidth': minWidth,
          'fontSize': fontSize,
          'borderRadius': '8px',
          'textTransform': textTransform,
          'bgcolor': bgcolor,
          'color': textColor,
          'border': border,
          '&:hover': {
            bgcolor: hoverBgColor,
            color: hoverColor,
            border: hoverBorder,
          },
          '&:active': {
            bgcolor: activeBgColor,
            color: activeColor,
            border: activeBorder,
          },
          '&:disabled': {
            cursor: `not-allowed`,
          },
          '&&.Mui-disabled': {
            bgcolor: disabledBgColor,
            color: disabledColor,
            border: disabledBorder,
          },
          ...sx,
        }}
        type={type}
        size={size}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        {...props}
      >
        {loading ? (
          <div class='dot-flashing'></div>
        ) : (
          <>
            {leftIcon ? (
              <img
                src={leftIcon}
                alt='moreIcon'
                height='18px'
                width='18px'
                style={{ marginRight: '5px' }}
              />
            ) : null}
            {children}
            {rightIcon ? (
              <img
                src={rightIcon}
                alt='moreIcon'
                height='18px'
                width='18px'
                style={{ marginLeft: '5px' }}
              />
            ) : null}
          </>
        )}
      </Button>
    </Box>
  );
}

export default Btn;
