export const Pencil = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9863_17884)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.1306 3.87004C18.8309 2.57029 17.5206 1.98899 16.1976 2.11885C14.9218 2.24409 13.8654 3.01338 13.0087 3.87004L3.67853 13.2002C3.4125 13.4662 3.18346 13.818 3.01349 14.1562C2.84388 14.4936 2.69588 14.8915 2.64232 15.2736L2.6421 15.2751L2.13387 18.8328L2.13359 18.8346C2.00392 19.7215 2.24653 20.5665 2.84064 21.1582C3.43348 21.7486 4.27808 21.9893 5.15988 21.8679L8.7284 21.3581C9.10553 21.3043 9.50059 21.1555 9.8356 20.9872C10.1726 20.8178 10.5223 20.5917 10.79 20.3326L20.1306 10.992C20.9873 10.1353 21.7566 9.07888 21.8818 7.80308C22.0117 6.48008 21.4304 5.16979 20.1306 3.87004ZM14.2815 5.14283C15.0351 4.3892 15.7192 3.97446 16.3734 3.91025C16.9804 3.85067 17.7895 4.07451 18.8578 5.14283C19.9261 6.21114 20.15 7.02028 20.0904 7.62724C20.0262 8.28142 19.6115 8.96553 18.8578 9.71916L18.5152 10.0618C18.4351 10.0059 18.3445 9.96213 18.2452 9.934C16.2127 9.35814 14.6324 7.77578 14.0664 5.75698C14.0385 5.65721 13.9947 5.56611 13.9387 5.48561L14.2815 5.14283ZM12.5424 6.88191L4.95132 14.473C4.86214 14.5622 4.73597 14.7373 4.62177 14.9645C4.50745 15.192 4.44249 15.3973 4.42483 15.5239L4.42442 15.5268L3.91528 19.0907L3.91479 19.0942C3.85563 19.4972 3.97423 19.7467 4.11084 19.8828C4.24892 20.0203 4.50487 20.1406 4.91252 20.085L4.91449 20.0847L8.47384 19.5762C8.59401 19.5591 8.79689 19.4947 9.02725 19.3789C9.25231 19.2658 9.43357 19.1388 9.53381 19.0432L17.1202 11.4568C15.0048 10.6593 13.3352 8.99209 12.5424 6.88191Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_9863_17884">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
