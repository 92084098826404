import React from 'react'

const SpinnerButton = ({
    className = 'btn btn-primary'
}) => (
    <button className={className} type="button" disabled>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span className="visually-hidden">Loading...</span>
    </button>
)

export default SpinnerButton
