import React, { useState, useEffect } from 'react';
import { useFormikContext, Formik, Form } from 'formik';

import { useSelector, useDispatch } from 'react-redux';

import Input from '../../../components/form/Input';
import Select from '../../../components/form/Select';

import * as singleActions from '../../../actions/singleActions';
import * as userActions from '../../../actions/userActions';
import * as notificationActions from '../../../actions/notificationActions';
import SpinnerButton from '../../../components/form/SpinnerButton';
import Address from '../../../components/form/Address';
import BankSelect from '../../../components/form/BankSelect';
import {
  Outlet,
  Link,
  useNavigate,
  useLocation,
  NavLink,
} from 'react-router-dom';
import MenuConfiguracoes from '../../../components/menu-configuracoes/menuConfiguracoes';
import Crop from '../../../components/take-picture/Crop';

import maskfy from '../../../utils/maskfy';
import appConfig from '../../../config';
import TGLayoutProvisorio from 'TGComponents/layout/TGLayoutProvisorio';
import { Box } from '@mui/system';
import TGButton from 'TGComponents/global/TGButton';
import TGTextField from 'TGComponents/global/TGTextField';

const ProducerAdd = () => {
  const [showpf, setShowpf] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = 'Cadastro Produtora - Ticket And Go';
  });

  const errors = useSelector(
    (state) => state.single?.response?.producer?.errors || {}
  );
  const saved = useSelector(
    (state) => state.single?.response?.producer?.status || false
  );

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/admin/perfil');
  }, []);

  const [producer, setProducer] = useState({
    razao_social: '',
    cnpj: '',
    email: '',
    telefone: '',
    celular: '',
    responsavel: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    numero: '',
    estado: '',
    complemento: '',
    imagem: '',
    taxa: 10.0,
    account: {
      pf: false,
      conta_corrente: true,
      cpf: '',
      cnpj: '',
      nome: '',
      agencia: '',
      conta: '',
      banco: '',
      tipo_pix: 'CPF/CNPJ',
      pix: '',
    },
  });

  const postSingle = (payload) =>
    dispatch(
      singleActions.postSingle(
        `/usuarios/${user.uuid}/produtoras`,
        payload,
        'producer'
      )
    );

  const mostraCampoCpf = () => {
    setShowpf(!showpf);
  };

  const clearSingle = (single) => dispatch(singleActions.clearSingle(single));

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setLoading(false);

        dispatch(userActions.logoff());
        dispatch(
          notificationActions.success(
            'Para acessar o sistema, realize seu login novamente!'
          )
        );
        clearSingle('producer');
      }, 5000);
    }
  }, [saved]);

  const itemsTitular = [
    { label: true, key: 'Pessoa Física' },
    { label: false, key: 'Pessoa Jurídica' },
  ];
  const itemsTipoConta = [
    { label: 'Conta corrente', key: true },
    { label: 'Conta poupança', key: false },
  ];
  return (
    <TGLayoutProvisorio
      pageTitle={'Adicionar Produtora'}
      page={
        <>
          {loading ? (
            <div className='row m-5'>
              <div className='col'>
                <h3 className='text-center'>
                  <div className='spinner-border me-2' role='status'>
                    <span className='visually-hidden'></span>
                  </div>
                  Falta pouco para você fazer parte da Ticket And Go ! Após
                  finalizar o seu cadastro, será preciso realizar um novo login
                  no sistema. Agradeço a sua compreensão! <br />
                  Boa vendas!!!
                </h3>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='col'>
                <div className='row'>
                  <div className='col'>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{ ...producer }}
                      onSubmit={(values) => {
                        setLoading(true);
                        postSingle(values);

                        setProducer({
                          ...producer,
                          ...values,
                        });
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div>
                            <div className='row justify-content-center'>
                              <div
                                className='col-sm-3 mb-3 text-center'
                                style={{ minWidth: '280px' }}
                              >
                                <div className='row mb-2'>
                                  <div className='14-400-14'>
                                    Imagem de perfil
                                  </div>
                                </div>
                                <div className='row'>
                                  <span className='texto-9-400-11-inter'>
                                    A imagem inserida será utilizada como
                                    <br />
                                    foto do perfil e logo / identidade visual
                                    <br />
                                    de e-mails, site, campanhas.
                                  </span>
                                </div>
                                <div
                                  className='row'
                                  style={{ maxHeight: '200px' }}
                                >
                                  <Crop
                                    label=''
                                    defaultImage={formik.values['imagem']}
                                    cropTo={{ width: 100, height: 100 }}
                                    handleCrop={(img) => {
                                      formik.setFieldValue('imagem', img);
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className='col-sm-12 mb-3 mt-3'
                                style={{ minWidth: '280px' }}
                              >
                                <div className='row  ml-6 pb-3'>
                                  <TGTextField
                                    placeholder='00.000.000/0000-00'
                                    errors={errors}
                                    mask='99.999.999/9999-99'
                                    label='CNPJ'
                                    type='text'
                                    name='cnpj'
                                    id='cnpj'
                                  />
                                </div>
                                <div className='row mb-3'>
                                  <TGTextField
                                    placeholder='Nome / Razão Social'
                                    errors={errors}
                                    label='Nome / Razão Social'
                                    type='text'
                                    name='razao_social'
                                    id='razao_social'
                                  />{' '}
                                </div>
                              </div>
                              <div
                                className='col-sm-5'
                                style={{ minWidth: '280px' }}
                              >
                                <div className='row'>
                                  <div className='col-sm-12 mb-3'>
                                    <div className='d-flex flex-column align-items-center pt-4'>
                                      <div className='d-flex flex-row align-items-end mb-2'>
                                        <div className='me-1'></div>
                                      </div>
                                      <div className='row mb-3 texto-9-400-11-inter'>
                                        <div className='col-sm-3'></div>
                                        <div className='col-sm-7'>
                                          <div className='d-flex align-items-center'></div>
                                        </div>
                                        <div className='col-sm-2'></div>
                                      </div>
                                      {1 > 2 ? (
                                        <div className='row mb-3'>
                                          <Link to={'/admin/producer/rate'}>
                                            <button
                                              type='button'
                                              className='btn btn-azul-pequeno'
                                            >
                                              ALTERAR TAXA
                                            </button>
                                          </Link>
                                        </div>
                                      ) : (
                                        <div className='row mb-3 d-none'>
                                          <p className='text-center'>
                                            <b>
                                              Para outras opções, finalize seu
                                              cadastro
                                            </b>
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='titulo-retangulo-cinza'>
                              Contato
                            </div>
                          </div>
                          <div>
                            <div className='row'>
                              <div
                                className='col-sm-4 mb-3'
                                style={{ minWidth: '280px' }}
                              >
                                <div className='row'>
                                  <div className='col-sm-12 mb-3'>
                                    <TGTextField
                                      placeholder='joaodasilva@ticketandgo.com.br'
                                      errors={errors}
                                      label='E-mail'
                                      type='email'
                                      name='email'
                                      id='email'
                                    />
                                  </div>
                                </div>
                              </div>

                              <div
                                className='col-sm-4 mb-3'
                                style={{ minWidth: '280px' }}
                              >
                                <div className='row'>
                                  <div className='col-sm-12 mb-3'>
                                    <TGTextField
                                      placeholder='(99)99999-9999'
                                      errors={errors}
                                      label='Celular'
                                      mask='(99)99999-9999'
                                      type='text'
                                      name='celular'
                                      id='celular'
                                    />
                                  </div>
                                </div>
                              </div>

                              <div
                                className='col-sm-4 mb-3'
                                style={{ minWidth: '280px' }}
                              >
                                <div className='row'>
                                  <div className='col-sm-12 mb-3'>
                                    <TGTextField
                                      placeholder='Nome do responsável'
                                      errors={errors}
                                      label='Nome do responsável'
                                      type='text'
                                      name='responsavel'
                                      id='responsavel'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='titulo-retangulo-cinza'>
                              Endereço
                            </div>
                          </div>
                          <div>
                            <div className='row'>
                              <Address errors={errors} />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='titulo-retangulo-cinza'>
                              Dados Bancários
                            </div>
                          </div>
                          <div>
                            <div className='row'>
                              <div
                                className='col-sm-3 mb-3 pe-3'
                                style={{ minWidth: '280px' }}
                              >
                                <Select
                                  errors={errors}
                                  label='Tipo de conta'
                                  name='account.conta_corrente'
                                  id='account.conta_corrente'
                                  classe='normal'
                                >
                                  <option value={true}>Conta corrente</option>
                                  <option value={false}>Conta poupança</option>
                                </Select>
                              </div>
                              <div
                                className='col-sm-3 mb-3 pe-3'
                                style={{ minWidth: '280px' }}
                              >
                                <BankSelect
                                  errors={errors}
                                  name='account.banco'
                                  id='account.banco'
                                  classe='normal'
                                />
                              </div>
                              <div
                                className='col-sm-3 mb-3 pe-3'
                                style={{ minWidth: '280px' }}
                              >
                                <TGTextField
                                  placeholder='0000'
                                  errors={errors}
                                  label='Agência (com dígito se houver)'
                                  type='text'
                                  name='account.agencia'
                                  id='account.agencia'
                                />
                              </div>
                              <div
                                className='col-sm-3 mb-3 pe-3'
                                style={{ minWidth: '280px' }}
                              >
                                <TGTextField
                                  placeholder='00000-0'
                                  errors={errors}
                                  label='Conta (com dígito se houver)'
                                  type='text'
                                  name='account.conta'
                                  id='account.conta'
                                />
                              </div>
                              <div
                                className='col-sm-3 mb-3 pe-3'
                                style={{ minWidth: '280px' }}
                              >
                                <Select
                                  errors={errors}
                                  label='Tipo de chave PIX'
                                  name='account.tipo_pix'
                                  id='account.tipo_pix'
                                  classe='normal'
                                >
                                  <option value={'CPF/CNPJ'}>CPF/CNPJ</option>
                                  <option value={'email'}>EMAIL</option>
                                  <option value={'telefone'}>TELEFONE</option>
                                  <option value={'telefone'}>ALEATÓRIA</option>
                                </Select>
                              </div>
                              <div
                                className='col-sm-3 mb-3 pe-3'
                                style={{ minWidth: '280px' }}
                              >
                                <TGTextField
                                  placeholder='Chave pix'
                                  errors={errors}
                                  label='Chave pix'
                                  type='text'
                                  name='account.pix'
                                  id='account.pix'
                                />
                              </div>
                            </div>
                          </div>

                          

                          <div className='d-flex justify-content-between pt-3'>
                            <Link to='/admin/producer/index'>
                              <TGButton variant='outlined'>VOLTAR</TGButton>
                            </Link>
                            <TGButton
                              type='submit'
                              className='btn btn-verde-grande float-end'
                            >
                              CADASTRAR
                            </TGButton>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

export default ProducerAdd;
