import { takeEvery, put, takeLatest, call } from 'redux-saga/effects'
import Requester from '../services/requester'

import * as searchActions from '../actions/searchActions'
import * as notificationActions from '../actions/notificationActions'

const _get = (endpoint, criteria) => Requester(`${endpoint}${criteria !== "" ? '?'+criteria : ''}`, {
    method: 'GET',    
})


export function* getSearch(action) {

    yield put(searchActions.setLoading(true))

    try {
        const { endpoint, criteria } = action.payload

        console.log(endpoint, criteria)

        let buildCriteria = criteria ? Object.keys(criteria).map(c => `${c}=${criteria[c]}`).join("&") : false

        const result = yield call(_get, endpoint, buildCriteria)

        if (result.status) {
            const results = result.results

            yield put(searchActions.setResults(results))

        }
        else {
            yield put(notificationActions.warning('Não foi encontrado resultados'));
        }
    } catch (error) {
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'));
    }
    finally {
        yield put(searchActions.setLoading(false))
    }
}





