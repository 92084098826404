export const ArrowRight = ({ size = 24, color = "#222222" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1027_17134)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.73235 5.48892C8.38088 5.84039 8.38088 6.41024 8.73236 6.76171L13.5686 11.5979C13.7883 11.8176 13.7883 12.1823 13.5686 12.402L8.73236 17.2382C8.38088 17.5897 8.38088 18.1595 8.73235 18.511C9.08382 18.8625 9.65367 18.8625 10.0051 18.511L14.8414 13.6748C15.764 12.7522 15.764 11.2477 14.8414 10.3251L10.0051 5.48891C9.65367 5.13744 9.08382 5.13744 8.73235 5.48892Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1027_17134">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
