import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import Select from "./Select";
import * as listsActions from "../../actions/listsActions";

const OptionSelect = ({
  label = "",
  name,
  errors = {},
  options = [],
  defaultReport,
  ...props
}) => {
  return (
    <Select errors={errors} label={label} name={name} {...props}>
      {defaultReport && (
      <option value="">
        {(defaultReport === "checkInEvo" && options[0]?.label) ||
          (defaultReport === "evoSales" && options[1]?.label)}
      </option>
      )}
      <option value="">-- Selecione --</option>

      {options.map((option, idx) => (
        <option
          key={idx}
          value={option.key}
          defaultValue={defaultReport === "checkInEvo" && options[0]?.label}
        >
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default OptionSelect;
