import applicationActionTypes from "../actionTypes/application"
const initialState = {
    access_token: "",
    status: true, 
    sidemenuOpen: true
}

const application = (state = initialState, action) => {
    switch (action.type) {

        case 'setStatus': {            

            return {
                ...state,
                status: !state.staus,
            }
        }

        case applicationActionTypes.SET_SIDEMENU_OPEN: {
            const { flag } = action.payload

            return {
                ...state, 
                sidemenuOpen: flag
            }
        }

        default:
            return state
    }
}

export default application