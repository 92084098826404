import { Stack, Typography, LinearProgress, Box } from '@mui/material';
import { Button } from 'TGComponents/global';
import { formatCurrency } from 'lib/utils';
import TGIcon from 'TGComponents/global/TGIcon';
import TGCopyToClipboard from 'TGComponents/global/TGCopyToClipboard';
import { useEffect, useState } from 'react';
import appConfig from 'config';
import Header from './Header';
import { useFormikContext } from 'formik';
import { copyToClipboard } from 'lib/utils';
import { CheckedSuccess } from 'assets/svgs';
const QrcodePix = ({ retorno, checkoutId }) => {
  const { setFieldValue } = useFormikContext();
  const { dataExpiracao, emv, linkQrCode, valor } = retorno;

  const [timeRemaining, setTimeRemaining] = useState('');
  const [progress, setProgress] = useState(100); // Start at 100%

  useEffect(() => {
    const parseCustomDate = (dateString) => {
      const [datePart, timePart] = dateString.split('T');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hours, minutes, rest] = timePart.split(':');
      const seconds = parseInt(rest.slice(0, 2));
      const milliseconds = parseInt(rest.slice(2, 5));

      return new Date(
        year,
        month - 1,
        day,
        hours,
        minutes,
        seconds,
        milliseconds
      );
    };

    const expirationDate = parseCustomDate(dataExpiracao);
    const startTime = new Date();
    const totalTime = expirationDate - startTime; // Total time in milliseconds

    const makeRequest = async () => {
      try {
        const response = await fetch(
          `${
            appConfig[appConfig.env].api
          }/checkout/consulta-registro-cobrancas/${checkoutId}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFieldValue('successPix', data.status === 'ATIVA' ? false : true);
      } catch (error) {
        console.error('Request failed:', error);
      }
    };

    const updateRemainingTime = () => {
      const now = new Date();
      const diff = expirationDate - now;

      if (diff > 0) {
        const totalSeconds = Math.floor(diff / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        setTimeRemaining(
          `Expira em ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
        );

        // Calculate progress as the percentage of time remaining
        const remainingPercentage = (diff / totalTime) * 100;
        const currentProgress = Math.floor(remainingPercentage);

        setProgress(currentProgress);
        console.log(progress);

        makeRequest();
        setFieldValue('expirado', false);
      } else {
        setTimeRemaining('Expirado');
        setProgress(0); // Expired means progress is 0%
        setFieldValue('isExpired', true);
      }
    };

    updateRemainingTime();
    const intervalId = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(intervalId);
  }, [dataExpiracao]);

  const getProgressColor = (value) =>
    value < 20 ? '#FF7777' : value > 60 ? '#9aff8b' : '#FFE297';

  const [copied, setCopied] = useState();
  const handleClick = (target) => {
    setCopied(true);
    copyToClipboard(target);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <Stack
      gap='24px'
      padding='40px'
      width='480px'
      borderRadius='12px'
      bgcolor='#fafafa'
    >
      <Header
        title={'Finalize seu pagamento'}
        description='Uma nova compra será gerada no seu cartão crédito.'
      />

      <Stack gap='20px' justifyContent='center' alignItems='center'>
        <Typography color='neutral.20' variant='title-m'>
          <Typography display='inline' color='#00A7F0' variant='title-m'>
            Valor:{' '}
          </Typography>
          R$ {formatCurrency(valor)}
        </Typography>

        <TGIcon width='144px' height='144px' src={JSON.parse(linkQrCode)} />

        <Box
          width='125px'
          height='22px'
          position={'relative'}
          bgcolor='#F7F9FA'
        >
          <LinearProgress
            variant='determinate'
            sx={{
              'height': '22px',
              'width': '125px',
              'bgcolor': '#fafafa',
              'border': '1px solid #eeeeee',
              'borderRadius': '4px',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getProgressColor(progress),
              },
            }}
            value={progress}
          ></LinearProgress>
          <Typography
            variant='body-s'
            fontSize='10px'
            color='#222222'
            position='absolute'
            top='20%'
            left='24%'
            transform='translate(-50%, -50%)'
          >
            {timeRemaining}
          </Typography>
        </Box>

        <Button
          buttonBoxStyle={{
            width: '100%',
          }}
          sx={{
            width: '100%',
            bgcolor: copied ? '#53A462 !important' : '#00A7F0',
          }}
          leftIcon={copied ? CheckedSuccess : null}
          variant='primary'
          onClick={() => handleClick(emv)}
        >
          {copied ? 'Código copiado!' : 'Copiar código Pix'}
        </Button>
      </Stack>
      <Stack gap='16px'>
        <Typography variant='body-m'>
          Escaneie o QR- Code ou copie o código Pix para pagar.
        </Typography>
        <Typography variant='body-m'>
          Após a confirmação do pagamento, você receberá as informações de
          acesso à sua compra por e-mail.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default QrcodePix;
