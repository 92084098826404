import { IndeterminateCheckBoxRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Button } from 'TGComponents/global';
import { Bullet } from 'TGComponents/icons/Bullet';
import { BulletPoint } from 'TGComponents/icons/BulletPoint';
import OfferQuantityControl from './OfferQuantityControl';

const Pix = ({ loadingPost, qtym, loadingParcelConfig, checkoutConfig }) => {
  const { setFieldValue } = useFormikContext();

  const miniBanners = [
    {
      icon: <Bullet />,
      text: 'Gere o código pix e pague de forma rápida e segura.',
      bgcolor: '#F7F9FA',
    },
    {
      icon: <BulletPoint />,
      text: 'Após gerar o código você precisará acessar a área PIX no aplicativo do seu banco e escanear o QR code ou digitar o código.',
      bgcolor: '#F7F9FA',
    },
  ];

  return (
    <Stack gap='12px'>
      <Stack gap='24px'>
        <OfferQuantityControl
          qtym={qtym}
          checkoutConfig={checkoutConfig}
          loading={loadingParcelConfig}
        />
        <Stack gap='12px'>
          {miniBanners.map((miniBanner, index) => (
            <Stack
              key={IndeterminateCheckBoxRounded}
              direction='row'
              gap='16px'
              bgcolor={miniBanner.bgcolor}
              p='20px'
              borderRadius='12px'
              alignItems='center'
            >
              {miniBanner.icon}
              <Typography variant='body-m'>{miniBanner.text}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Button
        buttonBoxStyle={{
          width: '100%',
        }}
        sx={{
          width: '100%',
        }}
        variant='primary'
        type='submit'
        loading={loadingPost}
      >
        Gerar código Pix
      </Button>
    </Stack>
  );
};

export default Pix;
