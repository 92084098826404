import { Box } from "@mui/material";
import TGSideBarRight from "TGComponents/global/TGSideBarRight";
import { Button } from "./styles";

const MenuMobileLink = ({ open, onClose, data }) => {
  const handleCloseDrawer = () => {
    onClose(false);
  };

  return (
    <Box display={["box", "box", "none"]}>
      <TGSideBarRight
        onClose={handleCloseDrawer}
        isOpen={open}
        title="Ações"
        rowGap={0}
      >
        <Box
          padding="20px 20px 40px 20px"
          display={"flex"}
          flexDirection={"column"}
        >
          {data.map((btn, index) => (
            <Button key={index} onClick={btn.onClick}>
              {btn.title}
            </Button>
          ))}
        </Box>
      </TGSideBarRight>
    </Box>
  );
};

export default MenuMobileLink;
