import { Tooltip } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  position: "sticky",
  top: 0,
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: theme.zIndex.drawer,
  ...(open && {
    margin: "116px 0 0 20px",
    borderRadius: "16px",
    height: "auto",
    width: drawerWidth,
    borderWidth: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    "& .MuiDrawer-paper": {
      margin: "116px 0 0 20px",
      borderRadius: "16px",
      height: "auto",
      width: drawerWidth,
      borderWidth: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
  }),
  ...(!open && {
    margin: "116px 0 0 20px",
    height: "auto",
    borderRadius: "16px",
    borderWidth: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    "& .MuiDrawer-paper": {
      margin: "116px 0 0 20px",
      height: "auto",
      borderRadius: "16px",
      borderWidth: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    },
  }),
}));

const TGTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, boxShadowTooltip }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#EFF7FA",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "8px !important",
    fontSize: theme.typography.pxToRem(12),
    border: "0.5px solid #dadde9",
    borderRadius: "8px", // Adiciona border-radius
    boxShadow: boxShadowTooltip ? "0px 4px 8px 0px #0000001F" : "none",
  },
  "& .MuiTooltip-arrow": {
    color: "#EFF7FA", // Cor da seta
  },
}));

export { Drawer, TGTooltip };
