import { Link } from "react-router-dom"
const NotFound = () => {

    return (
        <div className="row">
            <div className="col mx-auto">
                <div className="card">
                    <div className="card-body d-flex flex-column align-items-center">
                        <h1 className="text-center display-1">404</h1>
                        <h2 className="text-center">Conteúdo não encontrado</h2>
                        <p className="lead text-center">
                            É possível que você tentou acessar uma área inexistente ou
                            foi encaminhado para esta parte indevidamente. Pressione o botão abaixo para voltar ao início.
                        </p>

                        <Link to="/admin" className="btn btn-primary">Voltar ao início</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound