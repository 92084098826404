import listsActionTypes from "../actionTypes/lists";

export const getLists = (endpoint, criteria) => ({
    type: listsActionTypes.GET_LISTS, 
    payload: { endpoint, criteria }
    
})

export const setLists = (data) => ({
    type: listsActionTypes.SET_LISTS, 
    payload: { data }
    
})

export const setLoading = (list) => ({
    type: listsActionTypes.SET_LOADING, 
    payload: { list }
    
})

export const clearAll = (partOfLabel = null) => ({
    type: listsActionTypes.CLEAR_ALL,
    payload: { partOfLabel }     
    
})

export const clearList = (list) => ({
    type: listsActionTypes.CLEAR_LIST,     
    payload: { list }
})