import Box from '@mui/material/Box';
import TGIcon from 'TGComponents/global/TGIcon';
import dragimg2 from 'assets/svgs/productadd/dragimg2.svg';

export default function TGNoImg({
  width = ['60px', '60px', '120px'],
  height = ['60px', '60px', '120px'],
  borderRadius = '12px',
  mr = '24px',
}) {
  return (
    <Box
      border='1px dashed #EEE'
      sx={{ borderWidth: '2px' }}
      width={width}
      height={height}
      borderRadius={borderRadius}
      bgcolor='#fafafa'
      mr={mr}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <TGIcon src={dragimg2} width='24px' height='24px' />
    </Box>
  );
}
