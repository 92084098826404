export const QuestionIcon = ({
  width = 25,
  height = 24,
  color = '#222222',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_5495_3149)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.3312 1.19922C6.36657 1.19922 1.53125 6.03454 1.53125 11.9992C1.53125 17.9638 6.36657 22.7992 12.3312 22.7992C18.2959 22.7992 23.1312 17.9638 23.1312 11.9992C23.1312 6.03454 18.2959 1.19922 12.3312 1.19922ZM3.33125 11.9992C3.33125 7.18899 7.52103 2.99922 12.3312 2.99922C17.1414 2.99922 21.3312 7.189 21.3312 11.9992C21.3312 16.8094 17.1414 20.9992 12.3312 20.9992C7.52103 20.9992 3.33125 16.8094 3.33125 11.9992Z'
          fill={color}
          fill-opacity='0.56'
        />
        <path
          d='M9.93359 9.75925C9.93359 8.34221 11.0765 7.19922 12.4936 7.19922C13.9107 7.19922 15.0535 8.34228 15.0535 9.75925C15.0535 10.8906 14.305 11.4854 13.8432 11.8011C13.652 11.9332 13.5436 12.0275 13.4776 12.1159C13.4282 12.1822 13.3936 12.2557 13.3936 12.3892V12.5992C13.3936 13.0963 12.9906 13.4992 12.4936 13.4992C11.9965 13.4992 11.5936 13.0963 11.5936 12.5992V12.3892C11.5936 11.233 12.36 10.6378 12.8222 10.3186L12.826 10.316C13.0072 10.1923 13.1116 10.1004 13.1741 10.016C13.2215 9.95206 13.2535 9.88255 13.2535 9.75925C13.2535 9.33621 12.9164 8.99922 12.4936 8.99922C12.0707 8.99922 11.7336 9.33628 11.7336 9.75925C11.7336 10.2563 11.3307 10.6592 10.8336 10.6592C10.3365 10.6592 9.93359 10.2563 9.93359 9.75925Z'
          fill={color}
          fill-opacity='0.56'
        />
        <path
          d='M11.5883 14.9893C11.5883 14.4922 11.9912 14.0893 12.4883 14.0893H12.4973C12.9943 14.0893 13.3973 14.4922 13.3973 14.9893C13.3973 15.4863 12.9943 15.8893 12.4973 15.8893H12.4883C11.9912 15.8893 11.5883 15.4863 11.5883 14.9893Z'
          fill={color}
          fill-opacity='0.56'
        />
      </g>
      <defs>
        <clipPath id='clip0_5495_3149'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.332031)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
