export const Pix = ({ size = 24, color }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4068_6552)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M15.1832 9.8549L10.6044 14.4333C9.58241 15.4553 7.9172 15.4553 6.89523 14.4333L2.31643 9.85436C1.29425 8.83215 1.29427 7.16679 2.31644 6.14458L6.89523 1.56617C7.40672 1.05467 8.07928 0.799805 8.74981 0.799805C9.42022 0.799805 10.0934 1.05461 10.6049 1.56619L15.1832 6.14515C16.2054 7.16736 16.2053 8.8327 15.1832 9.8549ZM7.77779 8.25984L5.92365 10.114C5.80179 10.2279 5.64048 10.2919 5.47203 10.2919H4.71982L3.2994 8.87139C2.81551 8.3875 2.81551 7.61146 3.2994 7.12756L4.71929 5.70762H5.47203C5.64048 5.70762 5.80177 5.77153 5.92363 5.88542L7.77795 7.7398C7.88693 7.84868 8.01008 7.93537 8.14162 7.99986C8.01029 8.06417 7.8869 8.15084 7.77779 8.25984ZM9.72129 8.25984C9.6122 8.15075 9.48902 8.06417 9.35773 7.99984C9.48922 7.93534 9.61233 7.84861 9.72129 7.73965L11.5591 5.9018C11.6838 5.77731 11.8521 5.70762 12.0276 5.70762H12.7798L14.2002 7.12809C14.6841 7.61199 14.6841 8.38803 14.2002 8.87192L12.7803 10.2919H12.0276C11.852 10.2919 11.6836 10.2221 11.5589 10.0975L9.72129 8.25984ZM9.62194 2.54914L11.4676 4.39526C11.1337 4.48993 10.8261 4.66858 10.5763 4.91815L10.4521 5.04289L8.74954 6.74546L6.92314 4.919C6.67348 4.66907 6.36584 4.49009 6.03172 4.39529L7.8782 2.54914C8.12084 2.3065 8.43509 2.18628 8.74981 2.18628C9.06465 2.18628 9.37941 2.30662 9.62194 2.54914ZM7.8782 13.4503L6.03203 11.6041C6.36595 11.5093 6.67358 11.3303 6.92314 11.0805L8.74954 9.25403L10.5763 11.0813C10.8261 11.3309 11.1338 11.5096 11.4678 11.6043L9.62141 13.4503C9.13787 13.9339 8.36174 13.9339 7.8782 13.4503Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_4068_6552'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.75)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
