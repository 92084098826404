import appConfig from "../config"
import { store } from '../store'
import * as userActions from '../actions/userActions'
import * as notificationActions from '../actions/notificationActions'

const ExternalRequester = async (endpoint, payload = {}) => {
    

    const headers = {
        ...payload,
        method: payload.method ?? 'GET',
        headers: payload.headers ?? { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: payload.body ? JSON.stringify(payload.body) : ''
    }

    if (headers.method === 'GET') {
        delete headers['body']
    }

    try {

        let ret;

        
            
        ret = await fetch(`${endpoint}`, headers)


        let result = await ret.json()

        return result

    } catch (e) {
        return { status: false };
    }
}
export default ExternalRequester