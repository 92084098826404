const flatfy = (obj, namespace) => {

    let o  = {...obj}
    try{
        namespace.split('.').forEach(key => {
            o = o[key]
        })
        return o
    }
    catch (err){
        return null
    }
    
  }

export default flatfy