export const Link = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3652_43512)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.39453 5.00039C9.39453 4.66902 9.66316 4.40039 9.99453 4.40039H11.0012C13.3533 4.40039 15.2679 6.32306 15.2679 8.66706C15.2679 11.0191 13.3452 12.9337 11.0012 12.9337H9.99453C9.66316 12.9337 9.39453 12.6651 9.39453 12.3337C9.39453 12.0024 9.66316 11.7337 9.99453 11.7337H11.0012C12.6839 11.7337 14.0679 10.355 14.0679 8.66706C14.0679 6.98439 12.6891 5.60039 11.0012 5.60039H9.99453C9.66316 5.60039 9.39453 5.33176 9.39453 5.00039Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.734375 8.66706C0.734375 6.31569 2.64967 4.40039 5.00104 4.40039H6.00104C6.33241 4.40039 6.60104 4.66902 6.60104 5.00039C6.60104 5.33176 6.33241 5.60039 6.00104 5.60039H5.00104C3.31241 5.60039 1.93438 6.97843 1.93438 8.66706C1.93438 10.3497 3.31312 11.7337 5.00104 11.7337H6.00104C6.33241 11.7337 6.60104 12.0024 6.60104 12.3337C6.60104 12.6651 6.33241 12.9337 6.00104 12.9337H5.00104C2.64896 12.9337 0.734375 11.0111 0.734375 8.66706Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.73438 8.66836C4.73438 8.33699 5.003 8.06836 5.33438 8.06836H10.6677C10.9991 8.06836 11.2677 8.33699 11.2677 8.66836C11.2677 8.99973 10.9991 9.26836 10.6677 9.26836H5.33438C5.003 9.26836 4.73438 8.99973 4.73438 8.66836Z"
          fill={color ?? "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_43512">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.667969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
