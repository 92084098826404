import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'

import * as userActions from '../../actions/userActions'
import * as notificationActions from '../../actions/notificationActions'

import { useEffect } from 'react'



const Logout = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userActions.logoff())
        dispatch(notificationActions.success('Desconectado. Para acessar novamente realize seu login'))
    },[])

    return (        
       <Navigate to="/login" />
    )

}

export default Logout