export const CheckCircle = ({ width = 16, height = 16, color = '#53A462' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.800781 8.00078C0.800781 4.03937 4.03937 0.800781 8.00078 0.800781C11.9622 0.800781 15.2008 4.03937 15.2008 8.00078C15.2008 11.9622 11.9622 15.2008 8.00078 15.2008C4.03937 15.2008 0.800781 11.9622 0.800781 8.00078ZM10.9632 6.6021C11.1676 6.87483 11.1123 7.26164 10.8395 7.46606L7.54617 9.93464C7.32664 10.0992 7.02485 10.0991 6.80546 9.93432L5.16168 8.70004C4.88913 8.49538 4.83408 8.10852 5.03874 7.83597C5.2434 7.56341 5.63026 7.50837 5.90281 7.71303L7.17635 8.66931L10.0992 6.47843C10.372 6.274 10.7588 6.32937 10.9632 6.6021Z"
        fill={color}
      />
    </svg>
  );
};
