import React from "react";
import { useField, Field } from "formik";
import { useFormikContext } from "formik";
import InputMask from "react-input-mask";
import { Typography } from "@mui/material";

const Input = ({ label, errors = {}, mask = null, ...props }) => {
  const { touched } = useFormikContext();

  const [field, meta] = useField(props);

  const hasErrors = meta.touched && meta.error;

  return (
    <>
      {label !== "none" ? (
        <Typography
          fontSize="12px"
          color="#555"
          mb="4px"
          htmlFor={props.id || props.name}
        >
          {label}
        </Typography>
      ) : (
        ""
      )}
      <Field
        as={InputMask}
        mask={mask || ""}
        maskChar=""
        className={`form-control ${hasErrors ? "is-invalid" : ""}`}
        autoComplete="off"
        style={{ minWidth: "100%", width: "100%" }}
        {...field}
        {...props}
      />
      <div className="invalid-feedback">
        {hasErrors && <span>{meta.error}</span>}
      </div>
    </>
  );
};

export default Input;
