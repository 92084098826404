import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Select from './Select'
import * as listsActions from '../../actions/listsActions'

const ProducerSelect = ({
  label = 'Produtora',
  name,
  errors = {},
  disabled = false,
}) => {
  const producers = useSelector((state) => state.lists.producers || [])
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const getProducers = () =>
    dispatch(
      listsActions.getLists(`/usuarios/${user.uuid}/produtoras/allV2`, {
        list: 'producers',
        arg: '',
      })
    )

  useEffect(() => {
    getProducers()
  }, [])

  return (
    <Select
      errors={errors}
      label={label}
      name={name}
      disabled={disabled ? 'disabled' : ''}
    >
      <option value=''>-- Selecione --</option>
      {producers.map((producer) => (
        <option key={producer.id} value={producer.uuid}>
          {producer.razao_social}
        </option>
      ))}
    </Select>
  )
}

export default ProducerSelect
