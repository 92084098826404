export const Bullet = ({ size = 24, color }) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
        fill='#00A7F0'
      />
      <g clip-path='url(#clip0_5479_112618)'>
        <path
          d='M11.5484 15.1707C11.5484 13.1655 13.1653 11.5486 15.1704 11.5486H17.6684C18.1512 11.5486 18.5427 11.1572 18.5427 10.6743C18.5427 10.1915 18.1512 9.80005 17.6684 9.80005H15.1704C12.1996 9.80005 9.7998 12.1998 9.7998 15.1707V17.6686C9.7998 18.1515 10.1912 18.5429 10.6741 18.5429C11.1569 18.5429 11.5484 18.1515 11.5484 17.6686V15.1707Z'
          fill='#FAFAFA'
        />
        <path
          d='M22.3312 9.80005C21.8484 9.80005 21.4569 10.1915 21.4569 10.6743C21.4569 11.1572 21.8484 11.5486 22.3312 11.5486H24.8292C26.8343 11.5486 28.4512 13.1655 28.4512 15.1707V17.6686C28.4512 18.1515 28.8427 18.5429 29.3255 18.5429C29.8084 18.5429 30.1998 18.1515 30.1998 17.6686V15.1707C30.1998 12.1998 27.8 9.80005 24.8292 9.80005H22.3312Z'
          fill='#FAFAFA'
        />
        <path
          d='M29.3255 22.6229C29.8084 22.6229 30.1998 23.0143 30.1998 23.4972V24.9543C30.1998 27.856 27.8558 30.2001 24.9541 30.2001H23.4969C23.0141 30.2001 22.6227 29.8086 22.6227 29.3258C22.6227 28.8429 23.0141 28.4515 23.4969 28.4515H24.9541C26.8901 28.4515 28.4512 26.8903 28.4512 24.9543V23.4972C28.4512 23.0143 28.8427 22.6229 29.3255 22.6229Z'
          fill='#FAFAFA'
        />
        <path
          d='M11.5484 22.3315C11.5484 21.8486 11.1569 21.4572 10.6741 21.4572C10.1912 21.4572 9.7998 21.8486 9.7998 22.3315V24.8294C9.7998 27.8003 12.1996 30.2001 15.1704 30.2001H17.6684C18.1512 30.2001 18.5427 29.8086 18.5427 29.3258C18.5427 28.8429 18.1512 28.4515 17.6684 28.4515H15.1704C13.1653 28.4515 11.5484 26.8346 11.5484 24.8294V22.3315Z'
          fill='#FAFAFA'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13.5942 13.4058C14.0491 12.9509 14.6605 12.8 15.2615 12.8H17.1267C17.7277 12.8 18.3391 12.9509 18.794 13.4058C19.249 13.8608 19.3998 14.4721 19.3998 15.0732V16.9383C19.3998 17.5394 19.249 18.1508 18.794 18.6057C18.3391 19.0606 17.7277 19.2115 17.1267 19.2115H15.2615C14.6605 19.2115 14.0491 19.0606 13.5942 18.6057C13.1392 18.1508 12.9884 17.5394 12.9884 16.9383V15.0732C12.9884 14.4721 13.1392 13.8608 13.5942 13.4058ZM14.8306 14.6423C14.8192 14.6536 14.7369 14.7417 14.7369 15.0732V16.9383C14.7369 17.2699 14.8192 17.3579 14.8306 17.3693C14.8419 17.3806 14.93 17.4629 15.2615 17.4629H17.1267C17.4582 17.4629 17.5462 17.3806 17.5576 17.3693C17.5689 17.3579 17.6512 17.2699 17.6512 16.9383V15.0732C17.6512 14.7417 17.5689 14.6536 17.5576 14.6423C17.5462 14.6309 17.4582 14.5486 17.1267 14.5486H15.2615C14.93 14.5486 14.8419 14.6309 14.8306 14.6423Z'
          fill='#FAFAFA'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M23.0615 12.8C22.4605 12.8 21.8491 12.9509 21.3942 13.4058C20.9392 13.8608 20.7884 14.4721 20.7884 15.0732V16.9383C20.7884 17.5394 20.9392 18.1508 21.3942 18.6057C21.8491 19.0606 22.4605 19.2115 23.0615 19.2115H24.9267C25.5277 19.2115 26.1391 19.0606 26.594 18.6057C27.049 18.1508 27.1998 17.5394 27.1998 16.9383V15.0732C27.1998 14.4721 27.049 13.8608 26.594 13.4058C26.1391 12.9509 25.5277 12.8 24.9267 12.8H23.0615ZM22.5369 15.0732C22.5369 14.7417 22.6192 14.6536 22.6306 14.6423C22.6419 14.6309 22.73 14.5486 23.0615 14.5486H24.9267C25.2582 14.5486 25.3462 14.6309 25.3576 14.6423C25.3689 14.6536 25.4512 14.7417 25.4512 15.0732V16.9383C25.4512 17.2699 25.3689 17.3579 25.3576 17.3693C25.3462 17.3806 25.2582 17.4629 24.9267 17.4629H23.0615C22.73 17.4629 22.6419 17.3806 22.6306 17.3693C22.6192 17.3579 22.5369 17.2699 22.5369 16.9383V15.0732Z'
          fill='#FAFAFA'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13.5942 21.3944C14.0491 20.9395 14.6605 20.7886 15.2615 20.7886H17.1267C17.7277 20.7886 18.3391 20.9395 18.794 21.3944C19.249 21.8493 19.3998 22.4607 19.3998 23.0618V24.9269C19.3998 25.528 19.249 26.1393 18.794 26.5943C18.3391 27.0492 17.7277 27.2001 17.1267 27.2001H15.2615C14.6605 27.2001 14.0491 27.0492 13.5942 26.5943C13.1392 26.1393 12.9884 25.528 12.9884 24.9269V23.0618C12.9884 22.4607 13.1392 21.8493 13.5942 21.3944ZM14.8306 22.6308C14.8192 22.6422 14.7369 22.7302 14.7369 23.0618V24.9269C14.7369 25.2584 14.8192 25.3465 14.8306 25.3578C14.8419 25.3692 14.93 25.4515 15.2615 25.4515H17.1267C17.4582 25.4515 17.5462 25.3692 17.5576 25.3578C17.5689 25.3465 17.6512 25.2584 17.6512 24.9269V23.0618C17.6512 22.7302 17.5689 22.6422 17.5576 22.6308C17.5462 22.6195 17.4582 22.5372 17.1267 22.5372H15.2615C14.93 22.5372 14.8419 22.6195 14.8306 22.6308Z'
          fill='#FAFAFA'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M23.0615 20.7886C22.4605 20.7886 21.8491 20.9395 21.3942 21.3944C20.9392 21.8493 20.7884 22.4607 20.7884 23.0618V24.9269C20.7884 25.528 20.9392 26.1393 21.3942 26.5943C21.8491 27.0492 22.4605 27.2001 23.0615 27.2001H24.9267C25.5277 27.2001 26.1391 27.0492 26.594 26.5943C27.049 26.1393 27.1998 25.528 27.1998 24.9269V23.0618C27.1998 22.4607 27.049 21.8493 26.594 21.3944C26.1391 20.9395 25.5277 20.7886 24.9267 20.7886H23.0615ZM22.5369 23.0618C22.5369 22.7302 22.6192 22.6422 22.6306 22.6308C22.6419 22.6195 22.73 22.5372 23.0615 22.5372H24.9267C25.2582 22.5372 25.3462 22.6195 25.3576 22.6308C25.3689 22.6422 25.4512 22.7302 25.4512 23.0618V24.9269C25.4512 25.2584 25.3689 25.3465 25.3576 25.3578C25.3462 25.3692 25.2582 25.4515 24.9267 25.4515H23.0615C22.73 25.4515 22.6419 25.3692 22.6306 25.3578C22.6192 25.3465 22.5369 25.2584 22.5369 24.9269V23.0618Z'
          fill='#FAFAFA'
        />
      </g>
      <defs>
        <clipPath id='clip0_5479_112618'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
