import userActionTypes from '../actionTypes/user'

export const login = (values) => ({
  type: userActionTypes.LOGIN,
  payload: { values },
})

export const recover = (values) => ({
  type: userActionTypes.RECOVER,
  payload: { values },
})

export const recuperar = (values) => ({
  type: userActionTypes.RECUPERAR,
  payload: { values },
})

export const applyUser = (user) => ({
  type: userActionTypes.APPLY_USER,
  payload: { user },
})

export const setLoading = (flag) => ({
  type: userActionTypes.SET_LOADING,
  payload: { flag },
})

export const logoff = () => ({
  type: userActionTypes.LOGOFF,
})

export const apllypessoa = (pessoa) => ({
  type: 'applypessoa',
  payload: { pessoa },
})

export const applyerrors = (errors) => ({
  type: 'applyerrors',
  payload: { errors },
})
