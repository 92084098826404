import { Skeleton, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { formatCurrency } from 'lib/utils';
import { Button } from 'TGComponents/global';

const OfferQuantityControl = ({ qtym, checkoutConfig, loading }) => {
  const { values, setFieldValue } = useFormikContext();

  // Define the field name that stores the quantity in your formik values
  const quantityField = 'quantity'; // Replace this with the actual field name

  const handleIncrease = () => {
    if (values[quantityField] < qtym) {
      setFieldValue(quantityField, values[quantityField] + 1);
    }
  };

  const handleDecrease = () => {
    if (values[quantityField] > 1) {
      setFieldValue(quantityField, values[quantityField] - 1);
    }
  };

  return (
    <Stack direction='row' justifyContent='space-between'>
      <Stack gap='4px'>
        <Typography variant='label-m' color='neutral.20'>
          {loading ? <Skeleton width='50px' /> : checkoutConfig?.nome}
        </Typography>
        <Typography variant='body-l' color='neutral.20'>
          {loading ? (
            <Skeleton width='120px' />
          ) : (
            <>
              R$ {formatCurrency(checkoutConfig?.valor)}{' '}
              {checkoutConfig?.csTaxaConveniencia ? (
                <Typography
                  variant='body-l'
                  color='rgba(34, 34, 34, 0.56)'
                  display='inline'
                >
                  (+R$ {formatCurrency(checkoutConfig?.taxaConveniencia)} taxa)
                </Typography>
              ) : null}
            </>
          )}
        </Typography>
      </Stack>
      {qtym > 1 && (
        <Stack alignItems='center' direction='row'>
          <Button
            size='medium'
            sx={{
              width: '0px',
              px: '20px',
              bgcolor: '#FFFFFF',
              color: 'neutral.secondary',
              border: '2px solid rgba(34, 34, 34, 0.08)',
            }}
            onClick={handleDecrease}
            disabled={(values[quantityField] === 1 && true) || loading}
          >
            {' '}
            -{' '}
          </Button>
          <Typography px='20px'>{values[quantityField]}</Typography>

          <Button
            size='medium'
            sx={{ width: '0px', px: '20px', bgcolor: '#53A462' }}
            onClick={handleIncrease}
            disabled={
              (Number(values[quantityField]) === Number(qtym) && true) ||
              loading
            }
          >
            {' '}
            +{' '}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default OfferQuantityControl;
