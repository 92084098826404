import { useField, Field } from 'formik'

import { useFormikContext } from 'formik'

import flatfy from '../../utils/flatfy'

import './Inputs.css'

const TextArea = ({ label, errors = {}, rows = 5, ...props }) => {
  const errTag = props.errTag || props.name

  const { values, handleChange, setFieldValue, handleBlur } = useFormikContext()

  const hasErrors = flatfy(errors, props.name) || false

  const [field] = useField(props)

  return (
    <>
      <label className='form-label' htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea
        rows={rows}
        className={`form-control ${hasErrors ? 'is-invalid' : ''}`}
        autoComplete='off'
        {...field}
        {...props}
      >
        {props.value || ''}
      </textarea>
      <div className='invalid-feedback'>{(hasErrors || [])?.join(' ')}</div>
    </>
  )
}

export default TextArea
