import { takeEvery, put, takeLatest, call } from 'redux-saga/effects';
import Requester from '../services/requester';

import * as userActions from '../actions/userActions';
import * as notificationActions from '../actions/notificationActions';

const _login = (values) =>
  Requester('/auth/login', {
    method: 'POST',
    body: values,
  });

const _recover = (values) =>
  Requester('/auth/solicitar-codigo-senha', {
    method: 'POST',
    body: values,
  });

const _recuperar = (values) =>
  Requester('/auth/recuperar-senha', {
    method: 'POST',
    body: values,
  });

const _addpessoa = (values) =>
  Requester('/api/pessoas/store', {
    method: 'POST',
    body: values,
  });

const _me = () =>
  Requester('/api/auth/me', {
    method: 'GET',
  });

export function* login(action) {
  yield put(userActions.setLoading(true));

  try {
    const { values } = action.payload;
    const result = yield call(_login, values);

    if (result.status === 'success') {
      const {
        token,
        uuid,
        foto,
        super_admin,
        nome,
        email,
        complete,
        boUsuarioVariasProdutoras,
        roles = [],
      } = result.data;

      const user = {
        uuid,
        nome,
        email,
        foto,
        complete,
        boUsuarioVariasProdutoras,
        super_admin,
        roles,
      };

      const menus = [];
      yield put(
        userActions.applyUser({
          ...user,
          menus,
          access_token: token,
        })
      );
      yield put(notificationActions.success('Login realizado com sucesso'));
    } else {
      yield put(
        notificationActions.error(
          'Falha de autenticação. Verifique os dados e tente novamente'
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'
      )
    );
  } finally {
    yield put(userActions.setLoading(false));
  }
}

export function* recover(action) {
  yield put(userActions.setLoading(true));

  try {
    const { values } = action.payload;
    const result = yield call(_recover, values);

    if (result.status === 'success') {
      const {
        token,
        uuid,
        super_admin,
        nome,
        email,
        complete,
        roles = [],
      } = result.data;

      const user = {
        uuid,
        nome,
        email,
        complete,
        super_admin,
        roles,
      };

      const menus = [];

      /*yield put(
        userActions.applyUser({
          ...user,
          menus,
          access_token: token,
        })
      );*/
      yield put(notificationActions.success('Nova senha enviada por e-mail'));
    } else {
      yield put(
        notificationActions.error(
          'Falha na recuperação da sua senha. Verifique os dados e tente novamente'
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'
      )
    );
  } finally {
    yield put(userActions.setLoading(false));
  }
}

export function* recuperar(action) {
  yield put(userActions.setLoading(true));

  try {
    const { values } = action.payload;
    const result = yield call(_recuperar, values);

    if (result.status === 'success') {
      const {
        token,
        uuid,
        super_admin,
        nome,
        email,
        complete,
        roles = [],
      } = result.data;

      const user = {
        uuid,
        nome,
        email,
        complete,
        super_admin,
        roles,
      };

      const menus = [];

      yield put(
        notificationActions.success('A sua senha foi alterada com sucesso')
      );
    } else {
      yield put(
        notificationActions.error(
          'Falha na recuperação da sua senha. Verifique o Código e tente novamente'
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      notificationActions.error(
        'Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'
      )
    );
  } finally {
    yield put(userActions.setLoading(false));
  }
}

export function* addpessoa(action) {
  try {
    const { values } = action.payload;
    const result = yield call(_addpessoa, values);

    if (result.status) {
      alert('Sucesso');
      yield put(userActions.apllypessoa(result.pessoa));
    } else {
      alert('Erro!!');
      yield put(userActions.applyerrors(result.errors));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* me(action) {
  try {
    const result = yield call(_me);

    if (result.status) {
      alert('Sucesso');
    } else {
      alert('Erro!!');
    }
  } catch (error) {
    console.log(error);
  }
}
