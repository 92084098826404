const actionTypes = {
    INSERT: 'CRUD_SAGA/INSERT',
    UPDATE: 'CRUD_SAGA/UPDATE',
    DELETE: 'CRUD_SAGA/DELETE',
    GET: 'CRUD_SAGA/GET',
    APPLY_DATA: 'CRUD/APPLY_DATA',
    APPLY_LISTS: 'CRUD/APPLY_LISTS',
    APPLY_ERRORS: 'CRUD/APPLY_ERRORS',
    SET_LOADING: 'CRUD/SET_LOADING',
    CLEAR: 'CRUD/CLEAR',
}

export default actionTypes