import { Box, Typography } from '@mui/material';

export default function TGFormGroup({
  children,
  title,
  isNew = false,
  gap = ['16px', '16px', '24px'],
  titleGap = { xs: '16px', md: '24px' },
}) {
  const gradientStyle = {
    background:
      'radial-gradient(203.12% 129.61% at 100% 8.52%, #54CCFF 0%, #51A1FF 100%);',
    WebkitTextFillColor: 'white',
  };

  return (
    <Box sx={{ gap: titleGap }} display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} gap='16px' alignItems={'center'}>
        <Typography
          color={'#222222'}
          fontSize={'18px'}
          fontWeight={'600'}
          lineHeight={'27px'}
        >
          {title}
        </Typography>
        {isNew && (
          <Typography
            sx={gradientStyle}
            my='auto'
            borderRadius={'4px'}
            color={'#222222'}
            fontSize={'10px'}
            fontWeight={'600'}
            lineHeight={'15px'}
            textTransform={'uppercase'}
            height={'23px'}
            width={'47px'}
            textAlign={'center'}
            margin='0'
            padding={'4px 8px 4px 8px'}
          >
            Novo
          </Typography>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={gap}>
        {children}
      </Box>
    </Box>
  );
}
