import { createStore, applyMiddleware, compose } from 'redux'
//import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from './reducers/root'
//import { createFilter } from 'redux-persist-transform-filter';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/root-saga'
import appConfig from './config'

/*declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}*/

const migrations = {
  // initial version 0: we will clean up all historical data
  // from local storage for the time
  // before we began with migration versioning
  0: (state) => {
    state = {}

    return { ...state }
  },
  // Next version
  //	1 : (state) =>
  //	{
  //		return { ...state };
  //	}
}

const persistConfig = {
  key: 'root',
  storage: storage,
  // migrate will iterate state over all version-functions
  // from migrations until version is reached
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: 'soop3r-b4dBoY#$#',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
  //whitelist: ['userReducer']
  whitelist: ['application', 'attendee', 'user', 'meeting'],
}

const sagaMiddleware = createSagaMiddleware()

const reduxMiddlewares = [sagaMiddleware]

//if (appConfig.env === 'development') {
//    const reduxLogger = createLogger(
//        {
//            // filter VOLUME level actions from log
//            predicate: (getState, action) => !(action.type === 'SET_PEER_VOLUME'),
//            duration: true,
//            timestamp: false,
//            level: 'log',
//            logErrors: true
//        });
//
//    reduxMiddlewares.push(reduxLogger);
//}

const composeEnhancers =
  (appConfig.env === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const enhancer = composeEnhancers(applyMiddleware(...reduxMiddlewares))

const pReducer = persistReducer(persistConfig, rootReducer)

const initialState = {
  intl: {
    locale: null,
    messages: null,
  },
  // ...other initialState
}

const store = createStore(pReducer, initialState, enhancer)

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
