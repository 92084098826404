// validationSchemas.js
import { TGValidations } from 'lib/helpers/TGValidations'
import * as yup from 'yup'

// Função para validar CPF
const validarCPF = (cpf) => {
  var cpfRegex = /^(?:(\d{3})\.(\d{3})\.(\d{3})-(\d{2}))$/
  if (!cpfRegex.test(cpf)) {
    return false
  }

  var numeros = cpf.match(/\d/g).map(Number)

  // Check for repeated digits
  if (
    numeros.every((digit) => digit === numeros[0]) ||
    numeros.join('') === '12345678909' // CPFs with all digits in sequence
  ) {
    return false
  }

  var soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 9) {
      return acc + cur * (10 - idx)
    }
    return acc
  }, 0)

  var resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== numeros[9]) {
    return false
  }

  soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 10) {
      return acc + cur * (11 - idx)
    }
    return acc
  }, 0)

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== numeros[10]) {
    return false
  }

  return true
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const loginSchema = yup.object().shape({
  docEstrangeiro: yup.boolean(),
  cpf: yup
    .string()
    .when('docEstrangeiro', {
      is: false,
      then: (schema) =>
        schema
          .required('O CPF é obrigatório')
          .test('is-valid-cpf', 'Por favor insira um CPF válido', validarCPF),
      otherwise: (schema) => schema.notRequired(),
    }),
  senha: yup.string().required('A senha é obrigatória'),
})

const registerSchema = yup.object().shape({
  nome: yup
    .string()
    .required('O nome é obrigatório')
    .max(60, 'O nome não pode ter mais de 20 caracteres'),
  email: yup
    .string()
    .required('O e-mail é obrigatório')
    .test('is-valid-email', 'Por favor insira um e-mail válido', (email) =>
      emailRegex.test(email)
    ),
  cpf: yup
    .string()
    .required('O CPF é obrigatório')
    .test('is-valid-cpf', 'Por favor insira um CPF válido', validarCPF),
  senha: TGValidations.senha,
})

const recoverySchema = yup.object().shape({
  cpf: yup
    .string()
    .required('O CPF é obrigatório')
    .test('is-valid-cpf', 'Por favor insira um CPF válido', validarCPF),
})
const recoveryCodeSchema = yup.object().shape({
  codigo: yup
    .string()
    .required('O código é obrigatório')
    .max(8, 'O código deve ter 6 caracteres'),
  senha: TGValidations.senha,
})

export { loginSchema, registerSchema, recoverySchema, recoveryCodeSchema }
