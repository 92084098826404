export const ArrowRightOutline = ({
  width = 8,
  height = 15,
  color = "#222222",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.732353 0.988947C0.380882 1.34042 0.380883 1.91027 0.732356 2.26174L5.56858 7.09794C5.78826 7.31762 5.78826 7.68237 5.56858 7.90204L0.732356 12.7382C0.380883 13.0897 0.380881 13.6595 0.732351 14.011C1.08382 14.3625 1.65367 14.3625 2.00514 14.011L6.84137 9.17484C7.76399 8.25222 7.76399 6.74777 6.84137 5.82515L2.00515 0.988944C1.65367 0.637473 1.08382 0.637474 0.732353 0.988947Z"
        fill={color ?? "currentColor"}
        fill-opacity="0.56"
      />
    </svg>
  );
};
