export const Money = ({ size = 24, color }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4068_2421)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.2498 3.26838C8.59064 3.26838 8.86695 3.54468 8.86695 3.88552V4.09123H9.07513C10.3275 4.09123 11.3355 5.10315 11.3355 6.3541C11.3355 6.69494 11.0592 6.97124 10.7184 6.97124C10.3775 6.97124 10.1012 6.69494 10.1012 6.3541C10.1012 5.78274 9.6437 5.32552 9.07513 5.32552H8.86695V7.5542L9.93904 7.90959C10.2725 8.02236 10.6465 8.19482 10.926 8.53509C11.2145 8.88632 11.3355 9.33224 11.3355 9.85741C11.3355 10.9903 10.4143 11.9084 9.28239 11.9084H8.86695V12.1141C8.86695 12.4549 8.59064 12.7312 8.2498 12.7312C7.90897 12.7312 7.63266 12.4549 7.63266 12.1141V11.9084H7.42449C6.17216 11.9084 5.16409 10.8965 5.16409 9.64551C5.16409 9.30467 5.44039 9.02837 5.78123 9.02837C6.12207 9.02837 6.39838 9.30467 6.39838 9.64551C6.39838 10.2169 6.85591 10.6741 7.42449 10.6741H7.63266V8.44538L6.56056 8.08999C6.22714 7.97723 5.85314 7.80476 5.57361 7.4645C5.28508 7.11327 5.16409 6.66736 5.16409 6.1422C5.16409 5.00934 6.08531 4.09123 7.21723 4.09123H7.63266V3.88552C7.63266 3.54468 7.90897 3.26838 8.2498 3.26838ZM7.21723 5.32552H7.63266V7.14505L6.95433 6.92019C6.70417 6.8354 6.59001 6.75729 6.52734 6.681C6.47354 6.61551 6.39838 6.47873 6.39838 6.1422C6.39838 5.69291 6.76509 5.32552 7.21723 5.32552ZM8.86695 10.6741V8.85454L9.54527 9.07939C9.79544 9.16418 9.90959 9.24229 9.97226 9.31858C10.0261 9.38407 10.1012 9.52087 10.1012 9.85741C10.1012 10.3067 9.73451 10.6741 9.28239 10.6741H8.86695Z'
          fill='#222222'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.2498 0.799805C4.27335 0.799805 1.0498 4.02335 1.0498 7.9998C1.0498 11.9762 4.27335 15.1998 8.2498 15.1998C12.2262 15.1998 15.4498 11.9762 15.4498 7.9998C15.4498 4.02335 12.2262 0.799805 8.2498 0.799805ZM2.28409 7.9998C2.28409 4.70503 4.95503 2.03409 8.2498 2.03409C11.5445 2.03409 14.2155 4.70503 14.2155 7.9998C14.2155 11.2945 11.5445 13.9655 8.2498 13.9655C4.95503 13.9655 2.28409 11.2945 2.28409 7.9998Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_4068_2421'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.25)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
