// Copy to clipboard
const copyToClipboard = (text, handleClick) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      handleClick?.();
      console.log('Text copied to clipboard');
    })
    .catch((err) => {
      // Handle errors here
      console.error('Failed to copy text: ', err);
    });
};

// QUANDO O TEXTO FOR MUITO GRANDE, ADICIONA O '...'
const shortenText = (text, maxLength = 43) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

// Gera slug a partir do campo nome para o componente TGCheckSlug
function generateSlug(str) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

// Função utilitária para converter uma string de data no formato brasileiro (dia/mês/ano) em um objeto de data JavaScript
const parseBrazilianDate = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0'); // Gets the day of the month (1-31)
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Gets the month (0-11) and adds 1
  const year = date.getFullYear(); // Gets the full year (e.g., 2024)

  return `${day}/${month}/${year}`; // Formats the date as "DD/MM/YYYY"
};

const formatStringDate = (date) => {
  const [day, month, year] = date.split('/').map(Number);

  const parsedDate = new Date(year, month - 1, day);

  return parsedDate;
};

// Função utilitária para filtrar e ordenar uma lista de itens com base em filtros e opções de ordenação
const filterAndSortItems = (
  items, // Lista de itens a serem filtrados e ordenados
  selectedFilters, // Filtros selecionados para aplicar à lista
  sortOption, // Opção de ordenação ('recentes' ou 'antigos')
  startIndex, // Índice inicial para paginação
  endIndex // Índice final para paginação
) => {
  return items
    .filter(
      (item) =>
        selectedFilters.length === 0 || selectedFilters.includes(item.status)
    )
    .sort((a, b) => {
      const dateA = parseBrazilianDate(a.createdAt);
      const dateB = parseBrazilianDate(b.createdAt);

      if (sortOption === 'recentes') {
        return dateB - dateA;
      } else if (sortOption === 'antigos') {
        return dateA - dateB;
      }

      return 0;
    })
    .slice(startIndex, endIndex);
};

async function convertURLToFile(url, headers = {}) {
  try {
    const response = await fetch(url, { headers, mode: 'no-cors' });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response}`);
    }

    const blob = await response.blob();
    return new File([blob], 'filename', { type: blob.type });
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}

function convertData(dataString) {
  const data = new Date(dataString);

  // Opções de formatação
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

  // Formatar a data
  const dataFormatada = data.toLocaleDateString('pt-BR', options);

  return dataFormatada;
}

function convertDataWithHours(dataString) {
  const data = new Date(dataString);

  // Opções de formatação
  const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const optionsTime = { hour: '2-digit', minute: '2-digit' };

  // Formatar a data e hora
  const dataFormatada = data.toLocaleDateString('pt-BR', optionsDate);
  const horaFormatada = data.toLocaleTimeString('pt-BR', optionsTime);

  return `${dataFormatada} • ${horaFormatada}`;
}

// CONVERT DATA COM BARRAS PARA ISO TIMESTAMP PADRAO QUE O BACKEND TA RECEBENDO
const convertToISODate = (dateString, timeString) => {
  const parts = dateString.split('/');
  return `${parts[2]}-${parts[1]}-${parts[0]}T${timeString}:00.000Z`;
};

const convertToISOOnlyDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
};

//formata um valor em dinheiro 00.00 para o padrão br
function formatCurrency(value) {
  if (value === null || value === undefined) {
    return '0,00';
  }

  // If the value is already formatted, return it as is
  const isAlreadyFormatted = /^\d{1,3}(?:\.\d{3})*(?:,\d{2})?$/.test(value);
  if (isAlreadyFormatted) {
    return value;
  }

  // Convert the value to a number
  const numericValue = Number(value);

  // If the conversion fails, return '0,00'
  if (isNaN(numericValue)) {
    return '0,00';
  }

  // Format the number as currency in 'pt-BR' locale with two decimal places
  return numericValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function revertFromISODate(isoDateString) {
  return new Date(isoDateString);
}
function stringToColor(name) {
  let hash = 0;
  let i;

  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash); // Calculate hash code
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff; // Extract color components
    color += `00${value.toString(16)}`.slice(-2); // Convert to hexadecimal
  }
  return color;
}
//Gera uma cor a partir do nome
function stringAvatar(name) {
  const initials = name
    .split(' ')
    .filter((n) => n !== '') // Filter out any empty strings just in case
    .map((n) => n[0]) // Map to first character of each part
    .join('') // Join to form initials
    .toUpperCase(); // Convert to uppercase for consistency

  return {
    sx: {
      bgcolor: stringToColor(name), // Set background color
    },
    children: initials.length > 1 ? initials.substring(0, 2) : initials, // Use first two initials if possible, else use what's available
  };
}

function formatPhoneNumber(phoneNumber) {
  // Convert the number to a string to ensure it's treated as text
  const numStr = phoneNumber.toString();

  // Extract parts of the number using substring
  const areaCode = numStr.substring(0, 2); // Get the first 2 digits for the area code
  const firstPart = numStr.substring(2, 7); // Get the next 5 digits
  const secondPart = numStr.substring(7, 11); // Get the last 4 digits

  // Combine the parts with the desired formatting and return
  return `(${areaCode}) ${firstPart}-${secondPart}`;
}

function formatRG(rgNumber) {
  // Convert the number to a string to ensure it's treated as text
  const rgStr = rgNumber.toString();

  // Extract parts of the number using substring
  const firstPart = rgStr.substring(0, 2); // Get the first 2 digits
  const secondPart = rgStr.substring(2, 5); // Get the next 3 digits
  const thirdPart = rgStr.substring(5, 8); // Get the next 3 digits
  const lastPart = rgStr.substring(8); // Get the remaining digits

  // Combine the parts with the desired formatting and return
  return `${firstPart}.${secondPart}.${thirdPart}-${lastPart}`;
}

function formatCPF(cpfNumber) {
  // Convert the number to a string to ensure it's treated as text
  const cpfStr = cpfNumber.toString();

  // Ensure the CPF number has 11 digits, padding with zeros if necessary
  const paddedCpfStr = cpfStr.padStart(11, '0');

  // Extract parts of the number using substring
  const firstPart = paddedCpfStr.substring(0, 3); // Get the first 3 digits
  const secondPart = paddedCpfStr.substring(3, 6); // Get the next 3 digits
  const thirdPart = paddedCpfStr.substring(6, 9); // Get the next 3 digits
  const lastPart = paddedCpfStr.substring(9); // Get the last 2 digits

  // Combine the parts with the desired formatting and return
  return `${firstPart}.${secondPart}.${thirdPart}-${lastPart}`;
}

function parseCurrency(value) {
  let integerPart = '';
  let fractionalPart = '';
  let decimalFound = false;

  // Iterate backwards to identify the decimal part and integer part
  for (let i = value.length - 1; i >= 0; i--) {
    if (value[i] >= '0' && value[i] <= '9') {
      if (decimalFound) {
        // Prepend digits to the integer part if decimal separator was found
        integerPart = value[i] + integerPart;
      } else {
        // Append digits to the fractional part if decimal separator hasn't been found
        fractionalPart = value[i] + fractionalPart;
      }
    } else {
      // The first non-numeric character from the end is treated as the decimal separator
      decimalFound = true;
    }
  }

  // Combine integer and fractional parts with a dot as the decimal separator
  const numericPart = integerPart + '.' + fractionalPart;

  return parseFloat(numericPart);
}

function convertDateFormat(dateStr) {
  // Split the input date string on '/'
  const parts = dateStr.split('/');

  // Extract the day, month, and year from the parts
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];

  // Reformat the date into 'YYYY-MM-DD'
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

function formatCNPJ(cnpj) {
  // Convert the input to a string (in case a number is passed)
  const cnpjStr = String(cnpj).replace(/\D/g, ''); // Remove all non-digit characters

  // Use a regular expression to format the CNPJ
  return cnpjStr.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  );
}
function formatNumber(number) {
  return number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

function formatDateWithHours(inputDateStr) {
  const inputDate = new Date(inputDateStr);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function calculatePercentage(total, part) {
  if (total === 0) {
    return 0;
  }
  return (part / total) * 100;
}

function deepMerge(target, source) {
  if (source == null || typeof source !== "object") {
    return target;
  }

  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && target[key] instanceof Object) {
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}

export {
  capitalizeFirstLetter,
  formatDateWithHours,
  formatNumber,
  formatCNPJ,
  formatCPF,
  formatPhoneNumber,
  formatRG,
  parseCurrency,
  formatCurrency,
  stringAvatar,
  copyToClipboard,
  shortenText,
  filterAndSortItems,
  parseBrazilianDate,
  generateSlug,
  convertURLToFile,
  formatDate,
  convertData,
  convertDataWithHours,
  convertToISODate,
  convertToISOOnlyDate,
  convertDateFormat,
  revertFromISODate,
  formatStringDate,
  calculatePercentage,
  deepMerge,
};
