import styled from "styled-components";
import { Tab as TabMUI } from "@mui/material";
import pallete from "TGComponents/theme/pallete";

export const Tab = styled(TabMUI)`
  font-size: 16px;
  color: ${pallete.base.blackAlpha};
  font-weight: 500;
  text-transform: unset;

  &.Mui-selected {
    font-weight: 600;
    color: ${pallete.base.black};
  }
`;
