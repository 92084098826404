import userActionTypes from './../actionTypes/user'

const initialState = {
  access_token: '',
  menus: [],
  id: null,
  name: null,
  email: null,
  avatar: null,
  phone: null,
  active: 'n',
  loading: false,
  boUsuarioVariasProdutoras: false,
  pessoa: {},
  errors: {},
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.APPLY_USER: {
      const { user } = action.payload

      return {
        ...state,
        ...user,
      }
    }

    case userActionTypes.SET_LOADING: {
      const { flag } = action.payload

      return {
        ...state,
        loading: flag,
      }
    }

    case 'applypessoa': {
      const { pessoa } = action.payload

      return {
        ...state,
        pessoa,
      }
    }

    case 'applyerrors': {
      const { errors } = action.payload

      return {
        ...state,
        errors,
      }
    }

    case userActionTypes.LOGOFF: {
      return initialState
    }

    default:
      return state
  }
}

export default user
