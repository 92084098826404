export const ReorderingIcon = ({
  width = 16,
  height = 16,
  bgColor = '#F2F4F5',
  color = '#222222',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_129_3290)">
        <rect
          x="0.799805"
          y="1.6001"
          width="14.4"
          height="12.8"
          rx="4"
          fill={bgColor}
        />
        <path
          d="M4.8 6.8002C5.24183 6.8002 5.6 6.44202 5.6 6.0002C5.6 5.55837 5.24183 5.2002 4.8 5.2002C4.35818 5.2002 4 5.55837 4 6.0002C4 6.44202 4.35818 6.8002 4.8 6.8002Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          d="M7.99997 6.8002C8.44182 6.8002 8.8 6.44202 8.8 6.0002C8.8 5.55837 8.44182 5.2002 7.99997 5.2002C7.55815 5.2002 7.2 5.55837 7.2 6.0002C7.2 6.44202 7.55815 6.8002 7.99997 6.8002Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          d="M11.2 6.8002C11.6418 6.8002 12 6.44202 12 6.0002C12 5.55837 11.6418 5.2002 11.2 5.2002C10.7581 5.2002 10.4 5.55837 10.4 6.0002C10.4 6.44202 10.7581 6.8002 11.2 6.8002Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          d="M4.79997 10.8002C5.24182 10.8002 5.6 10.442 5.6 10.0002C5.6 9.55837 5.24182 9.2002 4.79997 9.2002C4.35815 9.2002 4 9.55837 4 10.0002C4 10.442 4.35815 10.8002 4.79997 10.8002Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          d="M7.99997 10.8002C8.44182 10.8002 8.8 10.442 8.8 10.0002C8.8 9.55837 8.44182 9.2002 7.99997 9.2002C7.55815 9.2002 7.2 9.55837 7.2 10.0002C7.2 10.442 7.55815 10.8002 7.99997 10.8002Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          d="M11.2 10.8002C11.6418 10.8002 12 10.442 12 10.0002C12 9.55837 11.6418 9.2002 11.2 9.2002C10.7582 9.2002 10.4 9.55837 10.4 10.0002C10.4 10.442 10.7582 10.8002 11.2 10.8002Z"
          fill={color}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_129_3290">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
