import { useEffect } from 'react';
import CreditCard from './CreditCard';
import Pix from './Pix';

const Body = ({
  paymentType,
  qtym,
  checkoutConfig,
  parcelConfig,
  loadingParcelConfig,
  loadingPost,
}) => {
  return (
    <>
      {paymentType === 'cartao_credito' ? (
        <CreditCard
          qtym={qtym}
          checkoutConfig={checkoutConfig}
          parcelConfig={parcelConfig}
          loadingParcelConfig={loadingParcelConfig}
          loadingPost={loadingPost}
        />
      ) : (
        <Pix
          qtym={qtym}
          loadingPost={loadingPost}
          checkoutConfig={checkoutConfig}
        />
      )}
    </>
  );
};

export default Body;
