import { useSelector, useDispatch } from 'react-redux'
import NotAllowed from '../../pages/admin/NotAllowed'

import { useEffect } from 'react'

import * as notificationActions from './../../actions/notificationActions'

import {
  hasPermissionSelector,
  permissionSelector,
} from '../../services/selectors'

const AllowedComponent = ({ permission, redirect = false, children }) => {
  const sanitPermission =
    permission.split('/').length === 1 ? `${permission}/index` : permission

  const dispatch = useDispatch()

  const { allowed, permissions } = useSelector((state) => ({
    allowed: hasPermissionSelector(state, sanitPermission),
    permissions: permissionSelector(state),
  }))

  const wl = ['home/index', 'user/index', 'user/add', 'user/edit']
  if (allowed) {
    return <>{children}</>
  }

  return redirect ? <NotAllowed /> : ''
}

export default AllowedComponent
