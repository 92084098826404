import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Select from './Select'
import * as listsActions from '../../actions/listsActions'

const PerfilSelectId = ({ label = 'Perfil', name, errors = {} }) => {
  const items = useSelector((state) => state.lists.perfil || [])
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const getItems = () =>
    dispatch(
      listsActions.getLists(`/usuarios/${user.uuid}/perfis`, {
        list: 'perfil',
        arg: '',
      })
    )

  useEffect(() => {
    getItems()
  }, [])

  return (
    <Select errors={errors} label={label} name={name}>
      <option value=''>-- Selecione --</option>
      {items.map((item) => (
        <option key={item.id} value={item.id}>
          {item.nome}
        </option>
      ))}
    </Select>
  )
}

export default PerfilSelectId
