//variações de botões
export const BUTTON_VARIANTS = {
  primary: {
    //estilos default
    bgcolor: '#00B2FF',
    color: '#fafafa',
    border: '2px solid transparent',
    //estilos de hover
    hoverBgColor: '#008AD4',
    hoverColor: '#fafafa',
    hoverBorder: '2px solid transparent',
    //estilos ao clicar
    activeBgColor: '#006DAB',
    activeColor: '#fafafa',
    activeBorder: '2px solid transparent',
    //estilos desativado
    disabledBgColor: '#F2F5F5',
    disabledColor: 'rgba(34, 34, 34, 0.24)',
    disabledBorder: '2px solid transparent',
  },
  secondary: {
    //estilos default
    bgcolor: '#FFF',
    color: '#00B2FF',
    border: '2px solid #00B2FF',
    //estilos de hover
    hoverBgColor: '#EFF9FF',
    hoverColor: '#00B2FF',
    hoverBorder: '2px solid #00B2FF',
    //estilos ao clica
    activeBgColor: '#DEF2FF',
    activeColor: '#00B2FF',
    activeBorder: '2px solid #00B2FF',
    //estilos desativado
    disabledBgColor: '#F2F5F5',
    disabledColor: 'rgba(34, 34, 34, 0.24)',
    disabledBorder: '2px solid rgba(34, 34, 34, 0.20)',
  },
  tertiary: {
    //estilos default
    bgcolor: '#FFF',
    color: '#00B2FF',
    border: '2px solid transparent',
    //estilos de hover
    hoverBgColor: '#EFF9FF',
    hoverColor: '#00B2FF',
    hoverBorder: '2px solid transparent',
    //estilos ao clicar
    activeBgColor: '#DEF2FF',
    activeColor: '#00B2FF',
    activeBorder: '2px solid transparent',
    //estilos desativado
    disabledBgColor: 'transparent',
    disabledColor: 'rgba(34, 34, 34, 0.24)',
    disabledBorder: '2px solid transparent',
  },
}
// Size variations
export const SIZE_STYLES = {
  large: {
    fontSize: '16px',
    padding: '12px',
    height: '48px',
    minWidth: '48px',
  },
  medium: {
    fontSize: '14px',
    padding: '8px',
    height: '40px',
    minWidth: '40px',
  },
  small: {
    fontSize: '12px',
    padding: '4px',
    height: '28px',
    minWidth: '28px',
  },
}
