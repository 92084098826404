export const ScanPerson = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6192_93196)">
        <path
          d="M6.85525 3.71328C5.11569 3.71328 3.71328 5.11569 3.71328 6.85525V9.13203C3.71328 9.66015 3.28515 10.0883 2.75703 10.0883C2.22891 10.0883 1.80078 9.66015 1.80078 9.13203V6.85525C1.80078 4.05944 4.05944 1.80078 6.85525 1.80078H9.13203C9.66015 1.80078 10.0883 2.22891 10.0883 2.75703C10.0883 3.28515 9.66015 3.71328 9.13203 3.71328H6.85525Z"
          fill={color}
        />
        <path
          d="M13.9133 2.75703C13.9133 2.22891 14.3414 1.80078 14.8695 1.80078H17.1463C19.9421 1.80078 22.2008 4.05944 22.2008 6.85525V9.13203C22.2008 9.66015 21.7727 10.0883 21.2445 10.0883C20.7164 10.0883 20.2883 9.66015 20.2883 9.13203V6.85525C20.2883 5.11569 18.8859 3.71328 17.1463 3.71328H14.8695C14.3414 3.71328 13.9133 3.28515 13.9133 2.75703Z"
          fill={color}
        />
        <path
          d="M21.2445 13.9133C21.7727 13.9133 22.2008 14.3414 22.2008 14.8695V16.4633C22.2008 19.637 19.637 22.2008 16.4633 22.2008H14.8695C14.3414 22.2008 13.9133 21.7727 13.9133 21.2445C13.9133 20.7164 14.3414 20.2883 14.8695 20.2883H16.4633C18.5808 20.2883 20.2883 18.5808 20.2883 16.4633V14.8695C20.2883 14.3414 20.7164 13.9133 21.2445 13.9133Z"
          fill={color}
        />
        <path
          d="M2.75703 13.9133C3.28515 13.9133 3.71328 14.3414 3.71328 14.8695V17.1463C3.71328 18.8859 5.11569 20.2883 6.85525 20.2883H9.13203C9.66015 20.2883 10.0883 20.7164 10.0883 21.2445C10.0883 21.7727 9.66015 22.2008 9.13203 22.2008H6.85525C4.05944 22.2008 1.80078 19.9421 1.80078 17.1463V14.8695C1.80078 14.3414 2.22891 13.9133 2.75703 13.9133Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.49453 9.13491C8.49453 7.20052 10.0574 5.62578 12.0008 5.62578C13.937 5.62578 15.507 7.19912 15.507 9.13491L15.507 9.13889C15.4992 11.0283 14.01 12.5743 12.1248 12.6377C12.0839 12.6391 12.0429 12.6379 12.0022 12.634L12.0001 12.634C11.9952 12.634 11.9898 12.6343 11.9898 12.6343C11.9503 12.6379 11.9106 12.6391 11.871 12.6377C9.9865 12.5743 8.49453 11.0282 8.49453 9.13491ZM12.0008 7.53828C11.1185 7.53828 10.407 8.25193 10.407 9.13491C10.407 9.98683 11.0632 10.6741 11.8923 10.7243C11.9616 10.7207 12.0336 10.7204 12.1049 10.7242C12.9321 10.6734 13.59 9.98718 13.5945 9.13291C13.5935 8.25225 12.8781 7.53828 12.0008 7.53828Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.005 13.2758C13.1315 13.2758 14.3121 13.4975 15.2545 13.9954L14.8078 14.8409L15.2531 13.9946C16.0873 14.4336 16.782 15.165 16.782 16.1412C16.782 17.1164 16.0883 17.8489 15.2551 18.29C14.3102 18.7901 13.1282 19.0133 12.0008 19.0133C10.8733 19.0133 9.69131 18.7901 8.74643 18.29C7.91317 17.851 7.21953 17.1199 7.21953 16.1445C7.21953 15.1693 7.91313 14.4369 8.74626 13.9958L8.7496 13.994C9.69605 13.4977 10.8781 13.2758 12.005 13.2758ZM9.63953 15.6869C9.39153 15.8184 9.25641 15.9443 9.19006 16.0311C9.15786 16.0733 9.14376 16.1035 9.13774 16.12C9.13204 16.1356 9.13203 16.1445 9.13203 16.1445C9.13203 16.1445 9.13203 16.1532 9.13762 16.1685C9.14351 16.1846 9.15742 16.2145 9.18936 16.2562C9.25535 16.3423 9.39034 16.4678 9.63903 16.5986L9.64102 16.5997C10.2385 16.9159 11.0923 17.1008 12.0008 17.1008C12.9091 17.1008 13.7629 16.9159 14.3604 16.5998C14.6093 16.4679 14.745 16.3416 14.8115 16.2546C14.8437 16.2125 14.8578 16.1822 14.8638 16.1657C14.8695 16.1501 14.8695 16.1412 14.8695 16.1412C14.8695 16.1412 14.8695 16.1325 14.8639 16.1172C14.8581 16.1011 14.8441 16.0712 14.8122 16.0295C14.7462 15.9434 14.6112 15.818 14.3625 15.6871L14.3612 15.6864C13.7668 15.3724 12.9143 15.1883 12.005 15.1883C11.0969 15.1883 10.2409 15.372 9.63953 15.6869Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6192_93196">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
