import searchActionTypes from '../actionTypes/search'
import userActionTypes from './../actionTypes/user'


const initialState = {
    results: null,
    loading: false

}

const search = (state = initialState, action) => {
    switch (action.type) {

        case searchActionTypes.SET_RESULTS: {

            const { results } = action.payload
            return {
                ...state,
                results,                
            }
        }

        case searchActionTypes.SET_LOADING: {

            const { flag } = action.payload
            return {
                ...state,
                loading: flag
            }
        }

        case searchActionTypes.CLEAR: 
        case userActionTypes.LOGOFF:
        {

            return initialState
        }

        

        default:
            return state
    }
}

export default search