import { Stack, Typography, Link } from '@mui/material';
import { ArrowLeft } from 'TGComponents/icons';
import { useNavigate } from 'react-router-dom'; // Using react-router-dom v6

const TGHeading = ({
  title,
  description,
  backTo,
  goBack = false,
  fontSizeTitle = '32px',
}) => {
  const navigate = useNavigate();

  const handleGoBack = (e) => {
    if (goBack) {
      e.preventDefault();
      navigate(-1); // Go back one page
    }
  };

  return (
    <Stack gap={['20px', '16px']}>
      <Link
        href={goBack ? '#' : backTo} // Disable href when goBack is true
        onClick={handleGoBack} // Handle back action
        sx={{
          'display': 'inline-flex',
          'textDecoration': 'none',
          'alignItems': 'center',
          'justifyContent': 'center',
          'width': '100px',
          'gap': '5px',
          'padding': '4px 8px',
          '&:hover': {
            bgcolor: '#F2F9FC',
            borderRadius: '4px',
            textDecoration: 'none',
          },
        }}
      >
        <ArrowLeft size={12} />
        <Typography color='#555555' fontSize={'16px'} lineHeight={'24px'}>
          Voltar
        </Typography>
      </Link>

      <Typography
        variant='h5'
        fontWeight={600}
        color='initial'
        fontSize={fontSizeTitle}
      >
        {title}
      </Typography>

      <Typography
        variant='body2'
        color='#555'
        mt={['40px', '0']}
        marginBottom={'20px'}
        fontSize={'16px'}
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default TGHeading;
