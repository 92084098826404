export const Refresh = ({ size = 24, color = "#222222" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_2426_47820)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.19922 12C1.19922 6.03527 6.03454 1.19995 11.9992 1.19995C17.9638 1.19995 22.7992 6.03527 22.7992 12C22.7992 17.9646 17.9638 22.7999 11.9992 22.7999C6.03454 22.7999 1.19922 17.9646 1.19922 12ZM11.9992 2.98344C7.01953 2.98344 2.9827 7.02027 2.9827 12C2.9827 16.9796 7.01954 21.0165 11.9992 21.0165C16.9789 21.0165 21.0157 16.9796 21.0157 12C21.0157 7.02027 16.9789 2.98344 11.9992 2.98344Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7439 11.7812C17.2313 11.8516 17.5694 12.3037 17.4991 12.7912C17.3354 13.925 16.8306 15.0485 15.9391 15.94L15.9374 15.9417C13.7575 18.1098 10.2316 18.1123 8.0593 15.94C7.76162 15.6423 7.49817 15.3075 7.28117 14.9458C7.02778 14.5235 7.16472 13.9757 7.58704 13.7224C8.00935 13.469 8.55711 13.6059 8.8105 14.0282C8.9502 14.2611 9.12268 14.4811 9.32042 14.6789C10.7941 16.1525 13.1927 16.1552 14.6789 14.678C15.2731 14.0833 15.6201 13.3244 15.7339 12.5364C15.8042 12.049 16.2564 11.7109 16.7439 11.7812Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.96577 14.4869C6.96577 13.9944 7.36502 13.5952 7.85752 13.5952H10.503C10.9955 13.5952 11.3947 13.9944 11.3947 14.4869C11.3947 14.9794 10.9955 15.3787 10.503 15.3787H8.74926V17.1324C8.74926 17.6249 8.35001 18.0242 7.85752 18.0242C7.36502 18.0242 6.96577 17.6249 6.96577 17.1324V14.4869Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.29209 12.1987C6.80464 12.1284 6.46652 11.6762 6.53688 11.1888C6.70052 10.055 7.20534 8.93144 8.09681 8.03998L8.09852 8.03828C10.2785 5.87013 13.8043 5.86768 15.9766 8.03998C16.2743 8.33766 16.5378 8.67247 16.7548 9.03413C17.0082 9.45644 16.8712 10.0042 16.4489 10.2576C16.0266 10.511 15.4788 10.374 15.2254 9.95172C15.0857 9.71889 14.9133 9.49883 14.7155 9.3011C13.2419 7.82745 10.8432 7.82472 9.35705 9.30196C8.76281 9.89661 8.41581 10.6555 8.30207 11.4435C8.23172 11.931 7.77953 12.2691 7.29209 12.1987Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.1409 5.9758C16.6334 5.9758 17.0326 6.37505 17.0326 6.86754V9.51303C17.0326 10.0055 16.6334 10.4048 16.1409 10.4048H13.4954C13.0029 10.4048 12.6036 10.0055 12.6036 9.51303C12.6036 9.02053 13.0029 8.62128 13.4954 8.62128H15.2491V6.86754C15.2491 6.37505 15.6484 5.9758 16.1409 5.9758Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_2426_47820">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
