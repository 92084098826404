import listsActionTypes from '../actionTypes/lists'



const initialState = {    
    loading: false
}

const lists = (state = initialState, action) => {
    switch (action.type) {

        case listsActionTypes.SET_LISTS: {

            const { data } = action.payload
            return {
                ...state,
                [data.list]: data.items,
            }
        }

        case listsActionTypes.SET_LOADING: {

            const { list } = action.payload
            return {
                ...state,
                loading: list
            }
        }

        case listsActionTypes.CLEAR_LIST: {

            const { list } = action.payload

            const newList = {...state}
            delete newList[list]
            return newList
        }

        case listsActionTypes.CLEAR_ALL:         
        {
            const { partOfLabel } = action.payload

            if(partOfLabel){

                const newList = {...state}

                Object.keys(newList).forEach((key, _) => {
                    if(key.startsWith(partOfLabel)){
                        delete newList[key]
                    }
                })

                return newList


            }

            return initialState
        }

        

        default:
            return state
    }
}

export default lists