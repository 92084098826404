import notificationsActionTypes from '../actionTypes/notifications'

export const success = (message, redirectTo = null) => ({
    type: notificationsActionTypes.SUCCESS,
    payload: { message, redirectTo }
})

export const warning = (message, redirectTo = null) => ({
    type: notificationsActionTypes.WARNING,
    payload: { message, redirectTo }
})

export const info = (message, redirectTo = null) => ({
    type: notificationsActionTypes.INFO,
    payload: { message, redirectTo }
})

export const error = (message, redirectTo = null) => ({
    type: notificationsActionTypes.ERROR,
    payload: { message, redirectTo }
})

export const clear = () => ({
    type: notificationsActionTypes.CLEAR,
})

export const clearRedirect = () => ({
    type: notificationsActionTypes.CLEAR_REDIRECT,
})

