import React from 'react'
import TGFilter from 'TGComponents/global/TGFilter'
import { Box, styled } from '@mui/system'
import TGSimpleSlider from './TGSimpleSlider'

const ChipsContainer = styled(Box)({
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
})

const TGFilterComponent = ({
  filterItems,
  selectedFilters,
  handleFilterClick,
  updateSortOption,
  mb = '24px',
  pl,
  pr,
  marginLeft,
  loading,
  widthContainerFilter,
  display,
  gridColumns,
  gap,
  filterDisabled,
  bgColor = null,
  textColor = null,
  displaySlider,
  colorDefault = '#222',
}) => {
  return (
    <TGSimpleSlider pb={['0px', '0px', '0px']} displaySlider={displaySlider}>
      <Box
        sx={{ marginLeft: marginLeft ? marginLeft : 'auto' }}
        width={['initial', 'initial', widthContainerFilter || '100%']}
      >
        <ChipsContainer
          sx={{
            mb: mb,
            pl: pl,
            pr: pr,
            gap: gap,
            display: display,
            gridTemplateColumns: gridColumns,
          }}
        >
          {filterItems.map((filter, index) => (
            <TGFilter
              borderRadius='480px'
              loading={loading}
              key={index}
              label={filter.label}
              moreOptions={filter.moreOptions}
              onAction={() => filter.onAction(filter.label)}
              orderOptions={
                filter.label === 'Ordenar' ? ['recentes', 'antigos'] : []
              }
              filterDisabled={filterDisabled}
              variant={
                filter.label === 'Ordenar'
                  ? ''
                  : selectedFilters.includes(filter.label)
                  ? 'filled'
                  : 'outlined'
              }
              updateSortOption={updateSortOption}
              bgColor={bgColor}
              textColor={textColor}
              colorDefault={colorDefault}
            />
          ))}
        </ChipsContainer>
      </Box>
    </TGSimpleSlider>
  )
}

export default TGFilterComponent
