import { useField } from 'formik'

import { useFormikContext } from 'formik'
import { Typography } from '@mui/material'

import flatfy from '../../utils/flatfy'
import TGTextField from 'TGComponents/global/TGTextField'

const Currency = ({
  label,
  separator = '.',
  errors = {},
  mask = null,
  places = 2,
  disabled = false,
  ...props
}) => {
  const errTag = props.errTag || props.name

  const { values, handleChange, setFieldValue } = useFormikContext()

  const [field] = useField(props)

  const hasErrors = flatfy(errors, props.name) || false

  return (
    <>
      {label ? (
        <Typography fontSize='12px' color='#555' mb='4px' htmlFor={props.id}>
          {label}
        </Typography>
      ) : (
        ''
      )}
      <TGTextField
        placeholder={props.placeholder}
        className={`form-control ${hasErrors ? 'is-invalid' : ''}`}
        autoComplete='off'
        disabled={disabled}
        {...field}
        onChange={(e) => {
          handleChange(e)

          let val = parseFloat(e.target.value.replace(/[^0-9]/, '') || 0)
          if (places === 2) {
            val = parseFloat(e.target.value.replace(/[^0-9]/, '') || 0) / 100
          }

          if (separator === '.') {
            setFieldValue(props.name, val.toFixed(places))
          } else {
            setFieldValue(props.name, val.toFixed(places).replace('.', ','))
          }
        }}
      />
      <div className='invalid-feedback'>{(hasErrors || [])?.join(' ')}</div>
    </>
  )
}

export default Currency
