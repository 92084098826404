export const BulletPoint = ({ size = 24, color }) => {
  return (
    <svg
      width='100'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
        fill='#53A462'
      />
      <g clip-path='url(#clip0_5479_112622)'>
        <path
          d='M11.5484 15.92C11.5484 13.5003 13.5 11.5486 15.9198 11.5486H18.8341C19.3169 11.5486 19.7084 11.1572 19.7084 10.6743C19.7084 10.1915 19.3169 9.80005 18.8341 9.80005H15.9198C12.5343 9.80005 9.7998 12.5346 9.7998 15.92V18.8343C9.7998 19.3172 10.1912 19.7086 10.6741 19.7086C11.1569 19.7086 11.5484 19.3172 11.5484 18.8343V15.92Z'
          fill='#FAFAFA'
        />
        <path
          d='M22.3312 9.80005C21.8484 9.80005 21.4569 10.1915 21.4569 10.6743C21.4569 11.1572 21.8484 11.5486 22.3312 11.5486H24.8292C26.7127 11.5486 28.4512 13.369 28.4512 15.92V18.8343C28.4512 19.3172 28.8427 19.7086 29.3255 19.7086C29.8084 19.7086 30.1998 19.3172 30.1998 18.8343V15.92C30.1998 12.6659 27.9216 9.80005 24.8292 9.80005H22.3312Z'
          fill='#FAFAFA'
        />
        <path
          d='M29.3255 21.4572C29.8084 21.4572 30.1998 21.8486 30.1998 22.3315V24.0801C30.1998 27.4655 27.4653 30.2001 24.0798 30.2001H22.3312C21.8484 30.2001 21.4569 29.8086 21.4569 29.3258C21.4569 28.8429 21.8484 28.4515 22.3312 28.4515H24.0798C26.4996 28.4515 28.4512 26.4998 28.4512 24.0801V22.3315C28.4512 21.8486 28.8427 21.4572 29.3255 21.4572Z'
          fill='#FAFAFA'
        />
        <path
          d='M11.5484 22.3315C11.5484 21.8486 11.1569 21.4572 10.6741 21.4572C10.1912 21.4572 9.7998 21.8486 9.7998 22.3315V24.8294C9.7998 27.9218 12.6656 30.2001 15.9198 30.2001H18.8341C19.3169 30.2001 19.7084 29.8086 19.7084 29.3258C19.7084 28.8429 19.3169 28.4515 18.8341 28.4515H15.9198C13.3687 28.4515 11.5484 26.713 11.5484 24.8294V22.3315Z'
          fill='#FAFAFA'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M25.5369 19.1258V17.8806C25.5369 16.8754 25.2567 15.9673 24.5513 15.3261C23.8618 14.6993 22.9128 14.4629 21.8649 14.4629H18.1347C17.0868 14.4629 16.1378 14.6993 15.4483 15.3261C14.7429 15.9673 14.4627 16.8754 14.4627 17.8806V19.1258H13.0055C12.5227 19.1258 12.1312 19.5172 12.1312 20C12.1312 20.4829 12.5227 20.8743 13.0055 20.8743H14.4627V22.1195C14.4627 23.1247 14.7429 24.0328 15.4483 24.674C16.1378 25.3008 17.0868 25.5372 18.1347 25.5372H21.8649C22.9128 25.5372 23.8618 25.3008 24.5513 24.674C25.2567 24.0328 25.5369 23.1247 25.5369 22.1195V20.8743H26.9941C27.4769 20.8743 27.8684 20.4829 27.8684 20C27.8684 19.5172 27.4769 19.1258 26.9941 19.1258H25.5369ZM16.6245 16.62C16.3973 16.8265 16.2112 17.1902 16.2112 17.8806V19.1258H23.7884V17.8806C23.7884 17.1902 23.6023 16.8265 23.3751 16.62C23.1321 16.399 22.6823 16.2115 21.8649 16.2115H18.1347C17.3173 16.2115 16.8675 16.399 16.6245 16.62ZM23.7884 20.8743H16.2112V22.1195C16.2112 22.8099 16.3973 23.1736 16.6245 23.3801C16.8675 23.6011 17.3173 23.7886 18.1347 23.7886H21.8649C22.6823 23.7886 23.1321 23.6011 23.3751 23.3801C23.6023 23.1736 23.7884 22.8099 23.7884 22.1195V20.8743Z'
          fill='#FAFAFA'
        />
      </g>
      <defs>
        <clipPath id='clip0_5479_112622'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
