import { takeEvery, put, takeLatest, call } from 'redux-saga/effects'
import Requester from '../services/requester'

import * as crudActions from '../actions/crudActions'
import * as notificationActions from '../actions/notificationActions'

const _get = (endpoint, criteria) => Requester(`${endpoint}${criteria !== "" ? '?' + criteria : ''}`, {
    method: 'GET'    
})

const _insert = (endpoint, data) => Requester(`${endpoint}`, {
    method: 'POST',
    body: data
})

const _update = (endpoint, data) => Requester(`${endpoint}`, {
    method: 'PUT',
    body: data
})

const _delete = (endpoint, data) => Requester(`${endpoint}`, {
    method: 'DELETE',
    body: data
})


export function* getSearch(action) {

    yield put(crudActions.setLoading(true))

    try {
        const { endpoint, criteria } = action.payload

        let buildCriteria = criteria ? Object.keys(criteria).map(c => `${c}=${criteria[c]}`).join("&") : false

        const result = yield call(_get, endpoint, buildCriteria)

        if (result.status) {
            
            const lists = result.lists || []
            if(result.data !== false){

                yield put(crudActions.applyData(result.data))
            }

            yield put(crudActions.applyLists(lists))

        }
        else {
            yield put(notificationActions.warning('Não foi encontrado resultados'));
        }
    } catch (error) {
        console.log(error)
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'));
    }
    finally {
        yield put(crudActions.setLoading(false))
    }
}

export function* insert(action) {

    yield put(crudActions.setLoading(true))

    try {
        const { endpoint, data, redirectTo } = action.payload

        const result = yield call(_insert, endpoint, data)

        if (result.status) {
            const data = result.data

            //yield put(crudActions.applyData(data))
            yield put(crudActions.applyErrors(null))
            yield put(notificationActions.success('Registro inserido com sucesso', redirectTo));

        }
        else {

            const errors = result.errors
            yield put(crudActions.applyErrors(errors))

            yield put(notificationActions.error('Não foi possível gravar o registro. Verifique os dados e tente novamente'));
        }
    } catch (error) {
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'));
    }
    finally {
        yield put(crudActions.setLoading(false))
    }
}

export function* update(action) {

    yield put(crudActions.setLoading(true))

    try {
        const { endpoint, data, redirectTo } = action.payload

        const result = yield call(_update, endpoint, data)

        if (result.status) {
            const data = result.data

            //yield put(crudActions.applyData(data))
            yield put(crudActions.applyErrors(null))
            yield put(notificationActions.success('Registro atualizado com sucesso', redirectTo));

        }
        else {
            const errors = result.errors
            yield put(crudActions.applyErrors(errors))
            yield put(notificationActions.error('Não foi possível alterar o registro. Verifique os dados e tente novamente'));
        }
    } catch (error) {
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'));
    }
    finally {
        yield put(crudActions.setLoading(false))
    }
}

export function* remove(action) {

    yield put(crudActions.setLoading(true))

    try {
        const { endpoint, data, redirectTo } = action.payload

        const result = yield call(_delete, endpoint, data)

        if (result.status) {

            //yield put(crudActions.applyData(data))
            yield put(notificationActions.success('Registro removido com sucesso', redirectTo));
            yield put(crudActions.applyErrors(null))

        }
        else {
            const errors = result.errors
            yield put(crudActions.applyErrors(errors))
            yield put(notificationActions.error('Não foi possível remover o registro. Verifique os dados e tente novamente'));
        }
    } catch (error) {
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte'));
    }
    finally {
        yield put(crudActions.setLoading(false))
    }
}




