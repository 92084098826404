import addressActionTypes from "../actionTypes/address";

export const getAddress = (cep) => ({
    type: addressActionTypes.GET_ADDRESS, 
    payload: { cep }    
})

export const setAddress = (address) => ({
    type: addressActionTypes.SET_ADDRESS, 
    payload: { address }    
})

export const setLoading = (flag) => ({
    type: addressActionTypes.SET_LOADING, 
    payload: { flag }    
})

export const clear = () => ({
    type: addressActionTypes.CLEAR,     
})