import React from "react";

import { Container } from "./styles";

export const Badge = ({ title, type, ...rest }) => {
  return (
    <>
      <Container type={type} {...rest}>
        {title}
      </Container>
    </>
  );
};
