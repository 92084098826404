import React from 'react'
import { useField, Field } from 'formik'
import { useFormikContext } from 'formik'
import InputMask from 'react-input-mask'
import { Typography, Box, Skeleton } from '@mui/material'

const TGTextField = ({
  label,
  errors = {},
  hint,
  onBlur,
  loading = false,
  flex = 1,
  mask = null,
  ...props
}) => {
  const { touched, setFieldValue } = useFormikContext()

  const [field, meta] = useField(props)

  const hasErrors = meta && meta.touched && meta.error

  const handleBlur = (event) => {
    field.onBlur(event)
    if (onBlur) {
      onBlur(event, setFieldValue)
    }
  }

  return (
    <Box flex={flex}>
      {label?.length ? (
        <Typography
          fontSize='12px'
          color='#555'
          mb='4px'
          htmlFor={props.id || props.name}
        >
          {label}
        </Typography>
      ) : (
        ''
      )}
      {loading ? (
        <Skeleton
          variant='text'
          width='100%'
          animation='wave'
          height='81.7px'
        />
      ) : (
        <Field
          style={{
            height: '49px',
          }}
          as={InputMask}
          mask={mask || ''}
          maskChar=''
          className={`form-control ${hasErrors ? 'is-invalid' : ''}`}
          autoComplete='off'
          onBlur={handleBlur}
          {...field}
          {...props}
        />
      )}
      <Box className='invalid-feedback'>
        {hasErrors && <span>{meta.error}</span>}
      </Box>
      {hint && (
        <Box
          fontSize='10px'
          color='#999'
          mt='4px'
          sx={{ a: { color: '#555' } }}
          dangerouslySetInnerHTML={{ __html: hint }}
        />
      )}
    </Box>
  )
}

export default TGTextField
