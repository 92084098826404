import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Select from './Select'
import * as listsActions from '../../actions/listsActions'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Typography } from '@mui/material'
import './EventSelect.css'
const EventSelect = ({
  label = 'Tipo de evento',
  name,
  errors = {},
  boComponenteNovo = false, //Na homologação trocar o valor para true
  boUsuarioVariasProdutoras = 0,
  ...props
}) => {
  const items = useSelector((state) => state.lists.events || [])
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const getItems = () =>
    dispatch(
      listsActions.getLists(`/usuarios/${user.uuid}/eventos`, {
        list: 'events',
        arg: [
          '?boUsuarioVariasProdutoras=' + (user.boUsuarioVariasProdutoras ? 1 : 0),
        ],
      })
    )

  useEffect(() => {
    getItems()
  }, [])
  const options = items.map(function (item) {
    return { label: item.nome, id: item.uuid }
  })

  return !boComponenteNovo ? (
    <Select
      errors={errors}
      label={label}
      name={name}
      {...props}
      style={{ height: '49px' }}
    >
      <option value=''>Selecione</option>
      {items.map((item) => (
        <option key={item.uuid} value={item.uuid}>
          {item.nome}
        </option>
      ))}
    </Select>
  ) : (
    <>
      {label ? (
        <Typography
          fontSize='12px'
          color='#555'
          mb='4px'
          htmlFor={props.id || props.name}
        >
          {label}
        </Typography>
      ) : (
        ''
      )}
      <Autocomplete
        disablePortal
        errors={errors}
        options={options}
        noOptionsText={'Carregando...'}
        name={name}
        {...props}
        sx={{ width: 255 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Selecione'
            className={`form-select  'form-select-normal'`}
          />
        )}
      />
    </>
  )
}

export default EventSelect
