import { useRef, useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './../../utils/canvasUtils'
import TGButton from 'TGComponents/global/TGButton'

const Crop = ({
  handleCrop,
  label,
  cropTo = { width: 0, height: 0 },
  defaultImage = null,
}) => {
  const flinput = useRef(null)
  const [imageSrc, setImageSrc] = useState(defaultImage)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 })
  const [isCropped, setIsCropped] = useState(defaultImage ? true : false)
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  //const [croppedImage, setCroppedImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const loadFile = (e) => {
    let reader = new FileReader()

    let img = new Image()
    img.onload = (e) => {
      setOriginalSize({
        width: img.width,
        height: img.height,
      })
    }

    reader.readAsDataURL(e.target.files[0])

    reader.onload = function () {
      setImageSrc(reader.result)
      img.src = reader.result
    }
  }

  const showCroppedImage = useCallback(async () => {
    setLoading(true)
    try {
      const width =
        cropTo.width > 0 && cropTo.width < originalSize.width
          ? cropTo.width
          : originalSize.width
      const height = cropTo.height > 0 ? cropTo.height : width / 1.7776

      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        { width, height }
      )

      setLoading(false)
      handleCrop(croppedImage)
      setIsCropped(true)
      setImageSrc(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation, cropTo, originalSize])

  const changePic = useCallback(() => {
    //output(null)

    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotation(0)
    setCroppedAreaPixels(null)
  }, [])

  return (
    <>
      <label className='form-label'>{label}</label>
      <div
        style={{
          position: 'relative',
          backgroundColor: '#FBFAFA',
          width: '100%',
          minHeight: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '5px',
          marginBottom: '80px',
        }}
      >
        {loading ? (
          <h3>Processando imagem ...</h3>
        ) : (
          <>
            {imageSrc || defaultImage ? (
              isCropped || defaultImage ? (
                <div className='d-flex flex-column p-3 align-items-center'>
                  <img
                    src={imageSrc || defaultImage}
                    className='img-fluid mb-2'
                    alt='Imagem recortada'
                  />
                  <TGButton
                    type='button'
                    onClick={() => {
                      setImageSrc(null)
                      setIsCropped(false)
                      handleCrop('')
                    }}
                  >
                    Trocar imagem
                  </TGButton>
                </div>
              ) : (
                <>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={0}
                    zoom={zoom}
                    aspect={
                      cropTo.width > 0 && cropTo.height > 0
                        ? cropTo.width / cropTo.height
                        : 16 / 9
                    }
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '-50px',
                      left: 0,
                      margin: 0,
                      width: '100%',
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TGButton
                      height='40px'
                      type='button'
                      fontSize='0.75rem'
                      onClick={() => {
                        setImageSrc(null)
                        setIsCropped(false)
                        handleCrop('')
                      }}
                    >
                      Nova imagem
                    </TGButton>
                    <TGButton
                      height='40px'
                      fontSize='0.75rem'
                      onClick={showCroppedImage}
                    >
                      Recortar imagem
                    </TGButton>
                  </div>
                </>
              )
            ) : (
              <TGButton
                type='button'
                className='btn btn-azul-medio'
                onClick={() => flinput?.current?.click()}
              >
                Escolher imagem
              </TGButton>
            )}
            <input
              type='file'
              style={{ display: 'none' }}
              ref={flinput}
              accept='image/png, image/jpeg'
              onChange={loadFile}
            />
          </>
        )}
      </div>
    </>
  )
}

export default Crop
