import * as yup from 'yup'
import { validarCPF } from './../validations/cpf'
import { validarCNPJ } from './../validations/cnpj'

const Required = (label) =>
  yup.string().required(`O campo ${label} é obrigatório`)

export const TGValidations = {
  nome: Required('nome'),
  descricao: Required('descrição'),
  email: Required('e-mail').test(
    'is-valid-email',
    'Por favor insira um e-mail válido',
    (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  ),
  telefone: Required('telefone'),
  cpf: Required('CPF').test(
    'is-valid-cpf',
    'Por favor insira um CPF válido',
    validarCPF
  ),

  cnpj: Required('cnpj').test(
    'is-valid-cnpj',
    'Por favor insira um CNPJ válido',
    validarCNPJ
  ),
  nascimento: Required('data de nascimento'),
  logradouro: Required('logradouro'),
  sexo: Required('gênero'),
  senha: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
      'A senha deve conter pelo menos 8 caracteres, 1 letra maiúscula e 1 caractere especial (!@#$%^&*)'
    ),
  nome_comercial: Required('nome comercial').max(
    60,
    'O nome comercial da sua empresa não pode ter mais de 60 caracteres'
  ),
  razao_social: Required('razão social'),
  cep: Required('cep'),
  numero: Required('numero'),
  cidade: Required('cidade'),
  uf: Required('Estado'),
  tipo_conta: Required('tipo de conta'),
  tipo_banco: Required('banco'),
  numero_agencia: Required('numero de agência'),
  numero_conta: Required('numero da conta'),
  tipo_chave: Required('tipo de chave'),
  chave_pix: Required('chave pix'),
  account_name: Required('O campo nome da conta'),
}
