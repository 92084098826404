export const Participants = ({ size = 24, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
    >
      <g clip-path="url(#clip0_4031_19146)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.19063 6.8568C4.19063 4.30375 6.25819 2.2168 8.83062 2.2168C11.4 2.2168 13.4706 4.28739 13.4706 6.8568C13.4706 9.36991 11.4922 11.406 9.00741 11.496C8.9506 11.4981 8.89376 11.4961 8.83729 11.4901C8.78083 11.4961 8.72398 11.4981 8.66718 11.496C6.18088 11.4059 4.20855 9.37032 4.19066 6.86537L4.19063 6.8568ZM6.59063 6.85252C6.60137 8.05154 7.51941 9.01075 8.67466 9.09331C8.78243 9.08462 8.89218 9.08462 8.99994 9.09331C10.1577 9.01066 11.0706 8.04986 11.0706 6.8568C11.0706 5.61287 10.0746 4.6168 8.83062 4.6168C7.59116 4.6168 6.59293 5.62085 6.59063 6.85252Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6306 2.2168C22.0612 2.2168 19.9906 4.28739 19.9906 6.8568C19.9906 9.36991 21.969 11.406 24.4538 11.496C24.5106 11.4981 24.5675 11.4961 24.624 11.4901C24.6804 11.4961 24.7373 11.4981 24.7941 11.496C27.2804 11.4059 29.2527 9.37032 29.2706 6.86537L29.2706 6.8568C29.2706 4.30375 27.2031 2.2168 24.6306 2.2168ZM22.3906 6.8568C22.3906 5.61287 23.3867 4.6168 24.6306 4.6168C25.8701 4.6168 26.8683 5.62085 26.8706 6.85252C26.8599 8.05154 25.9418 9.01075 24.7866 9.09331C24.6788 9.08462 24.5691 9.08462 24.4613 9.09331C23.3035 9.01066 22.3906 8.04986 22.3906 6.8568Z"
          fill={color ?? "currentColor"}
        />
        <path
          d="M29.2945 13.4837C27.5634 12.3351 25.1978 11.9928 23.1306 12.3549C22.4778 12.4693 22.0413 13.0912 22.1557 13.744C22.2701 14.3968 22.892 14.8333 23.5448 14.7189C25.1304 14.4412 26.8442 14.7386 27.9665 15.4828C28.6554 15.9424 28.841 16.4293 28.841 16.7636C28.841 17.098 28.6552 17.5852 27.9654 18.0451L27.9632 18.0466C26.8555 18.7885 25.1678 19.0873 23.5764 18.8201C22.9228 18.7104 22.304 19.1513 22.1943 19.8049C22.0845 20.4585 22.5254 21.0773 23.179 21.187C25.2401 21.533 27.5779 21.1922 29.2967 20.042C30.4869 19.2485 31.241 18.0825 31.241 16.7636C31.241 15.4446 30.4869 14.2786 29.2967 13.4851L29.2945 13.4837Z"
          fill={color ?? "currentColor"}
        />
        <path
          d="M9.91502 14.7189C8.32938 14.4412 6.61561 14.7386 5.49331 15.4827C4.80441 15.9424 4.61875 16.4293 4.61875 16.7636C4.61875 17.098 4.80461 17.5852 5.49439 18.0451L5.49656 18.0466C6.60425 18.7885 8.29199 19.0873 9.8834 18.8201C10.537 18.7104 11.1558 19.1513 11.2655 19.8049C11.3752 20.4585 10.9344 21.0773 10.2808 21.187C8.21927 21.5331 5.8809 21.1921 4.16202 20.0413C2.97243 19.2478 2.21875 18.0821 2.21875 16.7636C2.21875 15.4446 2.97289 14.2786 4.16311 13.4851L4.16529 13.4836C5.89634 12.3351 8.26197 11.9928 10.3291 12.3549C10.9819 12.4693 11.4184 13.0912 11.3041 13.744C11.1897 14.3968 10.5678 14.8333 9.91502 14.7189Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9906 16.8168C11.9906 14.2474 14.0613 12.1768 16.6307 12.1768C19.2031 12.1768 21.2707 14.2638 21.2707 16.8168V16.8254C21.2528 19.3226 19.2863 21.3838 16.7854 21.4563C16.7315 21.4579 16.6776 21.4558 16.624 21.4501C16.5675 21.4561 16.5107 21.4581 16.4539 21.456C13.9691 21.366 11.9906 19.3299 11.9906 16.8168ZM16.6307 14.5768C15.3867 14.5768 14.3906 15.5729 14.3906 16.8168C14.3906 18.0098 15.3035 18.9707 16.4614 19.0533C16.571 19.0445 16.6826 19.0446 16.7922 19.0538C17.9351 18.9807 18.8599 18.0199 18.8707 16.8125C18.8684 15.5808 17.8701 14.5768 16.6307 14.5768Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.2951 23.4584C19.9951 22.5918 18.3424 22.1969 16.7544 22.1969C15.1661 22.1969 13.5117 22.5919 12.2066 23.4565L12.2037 23.4584C11.0135 24.2519 10.2594 25.4179 10.2594 26.7369C10.2594 28.0556 11.0132 29.2214 12.203 30.0149C13.5081 30.8873 15.1603 31.2869 16.7494 31.2869C18.3383 31.2869 19.9901 30.8875 21.2951 30.0153C22.4853 29.2219 23.2394 28.0558 23.2394 26.7369C23.2394 25.4179 22.4853 24.2519 21.2951 23.4584ZM21.2951 30.0153L20.6294 29.0169L21.2965 30.0144L21.2951 30.0153ZM12.6594 26.7369C12.6594 26.4027 12.845 25.9159 13.5336 25.4563C14.3618 24.9081 15.5267 24.5969 16.7544 24.5969C17.9831 24.5969 19.1437 24.9086 19.9638 25.4553C20.6536 25.9152 20.8394 26.4025 20.8394 26.7369C20.8394 27.0713 20.6536 27.5586 19.9638 28.0184L19.9623 28.0194C19.1341 28.5732 17.9733 28.8869 16.7494 28.8869C15.5255 28.8869 14.3647 28.5732 13.5364 28.0194L13.535 28.0184C12.8452 27.5586 12.6594 27.0713 12.6594 26.7369Z"
          fill={color ?? "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4031_19146">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.75 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
