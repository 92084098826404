export const Lock = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3752_55732)">
        <g clip-path="url(#clip1_3752_55732)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.40156 6.13523V5.33242C3.40156 4.1932 3.56798 3.01779 4.2843 2.12853C5.0232 1.21125 6.23221 0.732422 8.00156 0.732422C9.77092 0.732422 10.9799 1.21125 11.7188 2.12853C12.4351 3.01779 12.6016 4.1932 12.6016 5.33242V6.13537C13.351 6.23336 13.9706 6.45445 14.4253 6.9092C15.1161 7.6 15.2677 8.67108 15.2677 10.0001V11.3335C15.2677 12.6625 15.1161 13.7336 14.4253 14.4244C13.7345 15.1152 12.6634 15.2668 11.3344 15.2668H4.66771C3.33866 15.2668 2.26758 15.1152 1.57678 14.4244C0.885973 13.7336 0.734375 12.6625 0.734375 11.3335V10.0001C0.734375 8.67108 0.885973 7.6 1.57678 6.9092C2.03174 6.45424 2.65165 6.23316 3.40156 6.13523ZM4.60156 5.33242C4.60156 4.26498 4.76847 3.44039 5.21882 2.88131C5.64659 2.35026 6.43759 1.93242 8.00156 1.93242C9.56554 1.93242 10.3565 2.35026 10.7843 2.88131C11.2347 3.44039 11.4016 4.26498 11.4016 5.33242V6.06693C11.3792 6.06684 11.3568 6.0668 11.3344 6.0668H4.66771C4.64559 6.0668 4.62354 6.06684 4.60156 6.06692V5.33242ZM1.93438 10.0001C1.93438 8.66251 2.11611 8.06692 2.42531 7.75773C2.7345 7.44853 3.33009 7.2668 4.66771 7.2668H11.3344C12.672 7.2668 13.2676 7.44853 13.5768 7.75773C13.886 8.06692 14.0677 8.66251 14.0677 10.0001V11.3335C14.0677 12.6711 13.886 13.2667 13.5768 13.5759C13.2676 13.8851 12.672 14.0668 11.3344 14.0668H4.66771C3.33009 14.0668 2.7345 13.8851 2.42531 13.5759C2.11611 13.2667 1.93438 12.6711 1.93438 11.3335V10.0001Z"
            fill="#222222"
            fill-opacity="0.56"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.86328 10.6672C9.86328 10.2254 10.2215 9.86719 10.6633 9.86719H10.6692C11.111 9.86719 11.4692 10.2254 11.4692 10.6672C11.4692 11.109 11.111 11.4672 10.6692 11.4672H10.6633C10.2215 11.4672 9.86328 11.109 9.86328 10.6672Z"
            fill="#222222"
            fill-opacity="0.56"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.19531 10.6672C7.19531 10.2254 7.55348 9.86719 7.99531 9.86719H8.00131C8.44314 9.86719 8.80131 10.2254 8.80131 10.6672C8.80131 11.109 8.44314 11.4672 8.00131 11.4672H7.99531C7.55348 11.4672 7.19531 11.109 7.19531 10.6672Z"
            fill="#222222"
            fill-opacity="0.56"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.53125 10.6672C4.53125 10.2254 4.88942 9.86719 5.33125 9.86719H5.33724C5.77906 9.86719 6.13724 10.2254 6.13724 10.6672C6.13724 11.109 5.77906 11.4672 5.33724 11.4672H5.33125C4.88942 11.4672 4.53125 11.109 4.53125 10.6672Z"
            fill="#222222"
            fill-opacity="0.56"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3752_55732">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_3752_55732">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
