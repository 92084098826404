import React from 'react';
import Select from './Select';
import items from '../../utils/banks-list';
import { TGSelectAuto } from 'TGComponents/global';
import { useFormikContext } from 'formik';

const BankSelect = ({ label = 'Banco', classe, name, errors = {} }) => {
  const { values, setFieldValue } = useFormikContext();

  const modifiedBanks = items.map(function (item) {
    return { label: item.Name, value: item.Code };
  });
  const findBankByCode = (code) => {
    const item = modifiedBanks.find((option) => option.value === code);
    return item ? item : null;
  };
  return (
    <TGSelectAuto
      multiple={false}
      label={label}
      placeholder='Selecione o banco'
      values={modifiedBanks}
      selectedValues={findBankByCode(values?.account?.banco)}
      errorMessage={errors?.account?.banco}
      onChange={(e) => {
        const selectedLabel = e.target.value;
        setFieldValue(name, selectedLabel);
      }}
    />
  );
};

export default BankSelect;
