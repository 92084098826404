import React, { useState, useEffect } from 'react';
import { Checkbox, Radio, Box, Stack, Typography, styled } from '@mui/material';
import { helpIcon } from 'assets/svgs';
import { TGTooltip } from 'TGComponents/layout/TGTobBar/stylesMobile';

const StyledCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root, &.MuiRadio-root': {
    'padding': '0 16px 0 0',
    'margin': '0',
    '& svg': {
      color: '#EEEEEE',
      height: '20px',
      width: '20px',
    },
  },
  '&.MuiCheckbox-root.Mui-checked, &.MuiRadio-root.Mui-checked': {
    '& svg': {
      color: '#00B2FF',
      height: '20px',
      width: '20px',
    },
  },
});

const StyledRadio = styled(Radio)({
  '&.MuiRadio-root, &.MuiRadio-root': {
    'padding': '0 16px 0 0',
    'margin': '0',
    '& svg': {
      color: '#EEEEEE',
      height: '20px',
      width: '20px',
    },
  },
  '&.MuiRadio-root.Mui-checked, &.MuiRadio-root.Mui-checked': {
    '& svg': {
      color: '#00B2FF',
      height: '20px',
      width: '20px',
    },
  },
});

const TGCheckbox = ({
  options,
  onChange,
  selectedValues,
  isRadio,
  isCircular,
  gap,
  maxWidth = '85%',
  errorMessage,
  warning,
  flexDirection = 'column',
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedValues || []);

  useEffect(() => {
    setSelectedOptions(selectedValues || []);
  }, [selectedValues]);

  const handleCheckboxChange = (optionValue) => {
    const updatedSelection = [...selectedOptions];

    if (isRadio) {
      // Se for um Radio, substitua a seleção existente
      updatedSelection.splice(0, updatedSelection.length, optionValue);
    } else {
      // Se for um Checkbox, adicione/remova da seleção
      if (updatedSelection.includes(optionValue)) {
        updatedSelection.splice(updatedSelection.indexOf(optionValue), 1);
      } else {
        updatedSelection.push(optionValue);
      }
    }

    setSelectedOptions(updatedSelection);
    onChange(updatedSelection);
  };

  return (
    <Box display={'flex'} flexDirection={flexDirection} gap={gap}>
      {options.map((item) => (
        <Box
          width='100%'
          display='flex'
          alignItems='flex-start'
          key={item.value}
        >
          {isCircular ? (
            <StyledRadio
              checked={selectedOptions.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
              sx={{
                '&.MuiCheckbox-root': { borderRadius: '8px' },
              }}
              disabled={item.disabled}
            />
          ) : (
            <StyledCheckbox
              checked={selectedOptions.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
              sx={{
                '&.MuiCheckbox-root': { borderRadius: '8px' },
              }}
              disabled={item.disabled}
            />
          )}

          <Box width={'100%'}>
            <Typography
              fontSize={'16px'}
              fontWeight={'500'}
              color={item.disabled ? '#999999' : '#222'}
            >
              <Stack direction='flex' gap='8px' alignItems={'center'}>
                {item.label} {/* Se tiver tooltip */}
                {item.tooltip && (
                  <TGTooltip title={item.tooltip} placement='top'>
                    <img
                      src={helpIcon}
                      alt='icon-tooltip'
                      style={{ cursor: 'pointer' }}
                    />
                  </TGTooltip>
                )}
              </Stack>
              {item.disabled && (
                <Typography
                  display={'inline'}
                  fontSize={'0.875rem'}
                  color='#00B2FF'
                  fontWeight={'500'}
                >
                  Em breve!
                </Typography>
              )}
            </Typography>
            {item.text && (
              <Typography
                fontSize={'0.75rem'}
                color={item.disabled ? '#c3c3c3' : '#999999'}
                mb={3}
                maxWidth={maxWidth}
              >
                {item.text}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
      {errorMessage && (
        <Typography
          color='#FF5858'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default TGCheckbox;
