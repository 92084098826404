import { Button, Modal } from 'react-bootstrap'
import { Box, Dialog, DialogContent, Typography, styled } from '@mui/material'

import { closeIcon } from 'assets/svgs'

const CloseIcon = styled('img')({
  position: 'absolute',
  right: '20px',
  top: '20px',

  '&:hover': {
    filter: 'brightness(0.8)',
    cursor: 'pointer',
    transition: 'filter 0.3s ease-in-out',
  },
})

const ModalLogin = ({
  open = false,
  size = 'md',
  title = 'Atenção',

  content = null,

  handleClose,
}) => {
  return (
    <Modal centered show={open} onHide={handleClose} size={size}>
      <Box padding='60px'>
        <CloseIcon onClick={handleClose} src={closeIcon} alt='close-icon' />
        <Typography
          textAlign='center'
          fontWeight='600'
          fontSize='20px'
          sx={{ marginTop: '30px' }}
        >
          {title}
        </Typography>
        {/*<p>{ message }</p>*/}
        {content}
      </Box>
    </Modal>
  )
}

export default ModalLogin
