import { Box, Stack, Typography, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import { Button } from 'TGComponents/global';
import { TGSelectInput } from 'TGComponents/global';
import OfferQuantityControl from './OfferQuantityControl';
import { formatCurrency } from 'lib/utils';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import TGPaymentType from 'TGComponents/global/TGPaymentType';

const CreditCard = ({
  qtym,
  checkoutConfig,
  parcelConfig,
  loadingParcelConfig,
  loadingPost,
}) => {
  const [searchParams] = useSearchParams();
  const digits = searchParams.get('digits');
  const band = searchParams.get('band');
  const { values, setFieldValue } = useFormikContext();

  //Reescreve lista de parcelas com quantidade + valor total para encaixar no component TGSelect
  const originalParcelConfig = parcelConfig?.parcelas ?? [];
  const modifiedParcelConfig = originalParcelConfig?.map((item, index) => ({
    value: `${item.valorTotal}:${item.qtdParcelas}`,
    label: `${item.qtdParcelas} x  R$ ${formatCurrency(item?.valorParcela)} ${
      index > 0 ? '*' : ''
    }`,
  }));

  //esse useEffect foi feito pra sempre que originalParcelConfig mudar, colocar o ultimo valor da lista no parametro
  useEffect(() => {
    if (originalParcelConfig.length > 0) {
      const lastValue =
        modifiedParcelConfig[modifiedParcelConfig.length - 1]?.value;
      setFieldValue('qtdParcelasValorParcela', lastValue);
    }
  }, [originalParcelConfig]);

  //função pra escolher a quantidade de parcelas e o valor total
  const handleSelectChange = (e) => {
    setFieldValue('qtdParcelasValorParcela', e.target.value);
  };

  //funcao pra pegar o nome do cartao
  function getCardBrand(band) {
    switch (band.toLowerCase()) {
      case 'master':
        return 'Mastercard';
      case 'visa':
        return 'Visa';
      default:
        return '??';
    }
  }

  return (
    <Box>
      <Stack gap='16px'>
        <OfferQuantityControl
          qtym={qtym}
          checkoutConfig={checkoutConfig}
          loading={loadingParcelConfig}
        />

        <TGSelectInput
          placeholder={'Selecione a quantidade de parcelas'}
          values={modifiedParcelConfig}
          value={values?.qtdParcelasValorParcela}
          onChange={handleSelectChange}
          loading={loadingParcelConfig}
        />
        {loadingParcelConfig ? (
          <Skeleton height='70px' />
        ) : (
          <Stack bgcolor='#F7F9FA' borderRadius='12px' p='16px' gap='4px'>
            <Typography variant='label-s' color='neutral.secondary'>
              Cartão de crédito{' '}
            </Typography>
            <Typography variant='body-l' color='rgba(34, 34, 34, 0.56)'>
              {getCardBrand(band)} -{' '}
              <TGPaymentType type={band} width='20px' height='20px' /> **** ****
              **** {digits}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack gap='40px'>
        <Box
          display='flex'
          width='100%'
          flexDirection='row'
          gap='12px'
          mt='40px'
        >
          <Button
            variant='secondary'
            buttonBoxStyle={{
              width: '100%',
            }}
            sx={{
              width: '100%',
              border: '2px solid rgba(34, 34, 34, 0.08)',
              color: '#222',
            }}
            loading={loadingPost || loadingParcelConfig}
            disabled={loadingPost || loadingParcelConfig}
          >
            Cancelar
          </Button>
          <Button
            buttonBoxStyle={{
              width: '100%',
            }}
            sx={{
              width: '100%',
            }}
            variant='primary'
            type='submit'
            loading={loadingPost || loadingParcelConfig}
            disabled={loadingPost || loadingParcelConfig}
          >
            Confirmar
          </Button>
        </Box>
        <Typography variant='body-m' color='rgba(34, 34, 34, 0.56)'>
          * O valor parcelado possui acréscimo.
        </Typography>
      </Stack>
    </Box>
  );
};

export default CreditCard;
