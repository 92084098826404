export const SearchIcon = ({ size = 24, color = '#22222' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.6812 1.1001C5.93748 1.1001 1.28125 5.75633 1.28125 11.5001C1.28125 17.2439 5.93748 21.9001 11.6812 21.9001C17.425 21.9001 22.0812 17.2439 22.0812 11.5001C22.0812 5.75633 17.425 1.1001 11.6812 1.1001ZM3.08125 11.5001C3.08125 6.75044 6.9316 2.9001 11.6812 2.9001C16.4309 2.9001 20.2812 6.75044 20.2812 11.5001C20.2812 16.2497 16.4309 20.1001 11.6812 20.1001C6.9316 20.1001 3.08125 16.2497 3.08125 11.5001ZM20.8176 19.3636C20.4662 19.0122 19.8963 19.0122 19.5449 19.3636C19.1934 19.7151 19.1934 20.2849 19.5449 20.6364L21.5449 22.6364C21.8963 22.9879 22.4662 22.9879 22.8176 22.6364C23.1691 22.2849 23.1691 21.7151 22.8176 21.3636L20.8176 19.3636Z'
        fill={color}
      />
    </svg>
  )
}
