import React, { useState, useEffect } from 'react'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/system'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { filterMore } from 'assets/svgs'

const StyledCustomChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  padding: '19px 4px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '480px',
  height: '48px',
  fontSize: '16px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))

const TextWithArrow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const TGFilter = ({
  label,
  onAction,
  variant,
  orderOptions,
  updateSortOption,
  icon,
  moreOptions,
  loading,
  filterDisabled,
  bgColor,
  textColor,
  colorDefault = '#999',
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    setSelected(variant === 'filled')
  }, [variant])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (item) => {
    if (item === 'recentes' || item === 'antigos') {
      //onAction(item)
      updateSortOption(item)
    } else {
      onAction(item)
    }
    setAnchorEl(null)
  }

  const noTextsOutlined = label === 'Ordenar' || label === 'Mais filtros'

  return (
    <>
      {icon}
      <StyledCustomChip
        disabled={loading || filterDisabled}
        label={
          <TextWithArrow>
            {label}
            {label === 'Mais filtros' && (
              <img
                src={filterMore}
                alt='filtro'
                style={{ marginLeft: '10px' }}
              />
            )}
            {orderOptions && orderOptions.length > 0 && <ArrowDropDownIcon />}
          </TextWithArrow>
        }
        onClick={label === 'Ordenar' ? handleClick : () => onAction(label)}
        variant={selected ? 'filled' : 'outlined'}
        sx={{
          'color': `${
            selected && !noTextsOutlined
              ? '#00B2FF'
              : noTextsOutlined
              ? '#555'
              : '#999'
          }`,
          'bgcolor': `${
            selected && !noTextsOutlined
              ? '#EBF9FF'
              : noTextsOutlined
              ? '#FAFAFA'
              : '#ffffff'
          }`,
          'border': `1px solid ${
            selected ? '#eeeeee' : 'rgba(34, 34, 34, 0.08)'
          }`,

          ':hover': {
            bgcolor: `#f9f9f9 !important`,
            border: `1px solid #eeeeee`,
            color: `#555555 !important`,
          },
          ':active': {
            bgcolor: `#EBF9FF !important`,
            border: `1px solid #eeeeee`,
            color: `#00B2FF !important`,
          },
        }}
        {...props}
      />
      {orderOptions && orderOptions.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: '8px',
            },
          }}
        >
          {orderOptions.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
              {option === 'recentes' ? 'Mais recentes' : 'Mais antigos'}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default TGFilter
