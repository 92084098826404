export const MenuIcon = ({ width = 16, height = 16, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Item_Interface/Menu'>
        <path
          id='Vector'
          d='M9.60156 2.3998C9.60156 1.5198 8.88156 0.799805 8.00156 0.799805C7.12156 0.799805 6.40156 1.5198 6.40156 2.3998C6.40156 3.2798 7.12156 3.9998 8.00156 3.9998C8.88156 3.9998 9.60156 3.2798 9.60156 2.3998Z'
          fill={color}
        />
        <path
          id='Vector_2'
          d='M9.60156 13.6C9.60156 12.72 8.88156 12 8.00156 12C7.12156 12 6.40156 12.72 6.40156 13.6C6.40156 14.48 7.12156 15.2 8.00156 15.2C8.88156 15.2 9.60156 14.48 9.60156 13.6Z'
          fill={color}
        />
        <path
          id='Vector_3'
          d='M9.60156 8.00039C9.60156 7.12039 8.88156 6.40039 8.00156 6.40039C7.12156 6.40039 6.40156 7.12039 6.40156 8.00039C6.40156 8.88039 7.12156 9.60039 8.00156 9.60039C8.88156 9.60039 9.60156 8.88039 9.60156 8.00039Z'
          fill={color}
        />
      </g>
    </svg>
  );
};
