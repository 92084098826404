import { Box, Dialog, DialogContent, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import TGButton from 'TGComponents/global/TGButton';
import { closeIcon } from 'assets/svgs';

const TGModalContainer = styled(Dialog)(
  ({ theme, maxWidth, paddingContainer }) => ({
    '& .MuiDialog-paper': {
      borderRadius: 16,
      padding: paddingContainer || '10px',
      width: '100%',
      maxWidth: maxWidth || 'sm', // Definindo maxWidth padrão como 'sm' caso não seja fornecido
      [theme.breakpoints.down('sm')]: {
        borderRadius: '16px 16px 0px 0px',
        padding: '20px',
        margin: 0,
        marginTop: 'auto',
      },
    },
    '& .MuiDialogContent-root': {
      textAlign: 'center',
      padding: theme.spacing(2),
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        padding: '20px',
        margin: 0,
      },
    },
  })
);
const CloseIcon = styled('img')({
  position: 'absolute',
  right: '40px',
  top: '40px',

  '&:hover': {
    filter: 'brightness(0.8)',
    cursor: 'pointer',
    transition: 'filter 0.3s ease-in-out',
  },
});

const LeftIconPosition = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '40px',
  top: '40px',
  [theme.breakpoints.down('sm')]: {
    left: '20px',
    top: '40px',
  }, 
}));

const DialogIcon = styled('img')({
  maxWidth: '80px',
  maxHeight: '80px',
});

const TGModalContent = styled(DialogContent)(({ textAlign, padding }) => ({
  textAlign: textAlign || 'center',
  padding: padding || '0 !important',
  marginBlock: '24px',
}));

const TGModalButtonContainer = styled(Box)({
  padding: '16px 20px 14px',
  gap: '1rem',
  display: 'flex',
  justifyContent: 'center',
});

export default function TGModal({
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onClose,
  open,
  icon,
  isConfirmation,
  textAlign,
  children,
  padding,
  maxWidth,
  loading,
  paddingContainer = '40px',
  disabledCancelButton = false,
  disabledConfirmButton = false,
  notCancel,
  widthConfirmButton,
  titleSize = '20px',
  titleColor = '#222',
  titlePx = '0',
  confirmButtonWidth,
  paddingActions,
  leftTitleIcon,
  styleButtonCancel,
  actionDelete,
  pbIcon = 6,
  mtIcon = '30px',
  hasHeader = true,
}) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    //Tive que desativar para somente chamar pelo pai
    //handleClose()
  };

  return (
    <Box>
      <TGModalContainer
        onClose={handleClose}
        open={open}
        textAlign={textAlign}
        maxWidth={maxWidth}
        paddingContainer={paddingContainer}
      >
        {hasHeader && (
          <Stack
            height={['48px', '48px', 'initial']}
            justifyContent="space-between"
            direction="row"
          >
            <LeftIconPosition>{leftTitleIcon}</LeftIconPosition>

            <CloseIcon onClick={handleClose} src={closeIcon} alt="close-icon" />
          </Stack>
        )}
        <Box display="flex" justifyContent="center" mt={mtIcon} pb={pbIcon}>
          {icon && <DialogIcon src={icon} alt="icon" />}
        </Box>
        <TGModalContent>
          <Box padding={padding}>
            {title && (
              <Typography
                mb="12px"
                fontSize={titleSize}
                color={titleColor}
                fontWeight="600"
                px={titlePx}
              >
                {title}
              </Typography>
            )}
            {message && (
              <Typography color="#555" fontSize="0.875rem">
                {message}
              </Typography>
            )}
            {children}
          </Box>
        </TGModalContent>
        {isConfirmation && (
          <TGModalButtonContainer sx={{ padding: paddingActions }}>
            {!notCancel && (
              <TGButton
                bgcolor="#fff"
                variant="outlined"
                sx={{
                  paddingInline: '40px',
                  color: '#0097D8',
                }}
                onClick={actionDelete || handleClose}
                loading={loading}
                disabled={loading || disabledCancelButton}
                style={styleButtonCancel}
              >
                {cancelButtonText}
              </TGButton>
            )}
            <TGButton
              width={confirmButtonWidth}
              variant="contained"
              autoFocus
              onClick={handleConfirm}
              sx={{
                minHeight: '48px',
                color: 'white',
                paddingInline: '40px',
                width: widthConfirmButton,
              }}
              loading={loading}
              disabled={loading || disabledConfirmButton}
            >
              {confirmButtonText}
            </TGButton>
          </TGModalButtonContainer>
        )}
      </TGModalContainer>
    </Box>
  );
}
