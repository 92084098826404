const appConfig = {
  env: "development",
  production: {
    host: "https://api.ticketandgo.com.br", socketUrl: "https://api2.ticketandgo.com.br", 
    api: "https://api.ticketandgo.com.br", 
    apiv2: "https://api-v2.jelastic.saveincloud.net", 
    apiCheckout: 'https://checkout.ticketandgo.com.br',
    dashboardUrl: 'https://dashboard.ticketandgo.com.br',
  },
  development: {
    host: "https://api-teste-v1.ticketandgo.com.br",
    socketUrl: "https://api-teste-v1.ticketandgo.com.br",
    api: "https://api-teste-v1.ticketandgo.com.br",
    apiv2: "https://api-producao-v1.jelastic.saveincloud.net",
    apiCheckout: 'https://checkout-prod.ticketandgo.com.br',
    dashboardUrl: 'https://dashboard-teste.ticketandgo.com.br',
  },

};



export default appConfig;


