import { styled } from '@mui/material/styles';

const IconButtonBox = styled('div')(
  ({ theme, borderColor, border, bgcolor, width, height, disabled }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': '8px',
    'minWidth': width,
    'minHeight': height,
    'borderRadius': '8px',
    'border': border,
    'borderColor': borderColor,
    'backgroundColor': bgcolor,
    'cursor': disabled ? 'not-allowed' : 'pointer',
    'transition': 'background-color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: !disabled && 'rgba(90, 90, 90, 0.09)',
    },
    '& img': {
      filter: disabled
        ? 'brightness(0) saturate(100%) invert(74%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(103%) contrast(97%)'
        : 'none',
    },
  })
);

export default IconButtonBox;
