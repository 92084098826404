import styled from '@emotion/styled'
import { Radio as MuiRadio } from '@mui/material'

const Radio = styled(MuiRadio)({
  '&.MuiRadio-root, &.MuiRadio-root': {
    'padding': '0',
    'margin': '0',
    '& svg': {
      color: '#EEEEEE',
      height: '20px',
      width: '20px',
    },
  },
  '&.MuiRadio-root.Mui-checked, &.MuiRadio-root.Mui-checked': {
    '& svg': {
      color: '#00B2FF',
      height: '20px',
      width: '20px',
    },
  },
})

export { Radio }
