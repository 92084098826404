export const CardIcon = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4275_69742)">
        <path
          d="M5.8317 15.6418C5.32044 15.6418 4.90598 16.0864 4.90598 16.6349C4.90598 17.1833 5.32044 17.628 5.8317 17.628H8.30027C8.81153 17.628 9.22598 17.1833 9.22598 16.6349C9.22598 16.0864 8.81153 15.6418 8.30027 15.6418H5.8317Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          d="M9.84312 16.6349C9.84312 16.0864 10.2576 15.6418 10.7688 15.6418H14.4717C14.983 15.6418 15.3974 16.0864 15.3974 16.6349C15.3974 17.1833 14.983 17.628 14.4717 17.628H10.7688C10.2576 17.628 9.84312 17.1833 9.84312 16.6349Z"
          fill={color}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.20312 7.8413C1.20312 6.06205 1.40117 4.54552 2.37036 3.56226C3.3212 2.59761 4.77252 2.40039 6.51302 2.40039H17.4834C19.2284 2.40039 20.6819 2.59753 21.634 3.56162C22.6051 4.54498 22.8031 6.06212 22.8031 7.8413V16.1594C22.8031 17.9386 22.6051 19.4552 21.6359 20.4384C20.685 21.4031 19.2337 21.6003 17.4932 21.6003H6.51419C4.77439 21.6052 3.3226 21.4115 2.371 20.4479C1.40099 19.4657 1.20312 17.9483 1.20312 16.1695V7.8413ZM3.0551 7.69694C3.06792 6.02565 3.30546 5.34727 3.64247 5.00537C4.00738 4.63517 4.74815 4.3866 6.51302 4.3866H17.4834C19.2536 4.3866 19.997 4.63525 20.3632 5.00601C20.7007 5.34782 20.9383 6.02561 20.9511 7.69694H3.0551ZM20.9517 9.68315V16.1594C20.9517 17.9364 20.7103 18.6437 20.3638 18.9953C19.9989 19.3655 19.2581 19.6141 17.4932 19.6141L6.51302 19.6141L6.51053 19.6141C4.74554 19.6192 4.00574 19.372 3.64183 19.0035C3.29609 18.6534 3.05455 17.947 3.05455 16.1695V9.68315H20.9517Z"
          fill={color}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_4275_69742">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};