import { Box, Stack, Typography } from '@mui/material';
import CupomStart from './CupomStar';

const Header = ({ title, description }) => {
  return (
    <Stack gap='16px'>
      <Stack direction='row' gap='16px' alignItems='center'>
        <CupomStart />
        <Typography color='neutral.20' variant='headline'>
          {title}
        </Typography>
      </Stack>
      {description && (
        <Typography color='neutral.secondary' variant='body-l'>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default Header;
