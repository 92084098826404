const QuestionIcon = ({ width = 24, height = 24, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.58853 4.9818C9.18076 4.60611 8.64597 4.41826 7.98418 4.41826C7.54966 4.41826 7.16529 4.50212 6.83105 4.66984C6.50349 4.83756 6.24947 5.07572 6.06898 5.38432C5.88849 5.68622 5.79824 6.03842 5.79824 6.44095H6.66058C6.66058 6.05184 6.77757 5.74659 7.01154 5.5252C7.25219 5.29711 7.57974 5.18306 7.9942 5.18306C8.40198 5.18306 8.72619 5.30046 8.96684 5.53527C9.2075 5.77007 9.32782 6.08874 9.32782 6.49126C9.32782 7.04809 9.14065 7.42713 8.7663 7.6284C8.39195 7.82966 7.87388 7.93029 7.21208 7.93029H6.92129L6.9614 9.52026H7.75355L7.78363 8.56427C8.52565 8.56427 9.11391 8.39655 9.54842 8.06111C9.98294 7.71897 10.2002 7.19568 10.2002 6.49126C10.2002 5.86064 9.99631 5.35749 9.58853 4.9818Z"
        fill={color}
        fill-opacity="0.56"
      />
      <path
        d="M6.91126 11.4021C7.03159 11.5228 7.17866 11.5832 7.35246 11.5832C7.51958 11.5832 7.65996 11.5228 7.7736 11.4021C7.89393 11.2813 7.95409 11.1337 7.95409 10.9593C7.95409 10.7849 7.89393 10.6373 7.7736 10.5165C7.65996 10.3958 7.51958 10.3354 7.35246 10.3354C7.17866 10.3354 7.03159 10.3958 6.91126 10.5165C6.79094 10.6373 6.73077 10.7849 6.73077 10.9593C6.73077 11.1337 6.79094 11.2813 6.91126 11.4021Z"
        fill={color}
        fill-opacity="0.56"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.9998 1.80078C4.57564 1.80078 1.7998 4.57661 1.7998 8.00078C1.7998 11.4249 4.57564 14.2008 7.9998 14.2008C11.4239 14.2008 14.1998 11.4249 14.1998 8.00078C14.1998 4.57661 11.4239 1.80078 7.9998 1.80078ZM2.9998 8.00078C2.9998 5.23936 5.23838 3.00078 7.9998 3.00078C10.7612 3.00078 12.9998 5.23936 12.9998 8.00078C12.9998 10.7622 10.7612 13.0008 7.9998 13.0008C5.23838 13.0008 2.9998 10.7622 2.9998 8.00078Z"
        fill={color}
        fill-opacity="0.56"
      />
    </svg>
  );
};

export default QuestionIcon;
