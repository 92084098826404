export const MoneyBackward = ({ size = 24, color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.02 4.46997L12 2'
        stroke='#00B2FF'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.91 7.79999C3.8 9.27999 3.10999 11.11 3.10999 13.11C3.10999 18.02 7.09 22 12 22C16.91 22 20.89 18.02 20.89 13.11C20.89 8.19999 16.91 4.21997 12 4.21997C11.32 4.21997 10.66 4.31002 10.02 4.46002'
        stroke='#00B2FF'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.61328 15.25C9.61328 16.22 10.3633 17 11.2833 17H13.1633C13.9633 17 14.6133 16.32 14.6133 15.47C14.6133 14.56 14.2133 14.23 13.6233 14.02L10.6133 12.97C10.0233 12.76 9.62329 12.44 9.62329 11.52C9.62329 10.68 10.2733 9.98999 11.0733 9.98999H12.9533C13.8733 9.98999 14.6233 10.77 14.6233 11.74'
        stroke='#00B2FF'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.1133 9V18'
        stroke='#00B2FF'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
