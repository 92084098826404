import { takeEvery, put, takeLatest, call } from 'redux-saga/effects'
import Requester from '../services/externalRequester'

import * as addressActions from '../actions/addressActions'
import * as notificationActions from '../actions/notificationActions'

const _getAddress = (cep) => Requester(`https://viacep.com.br/ws/${cep}/json/`, {
    method: 'GET'    
})




export function* getAddress(action) {

    yield put(addressActions.setLoading(true))

    try {
        const { cep } = action.payload
        

        const result = yield call(_getAddress, cep)

        if (result.erro) {

            yield put(notificationActions.warning('Cep não encontrado'));
            yield put(addressActions.clear())

        }
        else {
            yield put(addressActions.setAddress(result))
        }
    } catch (error) {
        
        yield put(notificationActions.error('Cep Inválido, utilize apenas números'));
    }
    finally {
        yield put(addressActions.setLoading(false))
    }
}

