export const validarCPF = (cpf) => {
  var cpfRegex = /^(?:(\d{3})\.(\d{3})\.(\d{3})-(\d{2}))$/
  if (!cpfRegex.test(cpf)) {
    return false
  }

  var numeros = cpf.match(/\d/g).map(Number)

  // Check for repeated digits
  if (
    numeros.every((digit) => digit === numeros[0]) ||
    numeros.join('') === '12345678909' // CPFs with all digits in sequence
  ) {
    return false
  }

  var soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 9) {
      return acc + cur * (10 - idx)
    }
    return acc
  }, 0)

  var resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== numeros[9]) {
    return false
  }

  soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 10) {
      return acc + cur * (11 - idx)
    }
    return acc
  }, 0)

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== numeros[10]) {
    return false
  }

  return true
}
