import React from 'react';
import { Stack, Typography } from '@mui/material';
import TGIcon from 'TGComponents/global/TGIcon';
import LoveEmoji from 'assets/images/loveEmoji.png';
import Nervoso from 'assets/images/Nervoso.png';
import Alert from 'assets/images/Alert.png';
import TicketAndGo from 'assets/images/ticketandgo_transparent.png';

const ModalStatus = ({ status, title, message, buttons }) => {
  function getStatusIcon(band) {
    switch (status.toLowerCase()) {
      case 'error':
        return Nervoso;
      case 'success':
        return LoveEmoji;
      case 'warning':
        return Alert;
      default:
        return '??';
    }
  }
  return (
    <Stack
      gap='12px'
      padding='40px'
      width='480px'
      borderRadius='12px'
      bgcolor='#FFFFFF'
      textAlign='center'
    >
      <Stack gap='24px'>
        <TGIcon
          src={getStatusIcon(status)}
          width='80px'
          height='80px'
          style={{ margin: 'auto' }}
        />
        <Typography variant='headline'>{title}</Typography>
      </Stack>

      <Stack gap='32px'>
        {message && (
          <Typography variant='body-l' color='rgba(34, 34, 34, 0.56)'>
            {message}
          </Typography>
        )}
        {buttons && <>{buttons}</>}
        {status === 'warning' ? (
          <Stack mt='28px' alignItems='center' direction='column' gap='4px'>
            <Typography color='rgba(34, 34, 34, 0.56)' variant='body-s'>
              Powered by:
            </Typography>
            <img
              src={TicketAndGo}
              height='24px'
              width='112px'
              alt='TicketAndGo-transparent'
            />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default ModalStatus;
