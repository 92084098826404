import React from 'react'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

export default function TGShow({
  children,
  breakpoint = 'md',
  display = 'above',
}) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up(breakpoint))

  // Decide whether to show children based on 'display' prop
  const shouldShow =
    (display === 'above' && matches) || (display === 'below' && !matches)

  return shouldShow ? <Box>{children}</Box> : null
}
