
export const PictureIcon = ({ width = 29, height = 29, color = '#00A7F0' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9999 0C23.7041 0 24.2749 0.570837 24.2749 1.275V3.4H26.3999C27.1041 3.4 27.6749 3.97084 27.6749 4.675C27.6749 5.37916 27.1041 5.95 26.3999 5.95H24.2749V8.075C24.2749 8.77916 23.7041 9.35 22.9999 9.35C22.2957 9.35 21.7249 8.77916 21.7249 8.075V5.95H19.5999C18.8957 5.95 18.3249 5.37916 18.3249 4.675C18.3249 3.97084 18.8957 3.4 19.5999 3.4H21.7249V1.275C21.7249 0.570837 22.2957 0 22.9999 0Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8999 2.55C17.8999 3.25416 17.3291 3.825 16.6249 3.825H10.4199C7.27141 3.825 5.73083 4.47032 4.91302 5.28812C4.09522 6.10593 3.4499 7.6465 3.4499 10.795V18.955C3.4499 21.0679 3.74053 22.4567 4.17889 23.3889L9.03251 18.5103C9.72675 17.813 10.6381 17.452 11.5805 17.5266C12.5178 17.6009 13.3613 18.0949 13.9613 18.8666L13.9698 18.8776L14.3421 19.3676C14.5768 19.667 14.804 19.7306 14.944 19.7306C15.0848 19.7306 15.3136 19.6663 15.5496 19.3629L20.3057 13.2524C20.9615 12.409 21.8989 11.9 22.9243 11.9C23.9496 11.9 24.887 12.409 25.5428 13.2524L25.5499 13.2615V11.05C25.5499 10.3458 26.1207 9.775 26.8249 9.775C27.5291 9.775 28.0999 10.3458 28.0999 11.05V18.955C28.0999 25.755 25.3799 28.475 18.5799 28.475H10.4199C3.6199 28.475 0.899902 25.755 0.899902 18.955V10.795C0.899902 3.995 3.6199 1.275 10.4199 1.275H16.6249C17.3291 1.275 17.8999 1.84584 17.8999 2.55ZM25.5499 17.382C25.4938 17.3317 25.4413 17.2757 25.3934 17.2141L23.5299 14.8178C23.2939 14.5144 23.0651 14.45 22.9243 14.45C22.7835 14.45 22.5547 14.5143 22.3187 14.8178L17.5626 20.9282C16.9068 21.7716 15.9694 22.2806 14.944 22.2806C13.9187 22.2806 12.9813 21.7716 12.3255 20.9282L12.3169 20.9171L11.9445 20.4271C11.7339 20.1584 11.5214 20.0799 11.3792 20.0687C11.2412 20.0577 11.0491 20.0992 10.8402 20.3088L5.98058 25.1934C6.91276 25.6332 8.30284 25.925 10.4199 25.925H18.5799C21.7284 25.925 23.269 25.2797 24.0868 24.4619C24.9046 23.6441 25.5499 22.1035 25.5499 18.955V17.382Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.4249 10.625C6.4249 8.51251 8.13741 6.8 10.2499 6.8C12.3624 6.8 14.0749 8.51251 14.0749 10.625C14.0749 12.7375 12.3624 14.45 10.2499 14.45C8.13741 14.45 6.4249 12.7375 6.4249 10.625ZM10.2499 9.35C9.54574 9.35 8.9749 9.92084 8.9749 10.625C8.9749 11.3292 9.54574 11.9 10.2499 11.9C10.9541 11.9 11.5249 11.3292 11.5249 10.625C11.5249 9.92084 10.9541 9.35 10.2499 9.35Z"
        fill={color}
      />
    </svg>
  );
};
