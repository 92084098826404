const palette = {
  mode: "light",
  neutral: {
    main: "#111111",
    secondary: "rgba(34, 34, 34, 0.56)",
    tertiary: '#F7F9FA',
    10: "#111111",
    20: "#222222",
    30: "#555555",
    40: "#999999",
    50: "#CCCCCC",
    60: "#EEEEEE",
    70: "#F9F9F9",
    80: "#FFFFFF",
  },
  success: {
    main: "#3ABD52",
    10: "#3ABD52",
    light: "#EBFFEE",
    80: "#EBFFEE",
  },
  alert: {
    main: "#F2C200",
    10: "#F2C200",
    light: "#FFFCEB",
    80: "#FFFCEB",
  },
  error: {
    main: "#FF4848",
    10: "#FF4848",
    light: "#FFEBEE",
    80: "#FFEBEE",
  },
  primary: {
    main: "#00B2FF",
    10: "#00B2FF",
    light: "#EBF9FF",
    80: "#EBF9FF",
  },
  text: {
    primary: "#272827",
    main: "#272827",
  },
  blue: {
    50: "#EFF9FF",
    100: "#DEF2FF",
    200: "#B6E8FF",
    300: "#75D7FF",
    400: "#2CC4FF",
    500: "#00B2FF",
    600: "#008AD4",
    700: "#006DAB",
    800: "#005C8D",
    900: "#064D74",
    950: "#04314D",
  },
  yellow: {
    50: "#FFFCEB",
    100: "#FDF0C8",
    200: "#FCDE8B",
    300: "#FAC84F",
    400: "#F2C200",
    500: "#F28F0D",
    600: "#D66B09",
    700: "#B2490B",
    800: "#903810",
    900: "#772F10",
    950: "#441604",
  },
  red: {
    50: "#FAF0F2",
    100: "#FDE3E3",
    200: "#FCCCCC",
    300: "#F9A8A8",
    400: "#F25C5C",
    500: "#EA4949",
    600: "#D72B2B",
    700: "#B42121",
    800: "#951F1F",
    900: "#7C2020",
    950: "#430C0C",
  },
  green: {
    50: "#F3FAF4",
    100: "#E5F3E7",
    200: "#CBE7D0",
    300: "#A2D3AB",
    400: "#72B67F",
    500: "#53A462",
    600: "#3C7D48",
    700: "#32633B",
    800: "#2B5032",
    900: "#25422B",
    950: "#102315",
  },
  gray: {
    50: "#FAFAFA",
    100: "#F2F5F5",
    200: "#E8EBEC",
    300: "#D5DBDD",
    400: "#BAC2C6",
    500: "#9EA9AE",
    600: "#879399",
    700: "#6F7A81",
    800: "#5E666B",
    900: "#4C5357",
    950: "#31373A",
  },
  base: {
    white: "#FFFFFF",
    black: "#222222",
    blackAlpha: "#2222228f",
    translucentBlack: "#22222214",
  },
};

export default palette;
