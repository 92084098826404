
export const TrashIcon = ({ width = 16, height = 17, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.54191 5.0029C5.53538 5.003 5.52882 5.003 5.52225 5.0029H1.41695C1.07611 5.0029 0.799805 4.7266 0.799805 4.38576C0.799805 4.04492 1.07611 3.76862 1.41695 3.76862H4.99588L5.07551 3.20986L5.08399 3.14978C5.13222 2.80636 5.20087 2.31746 5.48242 1.9412C5.8326 1.47326 6.38614 1.30005 7.07593 1.30005H8.92383C9.62441 1.30005 10.1761 1.48762 10.5228 1.95853C10.8046 2.34119 10.8715 2.8319 10.9176 3.16928L10.9244 3.21915L11.0033 3.76862H14.5827C14.9235 3.76862 15.1998 4.04492 15.1998 4.38576C15.1998 4.7266 14.9235 5.0029 14.5827 5.0029H10.4775C10.471 5.003 10.4644 5.003 10.4578 5.0029H5.54191ZM6.29741 3.38424L6.24264 3.76862H9.75636L9.70196 3.38982C9.64284 2.96255 9.60278 2.79068 9.52893 2.6904C9.50513 2.65807 9.41402 2.53433 8.92383 2.53433H7.07593C6.57975 2.53433 6.49108 2.6533 6.47133 2.67979L6.47065 2.68071C6.44526 2.71463 6.41316 2.77687 6.38157 2.90074C6.34895 3.02864 6.32676 3.17889 6.29741 3.38424Z"
        fill={color}
      />
      <path
        d="M3.67821 6.8096C3.65349 6.46966 3.35788 6.21412 3.01794 6.23883C2.67799 6.26355 2.42245 6.55916 2.44717 6.8991L2.91565 13.3425L2.91595 13.3463L2.92117 13.4138C2.95518 13.8582 3.00371 14.4924 3.41764 14.9708C3.87878 15.5038 4.63731 15.7 5.68623 15.7H10.3134C11.3624 15.7 12.1209 15.5038 12.582 14.9708C12.9959 14.4924 13.0445 13.8582 13.0785 13.4138L13.0837 13.3463L13.084 13.3425L13.5525 6.8991C13.5772 6.55916 13.3217 6.26355 12.9817 6.23883C12.6418 6.21412 12.3462 6.46966 12.3215 6.8096L11.8533 13.2492L11.8531 13.251C11.8091 13.8085 11.7681 14.0251 11.6486 14.1632C11.5548 14.2717 11.2754 14.4658 10.3134 14.4658H5.68623C4.72424 14.4658 4.44489 14.2717 4.35106 14.1632C4.23159 14.0251 4.1906 13.8085 4.14656 13.251L3.67821 6.8096Z"
        fill={color}
      />
      <path
        d="M6.5598 10.9686C6.5598 10.6278 6.83611 10.3515 7.17695 10.3515H8.82266C9.1635 10.3515 9.43981 10.6278 9.43981 10.9686C9.43981 11.3094 9.1635 11.5857 8.82266 11.5857H7.17695C6.83611 11.5857 6.5598 11.3094 6.5598 10.9686Z"
        fill={color}
      />
      <path
        d="M6.35409 7.88289C6.01325 7.88289 5.73695 8.1592 5.73695 8.50003C5.73695 8.84087 6.01325 9.11718 6.35409 9.11718H9.64552C9.98636 9.11718 10.2627 8.84087 10.2627 8.50003C10.2627 8.1592 9.98636 7.88289 9.64552 7.88289H6.35409Z"
        fill={color}
      />
    </svg>
  );
};
