import { put, call  } from 'redux-saga/effects'
import Requester, { Download } from '../services/requester'

import * as singleActions from '../actions/singleActions'
import * as notificationActions from '../actions/notificationActions'

const _get = (endpoint, criteria) => Requester(`${endpoint}${criteria ? '/'+criteria : ''}`, {
    method: 'GET',
})

const _download = (endpoint, criteria, ext) => Download(`${endpoint}${criteria ? '/'+criteria : ''}`, {
    method: 'GET',

}, ext)

const _put = (endpoint, payload) => Requester(`${endpoint}`, {
    method: 'PUT',
    body: payload
})

const _post = (endpoint, payload) => Requester(`${endpoint}`, {
    method: 'POST',
    body: payload
})

const _delete = (endpoint, payload) => Requester(`${endpoint}`, {
    method: 'DELETE',
    body: payload
})


export function* getSingle(action) {

    const { endpoint, criteria, supress_notification = 'none' } = action.payload

    yield put(singleActions.setLoading(criteria.single))

    try {



        const result = yield call(_get, endpoint, criteria.arg)

        if (result.status === "success") {
            const { data } = result

            const single = {
                single: criteria.single,
                item: data
            }

            yield put(singleActions.setSingle(single))

        }
        else{
            if(!['error', 'both'].includes(supress_notification)){
                yield put(notificationActions.error(result.message))
            }
        }

    } catch (error) {
        console.log('erro de comunicacao')
    }
    finally {
        yield put(singleActions.setLoading(false))
    }
}

export function* putSingle(action) {

    const { endpoint, payload, single, supress_notification = 'none' } = action.payload

    yield put(singleActions.setLoading(single))



    try {

        const result = yield call(_put, endpoint, payload)

        if (result.status === "success") {
            const { data } = result

            const response = {
                single,
                response: {
                    status: true,
                    data
                }
            }

            if (!data.noRedirect) {
                yield put(singleActions.setResponse(response))
            }

            if(!['success', 'both'].includes(supress_notification)){
                console.log(result)
                //yield put(notificationActions.success(( result?.message ? result.message : (result?.data?.message ? result.data.message : 'Operação realizada com sucesso'))))
                yield put(notificationActions.success((result?.data?.message ? result.data.message : result?.message ? result.message : (result?.data?.message ? result.data.message :'Operação realizada com sucesso'))))
            }

        }
        else{
            const { errors } = result

            const response = {
                single,
                response: {
                    status: false,
                    errors
                }
            }

            yield put(singleActions.setResponse(response))

            if(!['error', 'both'].includes(supress_notification)){
                yield put(notificationActions.error((result.status=='error' ? result.message : 'Identificamos erros de preenchimento. Verifique os dados e tente novamente.')))
            }


        }

    } catch (error) {
        console.log('erro de comunicacao')
    }
    finally {
        yield put(singleActions.setLoading(false))
    }
}

export function* postSingle(action) {

    const { endpoint, payload, single, supress_notification = 'none' } = action.payload

    yield put(singleActions.setLoading(single))

    try {

        const result = yield call(_post, endpoint, payload)

        if (result.status === "success") {
            const { data } = result

            const response = {
                single,
                response: {
                    status: true,
                    data
                }
            }

            yield put(singleActions.setResponse(response))

            if(!['success', 'both'].includes(supress_notification)){
                yield put(notificationActions.success(( result?.message ? result.message : (result?.data?.message ? result.data.message : 'Operação realizada com sucesso'))))
            }

        }
        else{
            const { errors } = result

            const response = {
                single,
                response: {
                    status: false,
                    errors
                }
            }

            yield put(singleActions.setResponse(response))

            if(!['error', 'both'].includes(supress_notification)){
                if(result.errors){
                    var ar = JSON.parse(JSON.stringify(result).replaceAll("[","").replaceAll("]",""));
                    var msgErro = "";
                    for(let x in ar.errors){
                        let boTem = false;
                        for(let y in ar.errors[x]){
                            boTem = true;
                            msgErro += ar.errors[x][y];
                        }
                        if(!boTem) msgErro += ar.errors[x];
                    }
                    yield put(notificationActions.error('Atenção! '+msgErro))
                }else if(result.message){
                    yield put(notificationActions.error('Atenção! '+result.message))
                }else{
                    yield put(notificationActions.error((result.status=='error' ? result.message : 'Identificamos erros de preenchimento. Verifique os dados e tente novamente.')))
                }
            }
        }

    } catch (error) {
        console.log('erro de comunicacao')
    }
    finally {
        yield put(singleActions.setLoading(false))
    }
}

export function* deleteSingle(action) {

    const { endpoint, payload, single, supress_notification = 'none' } = action.payload

    yield put(singleActions.setLoading(single))

    try {

        const result = yield call(_delete, endpoint, payload)

        if (result.status === "success") {
            const { data } = result

            const response = {
                single,
                response: {
                    status: true,
                    data
                }
            }

            if (!data.noRedirect) {
                yield put(singleActions.setResponse(response))
            }

            if(!['success', 'both'].includes(supress_notification)){
                yield put(notificationActions.success((result?.data?.message ? result.data.message : 'Operação realizada com sucesso')))
            }

        }
        else{
            const { errors } = result

            const response = {
                single,
                response: {
                    status: false,
                    errors
                }
            }

            yield put(singleActions.setResponse(response))

            if(!['error', 'both'].includes(supress_notification)){
                yield put(notificationActions.error((result?.message ? result.message :'Falha ao tentar realizar a operação')))
            }
        }

    } catch (error) {
        console.log('erro de comunicacao')
    }
    finally {
        yield put(singleActions.setLoading(false))
    }
}

export function* download(action) {

    const { endpoint, criteria, ext = 'none' } = action.payload

    yield put(singleActions.setLoading('download'))

    try {

        const result = yield call(_download, endpoint, criteria.arg || null, ext)

        yield put(singleActions.setLoading('download'))

    } catch (error) {
        console.log('erro de comunicacao')
    }
    finally {
        yield put(singleActions.setLoading(false))
    }
}





