export const MenuIconHorizontal = ({
  width = 16,
  height = 16,
  color = '#222222',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_129_3252)">
        <path
          d="M2.3998 6.3999C1.5198 6.3999 0.799805 7.1199 0.799805 7.9999C0.799805 8.8799 1.5198 9.5999 2.3998 9.5999C3.2798 9.5999 3.9998 8.8799 3.9998 7.9999C3.9998 7.1199 3.2798 6.3999 2.3998 6.3999Z"
          fill={color}
        />
        <path
          d="M13.5998 6.3999C12.7198 6.3999 11.9998 7.1199 11.9998 7.9999C11.9998 8.8799 12.7198 9.5999 13.5998 9.5999C14.4798 9.5999 15.1998 8.8799 15.1998 7.9999C15.1998 7.1199 14.4798 6.3999 13.5998 6.3999Z"
          fill={color}
        />
        <path
          d="M6.3998 7.9999C6.3998 7.1199 7.1198 6.3999 7.9998 6.3999C8.8798 6.3999 9.5998 7.1199 9.5998 7.9999C9.5998 8.8799 8.8798 9.5999 7.9998 9.5999C7.1198 9.5999 6.3998 8.8799 6.3998 7.9999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_129_3252">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
