import { createTheme } from '@mui/material/styles';
import palette from './pallete';
import typography from './typography';
import spacing from './spacing';

// MUI CONFIGURATION
export const muiTheme = createTheme({
  spacing: spacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1600,
    },
  },
  typography: typography,
  palette: palette,

  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.Mui-focused svg': {
            'fill': '#00B2FF !important',
            'color': '#555',
            '&:hover': {
              backgroundColor: '#E6F5FB',
              color: '#222222',
            },
          },
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'rounded',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          minWidth: '65px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0',
        },
      },
    },
    //muiMenuitems
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          fontSize: '14px',
          fontWeight: '400',
        },
        root: {
          'minHeight': '21px',
          'padding': '12px 16px',
          'color': '#555',
          '&:hover': {
            backgroundColor: '#E6F5FB',
            color: '#222222',
          },
          '&.Mui-selected': {
            backgroundColor: '#E6F5FB',
            color: '#222222',
          },
        },
      },
    },
  },
});
