import { Box, Button, Grid, Typography } from '@mui/material'
import { IOSSwitch } from 'TGComponents/global/Inputs'

export default function TGSwitch({
  label,
  checked,
  onChange,
  disabled = false,
}) {
  return (
    <Box display={'flex'} alignItems={'center'} gap='10px'>
      <IOSSwitch checked={checked} onChange={onChange} disabled={disabled} />
      <Typography fontSize={'14px'} color='#555555'>
        {label}
      </Typography>
    </Box>
  )
}
